import App, { state, BaseControllerClass } from 'cerebral';
import * as Sentry from '@sentry/browser';
import page from 'page';
import qs from 'query-string';
import cloneDeep from 'lodash.clonedeep';

import { initializeApp, FirebaseOptions } from 'firebase/app';

import { notifyError } from 'common/appContainer/module/factories';
import { setAuthenticationError } from 'common/appContainer/module/modules/auth/actions';
import FormsProvider from 'providers/Forms';
import FirebaseProvider, {
  FirebaseProviderAuthenticationError,
  FirebaseProviderError,
} from 'providers/Firebase';
import UseragentProvider from 'providers/Useragent';
import HttpProvider, { HttpProviderError } from 'providers/Http/client';
import TranslateProvider from '@ducky/provider-translate';
import StorageProvider from 'providers/Storage';
import ConfigProvider from 'providers/Config';
import RouterProvider from 'providers/Router';
import UrlActionProvider from 'providers/UrlAction';
import SentryProvider from 'providers/Sentry/client';
import { databaseProvider } from 'providers/Database';
import { activitiesProvider } from 'providers/Activities';
import ServiceWorkerProvider from 'providers/ServiceWorker';
import { trackingProvider } from 'providers/Tracking';
import { ClientConfig } from 'configs/types';

import appContainer from 'common/appContainer/module';
import tlds from 'resources/tlds.json';
import { primaryLanguage, secondaryLanguages } from 'languages/config';

import routes from './routes';
import challengeModule from 'challenge/module';
import accountModule from 'account/module';
import appModule from './module';

const firebaseApp = initializeApp(
  (process.env.CONFIG as unknown as ClientConfig).firebase as FirebaseOptions
);

export default function createController(
  languageData: { [key: string]: any },
  isMobile: boolean
): BaseControllerClass {
  const rootModule = ({ app }) => {
    app.on('initialized', () => {
      Sentry.init({
        dsn: 'https://2c3ac7bb77ab470a928eb7f1fa93e52b@sentry.ducky.eco/3',
        environment: process.env.DUCKY_ENV,
        release: process.env.SOURCE_VERSION,
      });

      if (typeof navigator.serviceWorker !== 'undefined') {
        import('firebase/messaging')
          .then(({ getMessaging, getToken, isSupported }) =>
            navigator.serviceWorker.ready.then(registration =>
              isSupported().then(async supported => {
                if (!supported) return;

                const messaging = getMessaging(firebaseApp);

                await getToken(messaging, {
                  serviceWorkerRegistration: registration,
                }).catch(() => {
                  // Ignore error when messaging was rejected by the user
                });
              })
            )
          )
          .catch(err => {
            console.warn(err); // eslint-disable-line no-console
          });
      }

      app.runSequence([
        ({ store }) => {
          store.set(state`app.isMobile`, isMobile);
          store.set(state`appContainer.language`, languageData);
          store.set(state`appContainer.config`, process.env.CONFIG);
        },
      ]);

      page.start();
      app.getSequence('appContainer.startServiceWorker')();
    });

    Sentry.addGlobalEventProcessor(event => {
      try {
        if (event.extra) {
          event.extra.state = cloneDeep(app.getState());
        } else {
          event.extra = { state: cloneDeep(app.getState()) };
        }
      } catch {
        // Continue regardless of error
      }

      return event;
    });

    routes.forEach(route => {
      page(route.path, ({ params, querystring, path }) => {
        app.getSequence(route.signal)({
          ...params,
          ...qs.parse(querystring),
          base: route.base,
          page: route.name,
          path,
          requireAuth: !route.noAuth,
        });
      });
    });

    // If you're on /, make sure to refresh once you change language
    if (languageData.locale === primaryLanguage) {
      secondaryLanguages.forEach(language => {
        page(`/${language}/*`, () => location.reload());
      });
    }

    // If not in current language, or a new secondary language from the primary, show 404
    page('*', ({ params, querystring, path }) => {
      app.getSequence('account.notFoundRendered')({
        ...params,
        ...qs.parse(querystring),
        base: 'account',
        page: 'notFound',
        path,
        requireAuth: false,
      });
    });

    return {
      modules: {
        appContainer,
        account: accountModule,
        challenge: challengeModule,
        app: appModule,
        useragent: UseragentProvider({
          media: {
            small: '(max-width: 767px)',
            medium: '(min-width: 768px) and (max-width: 1024px)',
            large: '(min-width: 1025px)',
            portrait: '(orientation: portrait)',
          },
          window: true,
        }),
      },
      catch: [
        [HttpProviderError, notifyError('errors.http')],
        [FirebaseProviderAuthenticationError, setAuthenticationError],
        [FirebaseProviderError, notifyError('errors.firebase')],
      ],
      providers: {
        activities: activitiesProvider({
          firebaseUrl: (process.env.CONFIG as unknown as ClientConfig).duckyApi
            .firebaseUrl,
          duckyApiUrl: (process.env.CONFIG as unknown as ClientConfig).duckyApi
            .url,
        }),
        database: databaseProvider({
          fetch: fetch,
          baseUrl: (process.env.CONFIG as unknown as ClientConfig).firebase
            .databaseURL,
          ccmUrl: (process.env.CONFIG as unknown as ClientConfig).ccm.URL,
          language: languageData.locale,
        }),
        serviceWorker: ServiceWorkerProvider(),
        config: ConfigProvider(),
        router: RouterProvider(),
        forms: FormsProvider({
          rules: {
            isGreaterThan(value, arg) {
              return value > arg;
            },
            equalToWord(value, arg) {
              return arg.split('||').indexOf(value) > -1;
            },
            hasValidTLD(value) {
              const parts = value.split('.');

              if (parts.length === 0) return true;
              const tld = parts[parts.length - 1];

              return tlds.indexOf(tld.toUpperCase()) >= 0;
            },
          },

          errorMessages: {
            isGreaterThan() {
              return {
                phrase: 'authentication.fieldErrors.invalid_email',
                options: {},
              };
            },
            isEmail() {
              return {
                phrase: 'authentication.fieldErrors.invalid_email',
                options: {},
              };
            },
            isAlpha() {
              return {
                phrase: 'authentication.fieldErrors.only_letters',
                options: {},
              };
            },
            minLength(value, length) {
              return {
                phrase: 'authentication.fieldErrors.too_short',
                options: { length },
              };
            },
            isValue() {
              return {
                phrase: 'authentication.fieldErrors.mandatory',
                options: {},
              };
            },
            equalsField() {
              return {
                phrase: 'authentication.fieldErrors.password_mismatch',
                options: {},
              };
            },
            hasValidTLD(value) {
              const parts = value.split('.');

              if (parts.length === 0) return true;
              const tld = parts[parts.length - 1];

              return {
                phrase: 'authentication.fieldErrors.email_valid_TLD',
                options: { tld },
              };
            },
          },
        }),
        storage: StorageProvider(),
        firebase: FirebaseProvider({
          app: firebaseApp,
        }),
        translate: TranslateProvider(),
        http: HttpProvider(),
        sentry: SentryProvider(),
        urlAction: UrlActionProvider(),
        tracking: trackingProvider,
      },
    };
  };

  const app = App(rootModule);

  window.onbeforeunload = () => app.getSequence('appContainer.unloading')();

  return app;
}
