import { noop as noReturnValue } from './helpers';
import { deleteUser } from 'firebase/auth';
import { FirebaseProviderError } from './errors';

export default function deleteUserFactory(auth) {
  const user = auth.currentUser;

  if (!user) return Promise.resolve();

  return deleteUser(user)
    .then(noReturnValue)
    .catch(error => {
      throw new FirebaseProviderError(error);
    });
}
