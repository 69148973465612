import { state, props } from 'app/pwa/app.cerebral';
import { set, toggle, when } from 'cerebral/factories';

import * as actions from './actions';

export const toggleParticipationListMobile = toggle(
  state.challenge.visualisations.showMobileParticipationList
);

export const toggleActivityItemsMobile = toggle(
  state.challenge.visualisations.showMobileActivityItems
);

export const toggleToplistMobile = toggle(
  state.challenge.visualisations.showMobileSectionToplist
);

export const expandToplistClicked = [
  toggle(state.challenge.visualisations.toplistExpanded),
  when(state.challenge.visualisations.toplistExpanded),
  {
    true: [actions.trackLeaderboardExpanded],
    false: [
      set(state.challenge.visualisations.leaderboardPage, 0),
      actions.trackLeaderboardCollapsed,
    ],
  },
];

export const setLeaderboardTab = [
  set(state.challenge.visualisations.selectedLeaderboardTab, props`tab`),
  set(state.challenge.visualisations.leaderboardPage, 0),
  actions.trackLeaderboardTabClicked,
];

export const getChampionshipLeaderboard = [
  when(state.challenge.campaign.championshipKey),
  {
    true: [
      actions.getChampionshipLeaderboardData,
      actions.formatChampionshipLeaderboardData,
      actions.setChampionshipLeaderboardUpdateDate,
      set(
        state.challenge.visualisations.championshipLeaderboard,
        props`leaderboard`
      ),
    ],
    false: [],
  },
];

export const setLeaderboardPage = [
  set(state.challenge.visualisations.leaderboardPage, props`page`),
  actions.trackLeaderboardPageClicked,
];
