export function getCampaign(getData, campaignId) {
  const getCampaignData = getData(`campaigns/list/${campaignId}.json`);
  const getExpectedParticipantCounts = getData(
    `campaigns/expectedParticipantCounts/${campaignId}.json`
  );

  return Promise.all([getCampaignData, getExpectedParticipantCounts]).then(
    ([campaign, expectedParticipantCounts]) => {
      if (
        !campaign ||
        campaign.error ||
        !expectedParticipantCounts ||
        expectedParticipantCounts.error
      )
        return null;

      campaign.structure.expectedParticipantCount =
        expectedParticipantCounts.total;

      Object.keys(campaign.structure.sections).forEach(sectionKey => {
        campaign.structure.sections[sectionKey].expectedParticipantCount =
          expectedParticipantCounts.sections[sectionKey];

        Object.keys(campaign.structure.sections[sectionKey].teams).forEach(
          teamKey => {
            campaign.structure.sections[sectionKey].teams[
              teamKey
            ].expectedParticipantCount =
              expectedParticipantCounts.teams[teamKey];
          }
        );
      });

      return {
        id: campaignId,
        dataSource: campaign.dataSource ?? 'norway',
        ...campaign,
        ...campaign.config,
      };
    }
  );
}
export function getChallenge(getData, challengeId) {
  return getData(`challenges/list/${challengeId}.json`).then(data => {
    if (!data) return null;

    return {
      id: challengeId,
      ...data,
      ...data.config,
    };
  });
}

export function getOrganisation(getData, organisationId) {
  return getData(`organisations/list/${organisationId}.json`);
}

export function verifyDomainForCampaign(getData, domain, campaignId) {
  return getData(`campaigns/domains/${domain}/${campaignId}.json`);
}

export function getChampionshipConfig(getData, championshipId) {
  return getData(`championships/list/${championshipId}/config.json`);
}

export function getChampionshipSeries(getData, championshipId) {
  return getData(`championships/series/${championshipId}.json`);
}

export function getChampionshipCampaigns(getData, championshipId) {
  return getData(`championships/campaigns/${championshipId}.json`);
}
