import { ModuleDefinition } from 'cerebral';
import { State } from './types';
import * as sequences from './sequences';

const state: State = {
  authenticationError: null,
  isAuthenticating: false,
  registerForm: {
    name: {
      value: '',
      defaultValue: '',
      validationRules: ['minLength:3', 'isValue'],
    },
    email: {
      value: '',
      defaultValue: '',
      validationRules: ['isEmail', 'isValue', 'hasValidTLD'],
    },
    password: {
      value: '',
      defaultValue: '',
      validationRules: ['minLength:8', 'isValue'],
    },
    termsAccepted: {
      value: false,
      defaultValue: false,
      validationRules: ['isTrue'],
    },
  },
  logInForm: {
    email: {
      value: '',
      defaultValue: '',
      validationRules: ['isEmail', 'isValue', 'hasValidTLD'],
    },
    password: {
      value: '',
      defaultValue: '',
      validationRules: ['isValue'],
    },
  },
  verifyEmailForm: {
    name: {
      value: '',
      defaultValue: '',
      validationRules: ['minLength:3', 'isValue'],
    },
    email: {
      value: '',
      defaultValue: '',
      validationRules: ['isEmail', 'isValue', 'hasValidTLD'],
    },
  },
  forgotPasswordForm: {
    email: {
      value: '',
      defaultValue: '',
      isRequired: true,
      validationRules: ['isEmail', 'isValue'],
    },
  },
};

const module: ModuleDefinition = {
  state,
  sequences: {
    googleRegisterClicked: sequences.signInWithGoogle,
    facebookRegisterClicked: sequences.signInWithFacebook,
    registerSubmitted: sequences.createUserWithEmailAndPassword,
    logInSubmitted: sequences.logInWithEmailAndPassword,
    profileChanged: sequences.changeProfile,
    forgotPasswordSubmitted: sequences.resetPassword,
    resendConfirmEmailClicked: sequences.sendConfirmEmail,
  },
};

export default module;
