import {
  ref as dbRef,
  query,
  orderByValue,
  orderByKey,
  orderByChild,
  startAt,
  endAt,
  off,
  onValue,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from 'firebase/database';
import { ref as storageRef } from 'firebase/storage';
import { FirebaseProviderError } from './errors';
const refs = {};

export function createRef(database, path, options = {}) {
  if (path.indexOf('/') >= 0) {
    throw new FirebaseProviderError(
      new Error(
        `The path "${path}" is not valid. Use dot notation for consistency with Cerebral`
      )
    );
  }
  path = path.replace(/\./gu, '/');

  return Object.keys(options).reduce(
    (q, key) => {
      switch (key) {
        case 'orderByKey':
          return options[key] ? query(q, orderByKey(options[key])) : q;
        case 'orderByChild':
          return options[key] ? query(q, orderByChild(options[key])) : q;
        case 'orderByValue':
          return options[key] ? query(q, orderByValue()) : q;
        case 'startAt':
          return query(q, startAt(options[key]));
        case 'endAt':
          return query(q, endAt(options[key]));
        default:
          return q;
      }
    },
    query(dbRef(database, path))
  );
}

export function createStorageRef(storage, path) {
  if (path.indexOf('/') >= 0) {
    throw new FirebaseProviderError(
      new Error(
        `The path "${path}" is not valid. Use dot notation for consistency with Cerebral`
      )
    );
  }
  path = path.replace(/\./gu, '/');

  return storageRef(storage, path);
}

export function listenTo(ref, path, event, signal, cb) {
  refs[path] = refs[path] || {};
  refs[path][event] = refs[path][event] ? refs[path][event].concat(ref) : [ref];

  const events = {
    value: onValue,
    child_added: onChildAdded,
    child_changed: onChildChanged,
    child_removed: onChildRemoved,
  };

  events[event](ref, cb, error => {
    throw new FirebaseProviderError(
      new Error(
        `${event} listener to path ${path}, triggering signal: ${signal}, gave error: ${error.message}`
      )
    );
  });
}

const events = {
  onChildAdded: 'child_added',
  onChildChanged: 'child_changed',
  onChildRemoved: 'child_removed',
  onValue: 'value',
  '*': '*',
};

export function stopListening(passedPath, event) {
  const realEventName = events[event] || '*';
  const pathArray = passedPath.split('.');
  let path = passedPath;
  let isWildcardPath = false;

  if (event && !realEventName) {
    throw new FirebaseProviderError(
      new Error(
        `The event "${event}" is not a valid event. Use: "${Object.keys(
          events
        )}`
      )
    );
  }

  if (pathArray[pathArray.length - 1] === '*') {
    isWildcardPath = true;
    pathArray.pop();
    path = pathArray.join('.');
  }

  let refsHit = [];

  if (isWildcardPath) {
    refsHit = Object.keys(refs).reduce((currentKeysHit, refPath) => {
      if (refPath.indexOf(path) === 0) {
        return currentKeysHit.concat(refPath);
      }

      return currentKeysHit;
    }, []);
  } else {
    refsHit = refs[path] ? [path] : [];
  }

  if (!refsHit.length) {
    throw new FirebaseProviderError(
      new Error(`The path "${path}" has no listeners`)
    );
  }

  refsHit.forEach(refPath => {
    const ref = refs[refPath];

    if (realEventName === '*') {
      Object.keys(ref).forEach(eventName => {
        ref[eventName].forEach(listener => off(listener));
        delete ref[eventName];
      });
    } else {
      if (!ref[realEventName]) {
        throw new FirebaseProviderError(
          new Error(`The path"${path}" has no listeners for "${realEventName}"`)
        );
      }
      ref[realEventName].forEach(listener => off(listener));
      delete ref[realEventName];
    }

    if (Object.keys(ref).length === 0) {
      delete refs[refPath];
    }
  });
}

export function createUser(user) {
  return {
    uid: user.uid,
    accessToken: user.accessToken,
    isAnonymous: user.isAnonymous,
    providerData: user.providerData,
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    photoURL: user.providerData.some(d => d.providerId === 'facebook.com') // We don't want facebook images, as they come with tracking
      ? null
      : user.photoURL,
  };
}

export function createReturnPromise(returnPromise, path) {
  let promise = returnPromise;

  if (!path) {
    promise = promise
      .then(response => (response ? { response } : {}))
      .catch(error => ({ error }));
  }

  if (path && path.success) {
    promise = promise.then(response =>
      response ? path.success({ response }) : path.success()
    );
  }
  if (path && path.error) {
    promise = promise.catch(error => path.error({ error }));
  }

  return promise;
}

export function noop() {}

export function snapshotToArray(snapshot) {
  const result = [];

  snapshot.forEach(childSnapshot => {
    result.push({
      key: childSnapshot.key,
      value: childSnapshot.val(),
    });
  });

  return result;
}
