/* eslint-disable max-lines, no-shadow */
import { sequence, parallel, set, equals, when } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { notify } from 'common/factories';
import { sum } from 'utils/common';
import { getProfilesByProperty, getProfile } from '../../factories';

import * as actions from './actions';

export const getEvents = [
  sequence('getEvents', [
    parallel('getEventData', [
      actions.getGlobalEvents,
      actions.getTeamEvents,
      actions.getTeamStaticEvents,
    ]),
    actions.combineEvents,
    set(state`challenge.events.events`, props`combined`),
    parallel('getEventProfiles', [
      getProfilesByProperty(props`globalEvents`, 'profileKey'),
      getProfilesByProperty(props`teamEvents`, 'profileKey'),
      getProfilesByProperty(props`teamStaticEvents`, 'profileKey'),
    ]),
    set(state`challenge.isLoading.events`, false),
  ]),
];

export const updateEvents = [
  actions.updateEvents,
  equals(props`value.type`),
  {
    participantJoined: [
      getProfile(props`value.profileKey`),
      set(state`challenge.teamMembers.${props`value.profileKey`}`, {
        joinedDatetime: Date.now(),
      }),
      when(
        props`value.profileKey`,
        state`app.user.uid`,
        (profileKey, userProfileKey) => profileKey !== userProfileKey
      ),
      {
        true: [
          actions.outputJoinedCount,
          notify(
            'participantJoined',
            {
              profileKey: props`value.profileKey`,
              joinedCount: props`joinedCount`,
            },
            {
              debounce: true,
            }
          ),
        ],
        false: [],
      },
    ],
    otherwise: [],
  },
];

export const updateStaticEvents = [
  actions.updateStaticEvents,
  equals(props`type`),
  {
    dailySummaryUpdates: [
      when(
        props`value.activitiesCount`,
        props`key`,
        state`appContainer.user.uid`,
        (activitiesCount, profileKey, userProfileKey) =>
          sum(activitiesCount || {}) > 0 && profileKey !== userProfileKey
      ),
      {
        true: [
          set(state`challenge.events.hasReceivedDailySummaryUpdateEvent`, true),
          notify(
            'dailySummaryUpdates',
            {
              profileKey: props`key`,
              activitiesCount: props`value.activitiesCount`,
            },
            {
              debounce: true,
            }
          ),
        ],
        false: [],
      },
    ],
    otherwise: [],
  },
];

export const updateParticipantCounts = [actions.setChallengeParticipantCounts];

export { listenForEvents } from './actions';

export const notifyLatestEvents = [
  when(state`challenge.events.hasReceivedDailySummaryUpdateEvent`),
  {
    true: [],
    false: [
      actions.getTodaysLatestDailySummaryEvent,
      when(props`latestEvent`),
      {
        true: [
          notify(
            'dailySummaryUpdates',
            {
              profileKey: props`latestEventProfileKey`,
              activitiesCount: props`latestEvent.activitiesCount`,
            },
            {
              debounce: true,
            }
          ),
        ],
        false: [],
      },
    ],
  },
];
