import { sequence, set, equals, when } from 'cerebral/factories';
import { notify } from 'common/factories';
import { rendered } from 'common/appContainer/module/factories';
import { state, props } from 'cerebral';
import * as actions from './actions';

const urlActions = [
  sequence('accountUrlActions', [
    set(state`account.showDeleteAccountModal`, false),
    set(state`account.timeline.explanationModalShowing`, false),
    equals(props`action`),
    {
      deleteAccount: set(state`account.showDeleteAccountModal`, true),
      otherwise: [],
    },
  ]),
];

export function renderPage(page, continueChain = []) {
  return rendered([
    set(state`app.page`, page),
    set(state`account.pageLoaded`, false),
    set(state`appContainer.currentPage`, 'accountOnboarding'),
    set(state`appContainer.auth.authenticationError`, null),
    urlActions,
    continueChain,
    actions.getSnackbarMessage,
    when(props`snackbarMessage`),
    {
      true: [
        notify(props`snackbarMessage`),
        actions.deleteAccountDeletedSnackbarMessage,
      ],
      false: [],
    },
  ]);
}
