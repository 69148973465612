import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getIndicator, sum } from 'utils/common';
import { getAvatarUrl, getDefaultAvatarColor } from 'utils/avatars';
import { INDICATOR } from 'resources/constants';
import { orderAndFormatLeaderboard } from 'utils/challenge';

export default Compute(get => {
  const staticUrl = get(state.appContainer.config.staticUrl);
  const userCampaign = get(state.challenge.userCampaign);
  const sections = get(state.challenge.campaign.structure.sections) || {};
  const sectionScores = get(state.challenge.stats.total.sections) || {};
  const indicator = get(state.challenge.campaign.indicator) || INDICATOR.co2;
  const leaderboardPage = get(state.challenge.visualisations.leaderboardPage);
  const leaderboardPageSize = get(
    state.challenge.visualisations.leaderboardPageSize
  );
  const isMobile = get(state.app.isMobile);
  const leaderboardIsExpanded = get(
    state.challenge.visualisations.toplistExpanded
  );

  const leaderboard = Object.keys(sections).map(sectionKey => ({
    key: sectionKey,
    isEmphasised: sectionKey === userCampaign?.sectionKey,
    name: sections[sectionKey].name,
    avatar: getAvatarUrl(staticUrl, { name: sections[sectionKey].name }).small,
    avatarLarge: getAvatarUrl(staticUrl, { name: sections[sectionKey].name })
      .large,
    avatarColor: getDefaultAvatarColor(sections[sectionKey].name),
    activityCount: sum(sectionScores?.[sectionKey]?.activitiesCount || {}),
    value: getIndicator(sectionScores?.[sectionKey], indicator, 'average'),
  }));

  return orderAndFormatLeaderboard(
    leaderboard,
    leaderboardPage,
    isMobile ? Infinity : leaderboardPageSize,
    leaderboardIsExpanded
  );
});
