import { state, sequence } from 'cerebral';

function mergeProfile(currentProfile, newProfile) {
  if (!newProfile) return null;

  return {
    ...currentProfile,
    ...newProfile,
  };
}

export function getProfilesByKeys(valueTag) {
  return sequence('getProfilesByKeys', [
    function getProfilesByKeysAction({ firebase, resolve }) {
      const value = resolve.value(valueTag);

      return Promise.all(
        Object.keys(value).map(profileKey =>
          firebase
            .value(`profiles.list.${profileKey}.public`)
            .then(response => ({ key: profileKey, value: response.value }))
        )
      ).then(profiles => ({ profiles }));
    },
    function setProfilesByKeys({ props, get, store }) {
      const loggedUserUid = get(state`appContainer.user.uid`);
      const loggedUserProfile = get(
        state`appContainer.profiles.${loggedUserUid}`
      );

      const profilesObject = props.profiles.reduce(
        (currentProfilesObject, profile) => {
          currentProfilesObject[profile.key] = mergeProfile(
            currentProfilesObject[profile.key],
            profile.value
          );

          return currentProfilesObject;
        },
        { [loggedUserUid]: { ...loggedUserProfile } }
      );

      store.merge(state`appContainer.profiles`, profilesObject);
    },
  ]);
}

export function getProfilesByProperty(listTag, propertyName) {
  return sequence('getProfilesByProperty', [
    function getProfilesByPropertyAction({ firebase, resolve }) {
      const list = resolve.value(listTag);

      return Promise.all(
        Object.keys(list)
          .filter(key => list[key][propertyName])
          .map(key => {
            const profileKey = list[key][propertyName];

            return firebase
              .value(`profiles.list.${profileKey}.public`)
              .then(response => ({ key: profileKey, value: response.value }));
          })
      ).then(profiles => ({ profiles }));
    },
    function setProfilesByProperty({ props, get, store }) {
      const loggedUserUid = get(state`appContainer.user.uid`);
      const loggedUserProfile = get(
        state`appContainer.profiles.${loggedUserUid}`
      );

      const profilesObject = props.profiles.reduce(
        (currentProfilesObject, profile) => {
          currentProfilesObject[profile.key] = mergeProfile(
            currentProfilesObject[profile.key],
            profile.value
          );

          return currentProfilesObject;
        },
        { [loggedUserUid]: { ...loggedUserProfile } }
      );

      store.merge(state`appContainer.profiles`, profilesObject);
    },
  ]);
}

export function getProfile(profileKeyTag) {
  return sequence('getProfile', [
    function getProfileAction({ firebase, resolve }) {
      const profileKey = resolve.value(profileKeyTag);

      return firebase
        .value(`profiles.list.${profileKey}.public`)
        .then(result => ({ profile: result.value, profileKey }));
    },
    function setProfile({ props, store }) {
      if (props.profile) {
        store.merge(
          state`appContainer.profiles.${props.profileKey}`,
          props.profile
        );
      } else {
        store.unset(state`appContainer.profiles.${props.profileKey}`);
      }
    },
  ]);
}
