import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import { renderPage } from '../../factories';

import { OwnState } from './types';

const state: OwnState = {
  loaded: false,
  campaign: null,
  campaigns: {},
  campaignKey: null,
  isJoining: false,
  isJoiningChallenge: false,
  isValidatingCode: false,
  selections: {
    campaign: null,
    section: null,
    team: null,
  },
  fields: {
    campaign: '',
    section: '',
    team: '',
  },
  code: '',
  codeNames: {
    team: '',
    section: '',
    campaign: '',
  },
  codeError: null,
  joiningError: null,
  subPage: null,
  dropdown: null,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    routed: renderPage('joinCampaign', sequences.render),
    hideOtherDropdown: sequences.hideOtherDropdown,
    toggleShowDropdown: sequences.toggleShowDropdown,
    showDropdown: sequences.showDropdown,
    optionSelected: sequences.optionSelected,
    optionChanged: sequences.optionChanged,
    joinCampaignCode: sequences.joinCampaignCode,
    joinCampaignSectionAndTeam: sequences.joinCampaignSectionAndTeam,
    codeChanged: sequences.codeChanged,
  },
};

export default module;
