import dayjs from 'dayjs';
import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { type Tool } from 'account/module/modules/timeline/types';

export default Compute(get => {
  const campaigns = get(state.account.timeline.campaigns);
  const computedCampaigns = { past: [], current: [], future: [] };

  Object.keys(campaigns || {})
    .map(campaignKey => ({
      id: campaignKey,
      startDate: dayjs(campaigns[campaignKey].startDatetime).format(
        'DD/MM/YYYY'
      ),
      endDate: dayjs(campaigns[campaignKey].endDatetime).format('DD/MM/YYYY'),
      organisationName: campaigns[campaignKey]?.organisation?.name,
      campaignName: campaigns[campaignKey].name,
      topic: campaigns[campaignKey].topic,
      campaignPageUrl: campaigns[campaignKey].campaignPageUrl,
      tools: Object.assign(
        {},
        // TODO: Remove array as survey no longer exists (so only one tool)
        ...['challenge']
          .filter(toolType => campaigns[campaignKey][toolType])
          .map(tool => {
            if (String(tool) === 'challenge') {
              const { startDatetime, endDatetime, id } =
                campaigns[campaignKey].challenge;

              let toolState: Tool['state'] = 'during';

              if (startDatetime > Date.now()) {
                toolState = 'before';
              } else if (endDatetime < Date.now()) {
                toolState = 'after';
              }

              return {
                [id]: {
                  type: 'challenge',
                  routeKey: id,
                  state: toolState,
                  startDatetime: startDatetime,
                  startDate: dayjs(startDatetime).format('DD/MM/YYYY'),
                  endDate: dayjs(endDatetime).format('DD/MM/YYYY'),
                },
              };
            }

            return null;
          })
      ),
    }))
    .forEach(campaign => {
      if (campaigns[campaign.id].endDatetime < Date.now()) {
        computedCampaigns.past.push(campaign);
      } else if (campaigns[campaign.id].startDatetime > Date.now()) {
        computedCampaigns.future.push(campaign);
      } else {
        computedCampaigns.current.push(campaign);
      }
    });

  return computedCampaigns;
});
