/* eslint-disable no-useless-escape, no-control-regex, prefer-named-capture-group */
import { state } from 'cerebral';

const rules = {
  _errorMessages: {},
  isExisty(value) {
    return value !== null && value !== undefined;
  },
  isEmpty(value) {
    return value === '';
  },
  regexp(value, regexp) {
    return !rules.isExisty(value) || rules.isEmpty(value) || regexp.test(value);
  },
  isValue(value) {
    return (
      value !== undefined &&
      value !== '' &&
      value !== null &&
      value !== false &&
      (!Array.isArray(value) || Boolean(value.length))
    );
  },
  isUndefined(value) {
    return value === undefined;
  },
  isEmail(value) {
    return rules.regexp(
      value,
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u
    );
  },
  isUrl(value) {
    return rules.regexp(
      value,
      /^(?:https?|s?ftp):\/\/(?:(?:(?:(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(?:(?:(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(?:\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|(?:(?:(?:[a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(?:(?:[a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(?::\d*)?)(?:\/(?:(?:(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(?:\/(?:(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(?:\?(?:(?:(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(?:#(?:(?:(?:[a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/iu
    );
  },
  isTrue(value) {
    return value === true;
  },
  isFalse(value) {
    return value === false;
  },
  isNumeric(value) {
    if (typeof value === 'number') {
      return true;
    }

    return rules.regexp(value, /^[-+]?(?:\d*[.])?\d+$/u);
  },
  isAlpha(value) {
    return rules.regexp(value, /^[A-Z]+$/iu);
  },
  isAlphanumeric(value) {
    return rules.regexp(value, /^[0-9A-Z]+$/iu);
  },
  isInt(value) {
    return rules.regexp(value, /^(?:[-+]?(?:0|[1-9]\d*))$/u);
  },
  isFloat(value) {
    return rules.regexp(
      value,
      /^(?:[-+]?(?:\d+))?(?:\.\d*)?(?:[eE][\+\-]?(?:\d+))?$/u
    );
  },
  isWords(value) {
    return rules.regexp(value, /^[A-Z\s]+$/iu);
  },
  isSpecialWords(value) {
    return rules.regexp(value, /^[A-Z\s\u00C0-\u017F]+$/iu);
  },
  isLength(value, length) {
    return value.length === length;
  },
  equals(value, eql) {
    return value === eql;
  },
  equalsField(value, field, get) {
    return value === get(state`${field}.value`);
  },
  maxLength(value, length) {
    return !rules.isExisty(value) || value.length <= length;
  },
  minLength(value, length) {
    return (
      !rules.isExisty(value) || rules.isEmpty(value) || value.length >= length
    );
  },
};

export default rules;
