import 'firebase/database';
import 'firebase/auth';
import { FirebaseProviderError } from './errors';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

export function createTaskFactory(functions, taskName) {
  return (name, payload = {}) => {
    if (process.env.NODE_ENV !== 'production') {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }

    return httpsCallable(
      functions,
      taskName
    )({
      name,
      payload: {
        data: payload,
      },
    })
      .then(result => result.data)
      .catch(error => {
        throw new FirebaseProviderError(error);
      });
  };
}
