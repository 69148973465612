import { set, toggle } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { clearUrl } from 'common/appContainer/module/actions.js';

import * as actions from './actions';

export const setTabIndex = [
  set(state`challenge.activityModal.tabIndex`, props`index`),
];

export const closeActivityModalClicked = [
  set(state`appContainer.shouldResetScroll`, false),
  actions.resetShowMoreInfo,
  set(state`challenge.activityModal.tabIndex`, 0),
  clearUrl,
];

export const toggleIndicatorExpanded = toggle(
  state`challenge.activityModal.showMoreInfo.${props`indicator`}`
);
