import { getIdToken } from 'firebase/auth';
import { FirebaseProviderError } from './errors';

export default function uploadAdminImageFactory(auth) {
  return function uploadAdminImageFunction(file, { size, path }) {
    const user = auth.currentUser;

    if (!user) throw new FirebaseProviderError('Not authenticated');

    const formData = new FormData();

    formData.append('image', file, file.name);
    formData.append('size', size);
    formData.append('path', path);

    const { cloudFunctionUrl } = this.context.controller.getState(
      'appContainer.config'
    );

    return getIdToken(user)
      .then(token =>
        fetch(`${cloudFunctionUrl}/requests-admin-uploadImage`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        })
      )
      .then(response => {
        if (response.ok) {
          return response.json().then(jsonResponse => ({
            response: jsonResponse,
            statusCode: response.status,
          }));
        }

        throw new FirebaseProviderError(new Error('Request error'));
      });
  };
}
