import { Compute } from 'cerebral';

import { getDaysInMilliseconds } from 'utils/common';
import { state } from 'app/pwa/app.cerebral';

export default Compute(get => {
  const habits = get(state.challenge.userChallenge.habits) || {};
  const appActivities = get(state.appContainer.activities);
  const startDatetime = get(state.challenge.campaign.challenge.startDatetime);
  const dailyUserStats = get(state.challenge.stats.daily.user);
  const selectedDayIndex = get(state.challenge.log.selectedDayIndex);

  const selectedDayKey =
    startDatetime + getDaysInMilliseconds(selectedDayIndex);
  const todaysLoggedActivityKeys = Object.keys(
    (dailyUserStats[selectedDayKey] &&
      dailyUserStats[selectedDayKey].activitiesCount) ||
      {}
  )
    .filter(
      activityKey => dailyUserStats[selectedDayKey].activitiesCount[activityKey]
    )
    .filter(activityKey => appActivities[activityKey]);

  return todaysLoggedActivityKeys.filter(activityKey =>
    Boolean(habits[activityKey])
  );
});
