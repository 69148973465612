import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getGoalValue } from 'utils/challenge';
import { GOAL_STATUS } from 'resources/constants';

export default Compute(get => {
  const goalsData = get(state.challenge.userChallenge.goals);
  const maxScore = get(state.challenge.maxScore);

  const goals = Object.entries(goalsData || {}).map(([goalKey, goal]) => ({
    goalKey,
    score: getGoalValue(maxScore, goal.level),
    ...goal,
  }));

  if (goals.length === 0) {
    return {
      status: GOAL_STATUS.NONE,
    };
  }

  const currentGoal = goals.find(goal => !goal.achieved);

  if (currentGoal) {
    return {
      status: GOAL_STATUS.CURRENT,
      goal: currentGoal,
    };
  }

  const lastGoal = goals.sort(
    (a, b) => b.datetimeAchieved - a.datetimeAchieved
  )[0];

  return {
    status: GOAL_STATUS.ACHIEVED,
    goal: lastGoal,
  };
});
