import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { TAG_CATEGORY } from 'resources/constants';

const CATEGORY_ORDER = [
  TAG_CATEGORY.consumption,
  TAG_CATEGORY.food,
  TAG_CATEGORY.energy,
  TAG_CATEGORY.transport,
  TAG_CATEGORY.microplastics,
];

const sortByNumericalValueAscending = (a, b) => Number(a) - Number(b);

// Sorts in the same order as CATEGORY_ORDER. Any extra elements gets added to the end of the array
const sortByCategoryOrder = (a, b) => {
  const aIndex = CATEGORY_ORDER.indexOf(a);
  const bIndex = CATEGORY_ORDER.indexOf(b);

  if (aIndex === -1 && bIndex === -1) return 0;
  if (aIndex === -1) return 1;
  if (bIndex === -1) return -1;

  return CATEGORY_ORDER.indexOf(a) - CATEGORY_ORDER.indexOf(b);
};

export default Compute(get => {
  const activities = get(state.challenge.campaign.challenge.activities) || {};
  const appActivities = get(state.appContainer.activities);
  const showTutorial = get(state.challenge.tutorial.show);
  const popularActivities = get(
    state.challenge.log.computedMostPopularActivities
  );

  function getAvailableActivities(activitiesInUse, allActivities) {
    const availableActivitiesObject = {};

    for (const activity in allActivities) {
      if (activitiesInUse.indexOf(activity) > -1) {
        availableActivitiesObject[activity] = allActivities[activity];
      }
    }

    return availableActivitiesObject;
  }

  const availableActivities = Object.keys(activities || {}).filter(
    activityKey => {
      if (activityKey === 'activity_tutorial_01') {
        if (showTutorial) {
          return activities[activityKey];
        }

        return false;
      }

      return activities[activityKey];
    }
  );

  const activitiesObject = getAvailableActivities(
    availableActivities,
    appActivities
  );

  const tagsObject: { [key: string]: string[] } = {};

  Object.keys(activitiesObject || {}).forEach(activityKey => {
    Object.keys(activitiesObject[activityKey].tags || {}).forEach(
      tagCategory => {
        if (!tagsObject[tagCategory]) {
          tagsObject[tagCategory] = [];
        }
        activitiesObject[activityKey].tags[tagCategory].forEach(tag => {
          if (tagsObject[tagCategory].indexOf(tag) === -1) {
            if (tag === TAG_CATEGORY.tutorial) {
              tagsObject[tagCategory].unshift(tag);
            } else {
              tagsObject[tagCategory].push(tag);
            }
          }
        });
      }
    );
  });

  return [
    'all',
    'habits',
    ...(tagsObject['categories'] || []).sort(sortByCategoryOrder),
    ...(popularActivities.length > 0 ? ['popular'] : []),
    ...(tagsObject['sdgs'] || []).sort(sortByNumericalValueAscending),
  ];
});
