import { set, when } from 'cerebral/factories';
import { state, props, string, sequence } from 'cerebral';
import { value, deleteUser } from 'providers/Firebase/src/operators';
import * as actions from './actions';
import * as appFactories from '../../factories';
import { TSequenceArray } from 'function-tree';

// If an email comes from facebook or google, we trust it is verified
const trustedEmailProviders = ['facebook.com', 'google.com'];

const setUser = sequence('setUser', [
  set(state`appContainer.user`, props`user`),
  value(string`profiles.list.${props`user.uid`}`),
  when(props`response.value`, user => user?.email), // Check if user has been created properly with email
  {
    true: [],
    false: [
      actions.createProfile,
      value(string`profiles.list.${props`user.uid`}`),
    ],
  },
  actions.setProfileFromSetUser,
  actions.listenForProfileChanges,
  actions.setUserSentry,
]);

export function authenticate(authenticatedChain = []): TSequenceArray {
  return [
    set(state`appContainer.auth.isAuthenticating`, true),
    actions.getUser,
    when(props`user`),
    {
      true: [
        setUser,
        set(state`appContainer.auth.isAuthenticating`, false),
        when(
          state`appContainer.profiles.${state`appContainer.user.uid`}.termsAccepted`,
          state`appContainer.termsUpdatedDatetime`,
          (datetimeAccepted, termsUpdatedDatetime) =>
            datetimeAccepted && datetimeAccepted > termsUpdatedDatetime
        ),
        {
          true: [
            when(
              state`appContainer.user.providerData.0.providerId`,
              state`appContainer.user.emailVerified`,
              props`page`, // Do not redirect to confirm email when trying to load confirmEmail
              (provider, verified, page) =>
                // facebook does not guarantee the legitimacy of the email so user logged in
                // through facebook have their emailVerified property set to false by default.
                // So, skip email verification if the provider is 'facebook.com'
                trustedEmailProviders.includes(provider) ||
                verified ||
                page === 'confirmEmail'
            ),
            {
              true: authenticatedChain,
              false: [actions.redirectToConfirmEmail],
            },
          ],
          false: [
            set(state`appContainer.isAcceptingTerms`, true),
            appFactories.setUrlAction('terms'),
            ...authenticatedChain,
          ],
        },
      ],
      false: [
        when(props`requireAuth`),
        {
          true: [actions.redirectToRegister],
          false: authenticatedChain,
        },
      ],
    },
    set(state`appContainer.auth.isAuthenticating`, false),
  ];
}

export function signIn(signInActions) {
  return sequence('signIn', [
    set(state`appContainer.auth.isAuthenticating`, true),
    set(state`appContainer.auth.authenticationError`, null),
    signInActions,
    when(props`user`),
    {
      true: [
        when(props`user.email`),
        {
          true: [
            setUser,
            set(state`appContainer.auth.isAuthenticating`, false),
            when(props`noRedirect`),
            {
              true: [authenticate([])],
              false: [actions.getContinueUrl, actions.redirectToUrl],
            },
          ],
          false: [
            deleteUser(),
            set(state`appContainer.auth.authenticationError`, {
              message: 'authentication.errors.facebook_has_no_email',
            }),
            set(state`appContainer.auth.isAuthenticating`, false),
          ],
        },
      ],
      false: [set(state`appContainer.auth.isAuthenticating`, false)],
    },
  ]);
}
