import { Provider, state } from 'cerebral';
import rules from './src/rules';
import resetForm from './src/helpers/resetForm';
import formToJSON from './src/helpers/formToJSON';
import { Form } from './src/form';
export { Form, Field } from './src/form';

export default function FormsProvider(options = {}) {
  if (options.rules) {
    Object.assign(rules, options.rules);
  }

  if (options.errorMessages) {
    rules._errorMessages = options.errorMessages;
  }

  return Provider({
    get(path) {
      const value = this.context.resolve.value(state`${path}`);

      return new Form(value, this.context.get);
    },
    reset(path) {
      this.context.state.set(path, resetForm(this.context.state.get(path)));
    },
    toJSON(path) {
      return formToJSON(this.context.state.get(path));
    },
    updateRules(newRules) {
      Object.assign(rules, newRules);
    },
    updateErrorMessages(errorMessages) {
      Object.assign(rules._errorMessages, errorMessages);
    },
  });
}
