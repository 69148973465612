import { ModuleDefinition } from 'cerebral';
import { State } from './types';

const state: State = {
  isMobile: false,
  page: 'register',
  path: '',
  tool: 'account',
  campaigns: {},
  params: {},
};

const module: ModuleDefinition = {
  state,
};

export default module;
