import { state } from 'cerebral';

export function hideAddToHomeScreenLocalStorage({ storage }) {
  storage.set('hideAddChallengeToHomeScreen', true);
}

export function saveGoal({ get, props, firebase }) {
  const challengeKey = get(state`challenge.key`);

  return firebase.task('set_goal', {
    challengeKey,
    level: Number(props.level),
  });
}

export function updateGoal({ get, props, firebase }) {
  const challengeKey = get(state`challenge.key`);

  return firebase.task('update_goal', {
    challengeKey,
    level: Number(props.level),
    goalKey: props.goalKey,
  });
}

export function setAchievedRecognitionClosed({ get, props, firebase }) {
  const challengeKey = get(state`challenge.key`);

  return firebase.task('update_user_challenge_goal_seen', {
    challengeKey,
    goalKey: props.goalKey,
  });
}

export function setNpsScore({ get, firebase }) {
  const challengeKey = get(state`challenge.key`);
  const npsForm = get(state`challenge.info.npsForm`);

  return firebase.task('set_nps_score', {
    score: npsForm.score,
    comment: npsForm.comment,
    challengeKey: challengeKey,
  });
}

export function dismissNps({ get, firebase }) {
  const challengeKey = get(state`challenge.key`);

  return firebase.task('set_nps_score', {
    challengeKey,
    dismissed: true,
  });
}

export function setSeenApiInfo({ get, firebase }) {
  const challengeKey = get(state`challenge.key`);

  return firebase.task('update_user_challenge_api_info', {
    challengeKey: challengeKey,
  });
}
