import { set, when } from 'cerebral/factories';
import { state, props } from 'cerebral';

import * as actions from './actions';
import * as factories from './factories';

export const changeProfile = [
  when(props`value`),
  {
    true: [actions.setUserProfile],
    false: [actions.reload],
  },
];

export const createUserWithEmailAndPassword = [
  set(props`termsAccepted`, true),
  factories.signIn([
    actions.createUserWithEmailAndPassword,
    {
      success: [set(state`account.registrationSuccessful`, true)],
      error: [actions.setAuthenticationError],
    },
  ]),
];

export const logInWithEmailAndPassword = [
  factories.signIn([
    actions.logInWithEmailAndPassword,
    {
      success: [],
      error: [actions.setAuthenticationError],
    },
  ]),
];

export const signInWithGoogle = [
  factories.signIn([
    actions.loginWithGoogle,
    {
      success: [],
      error: [actions.setAuthenticationError],
    },
  ]),
];

export const signInWithFacebook = [
  factories.signIn([
    actions.loginWithFacebook,
    {
      success: [],
      error: [actions.setAuthenticationError],
    },
  ]),
];

export const resetPassword = [
  set(state`account.isSendingPasswordEmail`, true),
  actions.sendPasswordResetEmail,
  {
    success: [set(state`account.hasSentPasswordEmail`, true)],
    error: [actions.setAuthenticationError],
  },
  set(state`account.isSendingPasswordEmail`, false),
];

export const sendConfirmEmail = [
  set(state`account.isSendingConfirmEmail`, true),
  actions.sendConfirmEmail,
  {
    success: [],
    error: [actions.setAuthenticationError],
  },
  set(state`account.isSendingConfirmEmail`, false),
  actions.redirectToConfirmEmail,
];

export const redirectToLogin = [actions.redirectToLogin];
