import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getCurrentDatetime } from 'utils/common';
import { STREAKS } from 'resources/constants';

const getCurrentStreak = (currentDatetime, startOfDays, dailyUserStats) => {
  const previousDatetimes = startOfDays
    .filter(datetime => datetime <= currentDatetime)
    .reverse();
  const streakBreakIndex = previousDatetimes.findIndex(
    (datetime, index) => index > 0 && !dailyUserStats?.[datetime]
  );

  return previousDatetimes.slice(
    dailyUserStats?.[previousDatetimes[0]] ? 0 : 1,
    streakBreakIndex === -1 ? Infinity : streakBreakIndex
  );
};

const getStreakInfoText = (
  currentDatetime,
  startOfDays,
  currentStreak,
  hasLogged
) => {
  // First Day or Challenge not started
  if (currentDatetime === startOfDays[0]) {
    return { title: STREAKS.trackDays, text: STREAKS.getAStreak };
  }

  // Last Day or Challenge Ended
  if (currentDatetime >= startOfDays[startOfDays.length - 1]) {
    // Logged Everyday
    if (currentStreak.length === startOfDays.length) {
      return {
        title: STREAKS.loggedEvery,
        text: STREAKS.niceWork,
      };
    }

    // Didn't log everyday
    return {
      title: STREAKS.loggedX,
      text: STREAKS.blank,
    };
  }

  // User has joined but not yet logged anything
  if (!hasLogged) {
    return { title: STREAKS.trackDays, text: STREAKS.getAStreak };
  }

  // During Challenge, with streak
  if (currentStreak.length > 0) {
    return {
      title: STREAKS.xDayStreak,
      text: STREAKS.keepItGoing,
    };
  }

  // During Challenge, without streak
  return {
    title: STREAKS.missedADay,
    text: STREAKS.lostStreak,
  };
};

const getStreakInfoData = (currentDatetime, startOfDays, dailyUserStats) =>
  startOfDays.map(datetime => {
    if (datetime > currentDatetime) {
      // In the future
      return {
        id: datetime,
        color: '#F4F7F9',
        borderColor: '#90A4AE',
        borderWidth: 1,
      };
    }
    if (dailyUserStats?.[datetime]) {
      // If logged
      return {
        id: datetime,
        color: '#FF7043',
        borderColor: datetime === currentDatetime ? '#BF360C' : null,
        borderWidth: datetime === currentDatetime ? 3 : 1,
      };
    }

    return {
      id: datetime,
      color: '#90A4AE',
      borderColor: datetime === currentDatetime ? '#546E7A' : null,
      borderWidth: datetime === currentDatetime ? 3 : 1,
    };
  });

export default Compute(get => {
  const indicator = get(state.challenge.campaign.indicator);
  const dailyUserStats = get(state.challenge.stats.daily.user);
  const startOfDays = get(state.challenge.campaign.challenge.startOfDays) || [];

  const highestScore = Math.max(
    0,
    ...Object.values(dailyUserStats || {}).map(d => d?.[indicator] ?? 0)
  );
  const currentDatetime = getCurrentDatetime(startOfDays);
  const currentStreak = getCurrentStreak(
    currentDatetime,
    startOfDays,
    dailyUserStats
  );
  const hasLogged = Object.keys(dailyUserStats || {}).length > 0;
  const streakInfoText = getStreakInfoText(
    currentDatetime,
    startOfDays,
    currentStreak,
    hasLogged
  );
  const streakInfoData = getStreakInfoData(
    currentDatetime,
    startOfDays,
    dailyUserStats
  );
  const streakInfo = {
    ...streakInfoText,
    data: streakInfoData,
    daysLogged: Object.keys(dailyUserStats || {}).length,
    daysMissed: startOfDays.filter(
      datetime => datetime <= currentDatetime && !dailyUserStats[datetime]
    ).length,
  };

  return { highestScore, currentStreak, streakInfo };
});
