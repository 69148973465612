function resetObject(form) {
  function resetArray(formArray) {
    return formArray.reduce((newFormArray, nestedForm, index) => {
      newFormArray[index] = resetObject(nestedForm);

      return newFormArray;
    }, []);
  }

  return Object.keys(form).reduce((newForm, key) => {
    if (form[key] === Object(form[key])) {
      if (Array.isArray(form[key])) {
        newForm[key] = resetArray(form[key]);
      } else if ('value' in form[key]) {
        newForm[key] = {
          ...form[key],
          value: 'defaultValue' in form[key] ? form[key].defaultValue : '',
          isPristine: true,
        };
      } else {
        newForm[key] = resetObject(form[key]);
      }
    }

    return newForm;
  }, {});
}

export default function resetForm(form) {
  return resetObject(form);
}
