import { INDICATOR } from '../constants';

export default {
  default: {
    color: '#CFD8DC',
    icon: 'icon-duck',
    precision: 0,
  },
  [INDICATOR.co2]: {
    color: '#00ab97',
    icon: 'icon-leaf',
    precision: 2,
    unitPath: 'challenge.kgCO2e',
  },
  [INDICATOR.points]: {
    color: '#fbc02d',
    icon: 'icon-stars',
    precision: 0,
    unitPath: 'challenge.points',
  },
  [INDICATOR.health]: {
    color: '#d9464d',
    icon: 'icon-favorite',
    precision: 0,
  },
  [INDICATOR.biodiversity]: {
    color: '#33691E',
    icon: 'icon-nature_people',
    precision: 0,
  },
  [INDICATOR.money]: {
    color: '#ECA156',
    icon: 'icon-attach_money',
    precision: 0,
  },
  [INDICATOR.time]: {
    color: '#AA75BA',
    icon: 'icon-watch_later',
    precision: 0,
  },
  [INDICATOR.landuse]: {
    color: '#8BC34A',
    icon: 'icon-terrain',
    precision: 0,
  },
  [INDICATOR.handprint]: {
    color: '#35B8C4',
    icon: 'icon-pan_tool',
    precision: 0,
  },
  [INDICATOR.social]: {
    color: '#7A4E94',
    icon: 'icon-people',
    precision: 0,
  },
};
