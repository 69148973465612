import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import quiz from 'toolData/quiz';

function getPercentage(count, total) {
  if (!count || !total) return 0;

  return Math.round((count / total) * 100);
}

export default Compute(get => {
  const tag = get(state.challenge.tagModal.key);
  const stats = get(state.challenge.tagModal.quizStats);
  const questionCount = quiz[tag]?.length;
  const yourScore = get(state.challenge.tagModal.computedUserQuizScore).value;

  const submissionCount = Object.values(stats || {}).reduce((a, v) => a + v, 0);

  return Array.from({ length: questionCount + 1 })
    .map((_, i) => ({
      label: `${i}/${questionCount}`,
      value: getPercentage(stats[i], submissionCount),
      isEmphasised: yourScore === i,
      key: i,
    }))
    .sort((a, b) => a.key - b.key);
});
