import { listenTo, createRef } from './helpers';

export default function onChildRemovedFactory(database) {
  return function onChildRemovedFunction(path, signal, options = {}) {
    listenTo(
      createRef(database, path, options),
      path,
      'child_removed',
      signal,
      data => {
        this.context.app.getSequence(signal)({
          key: data.key,
          ...options.payload,
        });
      }
    );
  };
}
