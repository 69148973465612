import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import computedEvents from 'challenge/computed/events';

import { OwnState } from './types';

const state: OwnState = {
  events: {},
  hasReceivedDailySummaryUpdateEvent: false,
  computedEvents,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    getEvents: sequences.getEvents,
    updated: sequences.updateEvents,
    staticEventsUpdated: sequences.updateStaticEvents,
    participantCountsUpdated: sequences.updateParticipantCounts,
    notifyLatestEvents: sequences.notifyLatestEvents,
  },
};

export default module;
