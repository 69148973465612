import { TimelineCampaigns } from 'common/types';
import { partition } from 'utils/common';
import * as getData from './getData';

export const databaseProvider = ({ baseUrl, fetch, ccmUrl, language }) => {
  function fetchData(path) {
    return fetch(`${baseUrl}/${path}`).then(response => response.json());
  }

  return {
    async getCampaign(campaignId: string) {
      const oldCampaign = campaignId.charAt(0) === '-';

      if (!oldCampaign) {
        const response = await fetch(
          `${ccmUrl}/getCampaign?campaignId=${campaignId}&language=${language}`
        );

        const ccmCampaign = await response.json();

        if (response.status === 200) {
          return ccmCampaign;
        }

        return { error: ccmCampaign.error || 'Error fetching campaign' };
      }

      return getData.getCampaign(fetchData, campaignId).then(campaign => {
        if (!campaign) return null;

        const promises = [
          {
            key: 'organisation',
            promise: getData.getOrganisation(
              fetchData,
              campaign.organisationKey
            ),
          },
        ];

        if (campaign.tools.challengeKey) {
          promises.push({
            key: 'challenge',
            promise: getData.getChallenge(
              fetchData,
              campaign.tools.challengeKey
            ),
          });
        }

        return Promise.all(promises.map(p => p.promise)).then(values => {
          values.forEach((value, index) => {
            campaign[promises[index].key] = values[index];
          });

          return campaign;
        });
      });
    },
    async getCampaignByChallengeId(challengeId) {
      const oldChallenge = challengeId.charAt(0) === '-';

      if (!oldChallenge) {
        const response = await fetch(
          `${ccmUrl}/getCampaign?campaignId=${challengeId}&language=${language}`
        );
        const ccmCampaign = await response.json();

        if (response.status === 200) {
          return ccmCampaign;
        }
      }

      return getData.getChallenge(fetchData, challengeId).then(challenge => {
        if (!challenge) return null;

        return getData
          .getCampaign(fetchData, challenge.campaignKey)
          .then(campaign => {
            if (!campaign) return null;

            campaign.challenge = challenge;

            const promises = [
              {
                key: 'organisation',
                promise: getData.getOrganisation(
                  fetchData,
                  campaign.organisationKey
                ),
              },
            ];

            return Promise.all(promises.map(p => p.promise)).then(values => {
              values.forEach((value, index) => {
                campaign[promises[index].key] = values[index];
              });

              return campaign;
            });
          });
      });
    },
    async getCampaigns(campaignIds: string[]): Promise<TimelineCampaigns> {
      let ccmCampaigns: TimelineCampaigns = {};
      const [oldCampaignIds, newCampaignIds]: string[][] = partition(
        campaignIds,
        campaignId => campaignId.charAt(0) === '-'
      );

      if (newCampaignIds.length > 0) {
        const ccmCampaignsResponses = await fetch(`${ccmUrl}/getCampaigns`, {
          method: 'POST',
          body: JSON.stringify({ campaignIds: newCampaignIds }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await ccmCampaignsResponses.json();
        if (ccmCampaignsResponses.status === 200) {
          ccmCampaigns = response;
        }
      }
      const oldCampaignPromises: Promise<TimelineCampaigns>[] =
        oldCampaignIds.map(
          async campaignId =>
            await this.getCampaign(campaignId).then(campaign => {
              if (!campaign) {
                return {};
              }

              return {
                [campaign.id]: {
                  id: campaign.id,
                  name: campaign.name,
                  topic: campaign.topic,
                  organisation: {
                    name: campaign.organisation.name,
                  },
                  challenge: {
                    id: campaign.challenge.id,
                    campaignKey: campaign.id,
                    startDatetime: campaign.startDatetime,
                    endDatetime: campaign.endDatetime,
                  },
                  startDatetime: campaign.startDatetime,
                  endDatetime: campaign.endDatetime,
                  tools: { challengeKey: campaign.challenge.id },
                },
              };
            })
        );

      const oldCampaignsArray = await Promise.all(oldCampaignPromises);

      return oldCampaignsArray.reduce(
        (all, current) => ({
          ...all,
          ...current,
        }),
        ccmCampaigns
      );
    },
    isAllowedEmailDomainForCampaign(domain, campaignId) {
      return getData
        .verifyDomainForCampaign(fetchData, domain, campaignId)
        .then(data => Boolean(data));
    },
    getChampionship(championshipId) {
      return Promise.all([
        getData.getChampionshipConfig(fetchData, championshipId),
        getData.getChampionshipSeries(fetchData, championshipId),
        getData.getChampionshipCampaigns(fetchData, championshipId),
      ]).then(([config, series, campaignKeys]) => {
        if (!config) return null;

        return Promise.all(
          Object.keys(campaignKeys || {}).map(campaignId =>
            this.getCampaign(campaignId).then(campaign => {
              const seriesKey = Object.keys(series || {}).find(
                seriesId => series[seriesId].campaigns[campaignId]
              );

              return {
                ...campaign,
                series: seriesKey
                  ? { id: seriesKey, name: series[seriesKey].name }
                  : null,
              };
            })
          )
        ).then(campaigns => ({
          ...config,
          series: Object.keys(series || {}).map(seriesId => ({
            id: seriesId,
            name: series[seriesId].name,
          })),
          campaigns,
        }));
      });
    },
    async getChampionshipCampaigns(championshipId) {
      const oldChampionship = championshipId.charAt(0) === '-';

      if (!oldChampionship) {
        const response = await fetch(
          `${ccmUrl}/getChampionship?championshipId=${championshipId}&language=${language}`
        );
        const ccmChampionship = await response.json();

        if (response.status === 200) {
          return {
            campaignKeys: ccmChampionship.campaigns.map(
              campaign => campaign.id
            ),
          };
        }
      }
      const campaignKeyObjects = await getData.getChampionshipCampaigns(
        fetchData,
        championshipId
      );

      return { campaignKeys: Object.keys(campaignKeyObjects || {}) };
    },
  };
};
