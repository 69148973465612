import { Provider } from 'cerebral';
import qs from 'query-string';

function UrlActionProvider() {
  return new Provider({
    get() {
      const query = qs.parse(location.search);

      return query.action || null;
    },
    set(value) {
      const query = qs.parse(location.search);

      if (query.action === value) return null;

      query.action = value;

      const newQuery = qs.stringify(query);

      return `${location.pathname}?${newQuery}`;
    },
    clear() {
      const query = qs.parse(location.search);

      if (!query.action) return null;

      delete query.action;

      // If there are no queries, return just the path to prevent having a "?" at the end of the url
      if (Object.keys(query).length === 0) return location.pathname;

      const newQuery = qs.stringify(query);

      return `${location.pathname}?${newQuery}`;
    },
  });
}

export default UrlActionProvider;
