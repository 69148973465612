import { listenTo, createRef } from './helpers';

export default function onChildAddedFactory(database) {
  return function onChildAddedFunction(path, signal, options = {}) {
    listenTo(
      createRef(database, path, options),
      path,
      'child_added',
      signal,
      data => {
        this.context.app.getSequence(signal)({
          key: data.key,
          value: data.val(),
          ...(options.payload || {}),
        });
      }
    );
  };
}
