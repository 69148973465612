import { sequence, state as stateTag } from 'cerebral';
import page from 'page';
import { wait, set, debounce } from 'cerebral/factories';
import { toCo2Kg, formatNumber } from 'utils/common';
import { CATEGORY } from 'resources/constants';

const notifyDebounce = debounce.shared();

export function getTranslationByValue(valueTag, dataSource, inputCategory) {
  return function getTranslationByValueAction({ resolve, translate, state }) {
    const value = toCo2Kg(resolve.value(valueTag));
    const categories = [CATEGORY.transport, CATEGORY.energy];

    let locale = 'nb-NO';

    if (state) {
      locale = state.get('appContainer.language.fullLocale');
    }

    let randomCategory =
      categories[Math.floor(Math.random() * categories.length)];

    if (value > 4500) {
      // CMM: NEED TO ADD MORE VALUES TO TRANSPORT CATEGORY
      randomCategory = CATEGORY.energy;
    }

    return {
      result: formatNumber(
        translate.combined({
          value: String(value),
          category: inputCategory || randomCategory,
          dataSource: resolve.value(dataSource),
        }),
        { locale }
      ),
    };
  };
}

export function notify(type, data, options = {}) {
  function notifyAction({ state, resolve }) {
    const resolvedData = Object.keys(data || {}).reduce((allData, key) => {
      allData[key] = resolve.value(data[key]);

      return allData;
    }, {});

    state.set('appContainer.snackbar.message', {
      data: resolvedData,
      type: resolve.value(type),
      date: Date.now(),
    });
  }

  return sequence('notify', [
    notifyDebounce(options.debounce ? 7500 : 0),
    {
      continue: [
        notifyAction,
        set(stateTag`appContainer.snackbar.show`, true),
        wait(6500), // Wait needs to be at least 1 sec less than the debounce number
        set(stateTag`appContainer.snackbar.show`, false),
        wait(1000), // Wait for animation to finish
        set(stateTag`appContainer.snackbar.message`, {
          data: {},
          type: null,
          date: null,
        }),
      ],
      discard: [],
    },
  ]);
}

export function redirect(path) {
  return function redirectAction({ resolve, state }) {
    const language = state.get('appContainer.language.locale');
    const primaryLanguage = state.get('appContainer.primaryLanguage');

    if (language === primaryLanguage) {
      return page(resolve.value(path));
    }

    if (resolve.value(path).substring(0, 1) === '/') {
      return page(`/${language}${resolve.value(path)}`);
    }

    return page(`/${language}/${resolve.value(path)}`);
  };
}

export function onVisible(signal) {
  return function onVisibleAction({ app }) {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        app.getSequence(signal)();
      }
    });
  };
}

export function onInvisible(signal) {
  return function onVisibleAction({ app }) {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        app.getSequence(signal)();
      }
    });
  };
}
