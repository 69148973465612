/* eslint-disable max-lines*/

export const enum ICON {
  acUnit = 'icon-ac_unit',
  accessibility = 'icon-accessibility',
  accessible = 'icon-accessible',
  accountBalance = 'icon-account_balance',
  accountBox = 'icon-account_box',
  accountCircle = 'icon-account_circle',
  accountAlert = 'icon-account-alert',
  accountCheck = 'icon-account-check',
  accountFollower = 'icon-account-follower',
  accountFollowing = 'icon-account-following',
  accountMinus = 'icon-account-minus',
  accountOff = 'icon-account-off',
  accountRemove = 'icon-account-remove',
  accountSearch = 'icon-account-search',
  accountStar = 'icon-account-star',
  accountSwitch = 'icon-account-switch',
  add = 'icon-add',
  addAPhoto = 'icon-add_a_photo',
  addAlert = 'icon-add_alert',
  addBox = 'icon-add_box',
  addCircle = 'icon-add_circle',
  airplanemodeInactive = 'icon-airplanemode_inactive',
  airportShuttle = 'icon-airport_shuttle',
  alarm = 'icon-alarm',
  alarmAdd = 'icon-alarm_add',
  alarmOff = 'icon-alarm_off',
  alarmOn = 'icon-alarm_on',
  allInclusive = 'icon-all_inclusive',
  announcement = 'icon-announcement',
  apps = 'icon-apps',
  arrowBack = 'icon-arrow_back',
  arrowDownward = 'icon-arrow_downward',
  arrowDropDown = 'icon-arrow_drop_down',
  arrowDropDownCircle = 'icon-arrow_drop_down_circle',
  arrowDropUp = 'icon-arrow_drop_up',
  arrowForward = 'icon-arrow_forward',
  arrowUpward = 'icon-arrow_upward',
  assignment = 'icon-assignment',
  assignmentLate = 'icon-assignment_late',
  assignmentTurnedIn = 'icon-assignment_turned_in',
  attachFile = 'icon-attach_file',
  attachMoney = 'icon-attach_money',
  audiotrack = 'icon-audiotrack',
  barley = 'icon-barley',
  batteryAlert = 'icon-battery_alert',
  batteryChargingFull = 'icon-battery_charging_full',
  batteryStd = 'icon-battery_std',
  batteryUnknown = 'icon-battery_unknown',
  beachAccess = 'icon-beach_access',
  beenhere = 'icon-beenhere',
  bluetooth = 'icon-bluetooth',
  bluetoothDisabled = 'icon-bluetooth_disabled',
  borderColor = 'icon-border_color',
  brightness3 = 'icon-brightness_3',
  brightnessHigh = 'icon-brightness_high',
  brush = 'icon-brush',
  bubbleChart = 'icon-bubble_chart',
  build = 'icon-build',
  cached = 'icon-cached',
  cake = 'icon-cake',
  callMade = 'icon-call_made',
  callReceived = 'icon-call_received',
  cancel = 'icon-cancel',
  carrot = 'icon-carrot',
  chat = 'icon-chat',
  chatBubble = 'icon-chat_bubble',
  chatBubbleOutline = 'icon-chat_bubble_outline',
  check = 'icon-check',
  checkBox = 'icon-check_box',
  checkBoxOutlineBlank = 'icon-check_box_outline_blank',
  checkCircle = 'icon-check_circle',
  cheese = 'icon-cheese',
  childCare = 'icon-child_care',
  childFriendly = 'icon-child_friendly',
  close = 'icon-close',
  cloudDone = 'icon-cloud_done',
  cloudOff = 'icon-cloud_off',
  cloudQueue = 'icon-cloud_queue',
  code = 'icon-code',
  collectionsBookmark = 'icon-collections_bookmark',
  colorize = 'icon-colorize',
  controlPoint = 'icon-control_point',
  cow = 'icon-cow',
  cropFree = 'icon-crop_free',
  cropOriginal = 'icon-crop_original',
  crown = 'icon-crown',
  cupWater = 'icon-cup-water',
  dashboard = 'icon-dashboard',
  dataUsage = 'icon-data_usage',
  dateRange = 'icon-date_range',
  delete = 'icon-delete',
  desktopMac = 'icon-desktop_mac',
  directionsBike = 'icon-directions_bike',
  directionsBoat = 'icon-directions_boat',
  directionsBus = 'icon-directions_bus',
  directionsCar = 'icon-directions_car',
  directionsRailway = 'icon-directions_railway',
  directionsRun = 'icon-directions_run',
  directionsTransit = 'icon-directions_transit',
  directionsWalk = 'icon-directions_walk',
  doNotDisturbAlt = 'icon-do_not_disturb_alt',
  doNotDisturbOff = 'icon-do_not_disturb_off',
  doneAll = 'icon-done_all',
  donutLarge = 'icon-donut_large',
  dotsVertical = 'icon-dots-vertical',
  drafts = 'icon-drafts',
  duck = 'icon-duck',
  duckylogo = 'icon-duckylogo',
  duckylogoBeta = 'icon-duckylogo_beta',
  dvr = 'icon-dvr',
  editLocation = 'icon-edit_location',
  equalizer = 'icon-equalizer',
  error = 'icon-error',
  errorOutline = 'icon-error_outline',
  evStation = 'icon-ev_station',
  eventAvailable = 'icon-event_available',
  eventBusy = 'icon-event_busy',
  eventNote = 'icon-event_note',
  eventSeat = 'icon-event_seat',
  exitToApp = 'icon-exit_to_app',
  explore = 'icon-explore',
  extension = 'icon-extension',
  face = 'icon-face',
  facebook = 'icon-facebook',
  fastForward = 'icon-fast_forward',
  fastRewind = 'icon-fast_rewind',
  favorite = 'icon-favorite',
  favoriteBorder = 'icon-favorite_border',
  fileUpload = 'icon-file_upload',
  filterDrama = 'icon-filter_drama',
  filterFrames = 'icon-filter_frames',
  filterNone = 'icon-filter_none',
  findInPage = 'icon-find_in_page',
  findReplace = 'icon-find_replace',
  fingerprint = 'icon-fingerprint',
  fire = 'icon-fire',
  fish = 'icon-fish',
  fitnessCenter = 'icon-fitness_center',
  flag = 'icon-flag',
  flagCheckered = 'icon-flag-checkered',
  flame = 'icon-flame',
  flare = 'icon-flare',
  flashOff = 'icon-flash_off',
  flashOn = 'icon-flash_on',
  flight = 'icon-flight',
  flightLand = 'icon-flight_land',
  flightTakeoff = 'icon-flight_takeoff',
  folder = 'icon-folder',
  folderOpen = 'icon-folder_open',
  folderShared = 'icon-folder_shared',
  folderSpecial = 'icon-folder_special',
  food = 'icon-food',
  foodApple = 'icon-food-apple',
  foodForkDrink = 'icon-food-fork-drink',
  foodOff = 'icon-food-off',
  foodVariant = 'icon-food-variant',
  footprint = 'icon-footprint',
  formatListBulleted = 'icon-format_list_bulleted',
  formatListNumbered = 'icon-format_list_numbered',
  formatQuote = 'icon-format_quote',
  freeBreakfast = 'icon-free_breakfast',
  functions = 'icon-functions',
  gavel = 'icon-gavel',
  gesture = 'icon-gesture',
  getApp = 'icon-get_app',
  goat = 'icon-goat',
  golfCourse = 'icon-golf_course',
  googlePlus = 'icon-google-plus',
  gridOff = 'icon-grid_off',
  gridOn = 'icon-grid_on',
  groupAdd = 'icon-group_add',
  hashtag = 'icon-hashtag',
  headset = 'icon-headset',
  headsetMic = 'icon-headset_mic',
  healing = 'icon-healing',
  hearing = 'icon-hearing',
  help = 'icon-help',
  helpOutline = 'icon-help_outline',
  highlight = 'icon-highlight',
  highlightOff = 'icon-highlight_off',
  highway = 'icon-highway',
  home = 'icon-home',
  hotTub = 'icon-hot_tub',
  hourglassEmpty = 'icon-hourglass_empty',
  hourglassFull = 'icon-hourglass_full',
  household = 'icon-household',
  importContacts = 'icon-import_contacts',
  inbox = 'icon-inbox',
  indeterminateCheckBox = 'icon-indeterminate_check_box',
  info = 'icon-info',
  infoOutline = 'icon-info_outline',
  insertDriveFile = 'icon-insert_drive_file',
  instagram = 'icon-instagram',
  keyVariant = 'icon-key-variant',
  keyboard = 'icon-keyboard',
  keyboardArrowDown = 'icon-keyboard_arrow_down',
  keyboardArrowLeft = 'icon-keyboard_arrow_left',
  keyboardArrowRight = 'icon-keyboard_arrow_right',
  keyboardArrowUp = 'icon-keyboard_arrow_up',
  keyboardVoice = 'icon-keyboard_voice',
  kitchen = 'icon-kitchen',
  language = 'icon-language',
  laptop = 'icon-laptop',
  laptopChromebook = 'icon-laptop_chromebook',
  laptopMac = 'icon-laptop_mac',
  layers = 'icon-layers',
  layersClear = 'icon-layers_clear',
  leaderboard = 'icon-leaderboard',
  leaf = 'icon-leaf',
  lens = 'icon-lens',
  libraryBooks = 'icon-library_books',
  lightbulbOutline = 'icon-lightbulb_outline',
  link = 'icon-link',
  linkChecked = 'icon-link_checked',
  list = 'icon-list',
  liveHelp = 'icon-live_help',
  liveTv = 'icon-live_tv',
  localBar = 'icon-local_bar',
  localDrink = 'icon-local_drink',
  localFlorist = 'icon-local_florist',
  localGasStation = 'icon-local_gas_station',
  localHospital = 'icon-local_hospital',
  localHotel = 'icon-local_hotel',
  localLaundryService = 'icon-local_laundry_service',
  localLibrary = 'icon-local_library',
  localOffer = 'icon-local_offer',
  localParking = 'icon-local_parking',
  localPlay = 'icon-local_play',
  localShipping = 'icon-local_shipping',
  localTaxi = 'icon-local_taxi',
  locationCity = 'icon-location_city',
  locationOff = 'icon-location_off',
  lock = 'icon-lock',
  loyalty = 'icon-loyalty',
  mailOutline = 'icon-mail_outline',
  map = 'icon-map',
  markunread = 'icon-markunread',
  markunreadMailbox = 'icon-markunread_mailbox',
  menu = 'icon-menu',
  message = 'icon-message',
  micNone = 'icon-mic_none',
  micOff = 'icon-mic_off',
  microplastics = 'icon-microplastics',
  mms = 'icon-mms',
  modeEdit = 'icon-mode_edit',
  monetizationOn = 'icon-monetization_on',
  moneyOff = 'icon-money_off',
  moodBad = 'icon-mood_bad',
  motorbike = 'icon-motorbike',
  motorcycle = 'icon-motorcycle',
  nature = 'icon-nature',
  naturePeople = 'icon-nature_people',
  nearMe = 'icon-near_me',
  networkCheck = 'icon-network_check',
  newReleases = 'icon-new_releases',
  nextWeek = 'icon-next_week',
  noEncryption = 'icon-no_encryption',
  noteAdd = 'icon-note_add',
  notifications = 'icon-notifications',
  notificationsActive = 'icon-notifications_active',
  notificationsActive2 = 'icon-notifications_active2',
  notificationsNone = 'icon-notifications_none',
  notificationsOff = 'icon-notifications_off',
  notificationsOff2 = 'icon-notifications_off2',
  notifications2 = 'icon-notifications2',
  ondemandVideo = 'icon-ondemand_video',
  openInNew = 'icon-open_in_new',
  overuse = 'icon-overuse',
  pageview = 'icon-pageview',
  panTool = 'icon-pan_tool',
  pause = 'icon-pause',
  pauseCircleFilled = 'icon-pause_circle_filled',
  pauseCircleOutline = 'icon-pause_circle_outline',
  people = 'icon-people',
  peopleOutline = 'icon-people_outline',
  permContactCalendar = 'icon-perm_contact_calendar',
  person = 'icon-person',
  personAdd = 'icon-person_add',
  personOutline = 'icon-person_outline',
  personPin = 'icon-person_pin',
  personPinCircle = 'icon-person_pin_circle',
  personalVideo = 'icon-personal_video',
  pets = 'icon-pets',
  phone = 'icon-phone',
  phoneAndroid = 'icon-phone_android',
  phoneIphone = 'icon-phone_iphone',
  photo = 'icon-photo',
  photoCamera = 'icon-photo_camera',
  photoCameraChecked = 'icon-photo_camera_checked',
  photoLibrary = 'icon-photo_library',
  pieChart = 'icon-pie_chart',
  pig = 'icon-pig',
  pinDrop = 'icon-pin_drop',
  playArrow = 'icon-play_arrow',
  playlistAdd = 'icon-playlist_add',
  playlistAddCheck = 'icon-playlist_add_check',
  poll = 'icon-poll',
  pool = 'icon-pool',
  portrait = 'icon-portrait',
  power = 'icon-power',
  powerSettingsNew = 'icon-power_settings_new',
  pregnantWoman = 'icon-pregnant_woman',
  print = 'icon-print',
  priorityHigh = 'icon-priority_high',
  public = 'icon-public',
  publish = 'icon-publish',
  questionAnswer = 'icon-question_answer',
  radio = 'icon-radio',
  radioButtonChecked = 'icon-radio_button_checked',
  radioButtonUnchecked = 'icon-radio_button_unchecked',
  rateReview = 'icon-rate_review',
  recentActors = 'icon-recent_actors',
  refresh = 'icon-refresh',
  remove = 'icon-remove',
  removeCircle = 'icon-remove_circle',
  removeCircleOutline = 'icon-remove_circle_outline',
  removeShoppingCart = 'icon-remove_shopping_cart',
  replay = 'icon-replay',
  reply = 'icon-reply',
  replyAll = 'icon-reply_all',
  restaurant = 'icon-restaurant',
  restaurantMenu = 'icon-restaurant_menu',
  restore = 'icon-restore',
  restorePage = 'icon-restore_page',
  ringVolume = 'icon-ring_volume',
  room = 'icon-room',
  roomService = 'icon-room_service',
  rowing = 'icon-rowing',
  rssFeed = 'icon-rss_feed',
  scanner = 'icon-scanner',
  schedule = 'icon-schedule',
  school = 'icon-school',
  screenRotation = 'icon-screen_rotation',
  search = 'icon-search',
  security = 'icon-security',
  send = 'icon-send',
  sentimentDissatisfied = 'icon-sentiment_dissatisfied',
  sentimentNeutral = 'icon-sentiment_neutral',
  sentimentSatisfied = 'icon-sentiment_satisfied',
  sentimentVeryDissatisfied = 'icon-sentiment_very_dissatisfied',
  sentimentVerySatisfied = 'icon-sentiment_very_satisfied',
  settings = 'icon-settings',
  settingsApplications = 'icon-settings_applications',
  settingsBackupRestore = 'icon-settings_backup_restore',
  share = 'icon-share',
  shop = 'icon-shop',
  shopTwo = 'icon-shop_two',
  shoppingBasket = 'icon-shopping_basket',
  shoppingCart = 'icon-shopping_cart',
  signalCellularNoSim = 'icon-signal_cellular_no_sim',
  skipNext = 'icon-skip_next',
  skipPrevious = 'icon-skip_previous',
  slowMotionVideo = 'icon-slow_motion_video',
  smokeFree = 'icon-smoke_free',
  smokingRooms = 'icon-smoking_rooms',
  smsFailed = 'icon-sms_failed',
  sort = 'icon-sort',
  spa = 'icon-spa',
  speaker = 'icon-speaker',
  speakerGroup = 'icon-speaker_group',
  spellcheck = 'icon-spellcheck',
  star = 'icon-star',
  starBorder = 'icon-star_border',
  starHalf = 'icon-star_half',
  starOff = 'icon-star-off',
  stars = 'icon-stars',
  stayPrimaryLandscape = 'icon-stay_primary_landscape',
  stayPrimaryPortrait = 'icon-stay_primary_portrait',
  style = 'icon-style',
  supervisorAccount = 'icon-supervisor_account',
  swapCalls = 'icon-swap_calls',
  swapHoriz = 'icon-swap_horiz',
  swapVert = 'icon-swap_vert',
  sync = 'icon-sync',
  tabletAndroid = 'icon-tablet_android',
  tabletMac = 'icon-tablet_mac',
  tagFaces = 'icon-tag_faces',
  team = 'icon-team',
  terrain = 'icon-terrain',
  terrain2 = 'icon-terrain2',
  textFields = 'icon-text_fields',
  textsms = 'icon-textsms',
  theaters = 'icon-theaters',
  thumbDown = 'icon-thumb_down',
  thumbUp = 'icon-thumb_up',
  thumbsUpDown = 'icon-thumbs_up_down',
  timelapse = 'icon-timelapse',
  timeline = 'icon-timeline',
  timer = 'icon-timer',
  timerOff = 'icon-timer_off',
  toc = 'icon-toc',
  today = 'icon-today',
  touchApp = 'icon-touch_app',
  trackChanges = 'icon-track_changes',
  traffic = 'icon-traffic',
  train = 'icon-train',
  tram = 'icon-tram',
  transferWithinAStation = 'icon-transfer_within_a_station',
  trendingDown = 'icon-trending_down',
  trendingUp = 'icon-trending_up',
  trophy = 'icon-trophy',
  trophyAward = 'icon-trophy-award',
  trophyOutline = 'icon-trophy-outline',
  tune = 'icon-tune',
  turnedIn = 'icon-turned_in',
  turnedInNot = 'icon-turned_in_not',
  twitter = 'icon-twitter',
  update = 'icon-update',
  verifiedUser = 'icon-verified_user',
  videocam = 'icon-videocam',
  videocamOff = 'icon-videocam_off',
  viewAgenda = 'icon-view_agenda',
  viewArray = 'icon-view_array',
  viewCarousel = 'icon-view_carousel',
  viewColumn = 'icon-view_column',
  viewComfy = 'icon-view_comfy',
  viewCompact = 'icon-view_compact',
  viewDay = 'icon-view_day',
  viewHeadline = 'icon-view_headline',
  viewList = 'icon-view_list',
  viewModule = 'icon-view_module',
  viewQuilt = 'icon-view_quilt',
  viewStream = 'icon-view_stream',
  viewWeek = 'icon-view_week',
  visibility = 'icon-visibility',
  visibilityOff = 'icon-visibility_off',
  voiceChat = 'icon-voice_chat',
  volumeDown = 'icon-volume_down',
  volumeMute = 'icon-volume_mute',
  volumeOff = 'icon-volume_off',
  volumeUp = 'icon-volume_up',
  vpnKey = 'icon-vpn_key',
  vpnLock = 'icon-vpn_lock',
  watch = 'icon-watch',
  watchLater = 'icon-watch_later',
  water = 'icon-water',
  waterOff = 'icon-water-off',
  waterPump = 'icon-water-pump',
  wbCloudy = 'icon-wb_cloudy',
  wbIncandescent = 'icon-wb_incandescent',
  wbSunny = 'icon-wb_sunny',
  wc = 'icon-wc',
  web = 'icon-web',
  webAsset = 'icon-web_asset',
  weekend = 'icon-weekend',
  wifi = 'icon-wifi',
  work = 'icon-work',
  wunderlist = 'icon-wunderlist',
  zoomIn = 'icon-zoom_in',
  zoomOut = 'icon-zoom_out',
  zoomOutMap = 'icon-zoom_out_map',
  zoomOutMap2 = 'icon-zoom_out_map2',
  facebook2 = 'icon-facebook2',
  appleinc = 'icon-appleinc',
  windows8 = 'icon-windows8',
}
