import { formatProfile } from 'utils/common';

export function getUser({ firebase }) {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.onAuthStateChanged(
      user => {
        unsubscribe();

        if (!user) return resolve(null);

        return resolve({
          uid: user.uid,
          isAnonymous: user.isAnonymous,
          providerData: user.providerData,
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.providerData.some(d => d.providerId === 'facebook.com') // We don't want facebook images, as they come with tracking
            ? null
            : user.photoURL,
        });
      },
      error => reject(error)
    );
  })
    .then(user => ({ user }))
    .catch(error => ({ error }));
}

export function listenForProfileChanges({ firebase, state }) {
  const uid = state.get('appContainer.user.uid');

  firebase.onValue(`profiles.list.${uid}`, 'appContainer.auth.profileChanged');
}

export function createProfile({ firebase, props, state, storage }) {
  return firebase.task('create_profile', {
    name: props.user.displayName || 'Anonymous',
    language: state.get('appContainer.language.locale'),
    email: props.user.email,
    avatarImage: props.user.photoURL || null,
    termsAccepted: props.termsAccepted || false,
    continueUrl: storage.get('redirectUrl') || '/',
  });
}

export function setProfile({ props, state }) {
  state.set(`appContainer.profiles.${props.user.uid}`, props.profile);
}

export function setAuthenticationError({ state, props }) {
  const { error } = props;

  const errorMessages = {
    'auth/user-not-found': 'authentication.errors.invalid_login',
    'auth/wrong-password': 'authentication.errors.invalid_login',
    'auth/uid-already-exists': 'authentication.errors.uid_already_exists',
    'auth/invalid-action-code': 'authentication.errors.invalid_action_code',
    'auth/expired-action-code': 'authentication.errors.invalid_action_code',
    'auth/account-exists-with-different-credential':
      'authentication.errors.account_exists_with_different_credential',
    'auth/too-many-requests': 'authentication.errors.too_many_requests',
    'auth/popup-blocked': 'authentication.errors.popup_blocked',
    'auth/popup-closed-by-user': 'authentication.errors.popup_closed_by_user',
    'auth/email-already-in-use': 'authentication.errors.email_already_in_use',
    'auth/email-already-exists': 'authentication.errors.email_already_in_use',
    'auth/user-disabled': 'authentication.errors.user_disabled',
    facebook_creation_failed: 'authentication.errors.facebook_creation_failed',
    'resource-exhausted': 'authentication.errors.rate_limit',
  };

  if (error && errorMessages[error.code]) {
    state.set('appContainer.auth.authenticationError', {
      message: errorMessages[error.code],
      code: error.code,
    });
  } else if (error && !error.code && error.message) {
    state.set('appContainer.auth.authenticationError', {
      message: error.message,
    });
  } else if (error && typeof error !== 'object') {
    state.set('appContainer.auth.authenticationError', {
      message: 'authentication.errors.no_code',
      code: `${error.message} ${error.code}`,
    });
  }

  return null;
}

function getContinueUrlFromUrl() {
  const continueUrl = new URL(window.location.href).searchParams.get(
    'continueUrl'
  );

  if (!continueUrl) return null;

  const { pathname, search } = new URL(continueUrl);

  return pathname + search;
}

export function getContinueUrl({ storage, props }) {
  const timelineURL = `/`;
  const path = getContinueUrlFromUrl();
  const redirectUrl = storage.get('redirectUrl');

  return {
    redirectUrl: props.redirectUrl || path || redirectUrl || timelineURL,
  };
}

function createUserObjectFromAuth(res, name) {
  return {
    uid: res.user.uid,
    displayName: res.user.displayName || name || 'No_name',
    providerData: res.user.providerData,
    email: res.user.email,
    photoURL: res.user.photoURL,
  };
}

export function createUserWithEmailAndPassword({ path, forms, firebase }) {
  const { email, name, password } = forms.toJSON(
    'appContainer.auth.registerForm'
  );

  return firebase
    .createUserWithEmailAndPassword(email, password)
    .then(user =>
      path.success({
        user: createUserObjectFromAuth({ user }, name),
      })
    )
    .catch(error => path.error({ error }));
}

export function logInWithEmailAndPassword({ firebase, path, forms }) {
  const { email, password } = forms.toJSON('appContainer.auth.logInForm');

  return firebase
    .signInWithEmailAndPassword(email, password)
    .then(user =>
      path.success({
        user: createUserObjectFromAuth({ user }),
      })
    )
    .catch(error => path.error({ error }));
}

export function loginWithGoogle({ firebase, path }) {
  return firebase
    .signInWithGoogle({})
    .then(res =>
      path.success({
        user: createUserObjectFromAuth(res),
      })
    )
    .catch(error => path.error({ error }));
}

export function loginWithFacebook({ firebase, path }) {
  return firebase
    .signInWithFacebook({})
    .then(res =>
      path.success({
        user: createUserObjectFromAuth(res),
      })
    )
    .catch(error => path.error({ error }));
}

export function redirectToRegister({ props, state, router, storage }) {
  const path = state.get('app.path') || '/';

  if (
    !props.noRedirect &&
    !path.startsWith('/confirmEmail') &&
    !path.startsWith('/verify')
  )
    storage.set('redirectUrl', path);

  router.goTo(props.redirectPath ? `/${props.redirectPath}` : '/register');
}

export function redirectToLogin({ router }) {
  router.goTo('/login');
}

export function redirectToUrl({ props, router }) {
  const { redirectUrl } = props;

  router.goTo(redirectUrl);
}

export function navigateToRegister({ router }) {
  router.goTo('/register');
}

export function redirectToConfirmEmail({ props, state, storage, router }) {
  const path = state.get('app.path') || '/';

  if (
    !props.noRedirect &&
    !path.startsWith('/confirmEmail') &&
    !path.startsWith('/verify')
  ) {
    storage.set('redirectUrl', path);

    router.goTo('/confirmEmail');
  }
}

export function setUserSentry({ state, sentry }) {
  const uid = state.get('appContainer.user.uid');
  const email = state.get(`appContainer.profiles.${uid}.email`);

  sentry.setUser({ uid, email });
}

export function setUserProfile({ props, state }) {
  state.set(
    `appContainer.profiles.${state.get(`appContainer.user.uid`)}`,
    formatProfile(props.value)
  );
}

export function setProfileFromSetUser({ props, state }) {
  state.set(
    `appContainer.profiles.${state.get(`appContainer.user.uid`)}`,
    formatProfile(props.response.value)
  );
}

export function reload() {
  window.location.reload();
}

export function sendPasswordResetEmail({ firebase, state, path }) {
  const email = state.get('appContainer.auth.forgotPasswordForm.email.value');
  const language = state.get('appContainer.language.locale');
  const type = state.get('app.params.creationNeeded') ? 'create' : 'reset';

  return firebase
    .task('send_email_password_reset', {
      email,
      language,
      type,
    })
    .then(() => path.success())
    .catch(error => path.error({ error }));
}

export function sendConfirmEmail({ state, firebase, path, storage }) {
  const language = state.get('appContainer.language.locale');

  return firebase
    .task('send_email_verify_address', {
      language,
      continueUrl: storage.get('redirectUrl') || '/',
    })
    .then(() => path.success())
    .catch(error => path.error({ error }));
}
