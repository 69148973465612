import { Route } from 'common/types';

const routes: Route[] = [
  {
    path: '/profile',
    name: 'profileInfo',
    signal: 'account.profileInfoRendered',
  },
  {
    path: '/profile/delete',
    name: 'profileDelete',
    signal: 'account.profileDeleteRendered',
  },
  {
    path: '/profile/language',
    name: 'profileLanguage',
    signal: 'account.profileLanguageRendered',
    noAuth: true,
  },
  {
    path: '/profile/preferences',
    name: 'profilePreferences',
    signal: 'account.profilePreferencesRendered',
  },
  {
    path: '/profile/password',
    name: 'profilePassword',
    signal: 'account.profilePasswordRendered',
  },
  {
    path: '/profile/support',
    name: 'profileSupport',
    signal: 'account.profileSupportRendered',
    noAuth: true,
  },
  {
    path: '/profile/terms',
    name: 'profileTerms',
    signal: 'account.profileTermsRendered',
    noAuth: true,
  },
  {
    path: '/menu',
    name: 'menu',
    signal: 'account.menuRendered',
    noAuth: true,
  },
  {
    path: '/register',
    name: 'register',
    signal: 'account.registerRendered',
    noAuth: true,
  },
  {
    path: '/register/password',
    name: 'registerPassword',
    signal: 'account.registerPasswordRendered',
    noAuth: true,
  },
  {
    path: '/',
    name: 'timeline',
    signal: 'account.timeline.routed',
  },
  {
    path: '/joinCampaign',
    name: 'joinCampaign',
    signal: 'account.joinCampaign.routed',
  },
  {
    path: '/login',
    name: 'login',
    signal: 'account.loginRendered',
    noAuth: true,
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    signal: 'account.forgotPasswordRendered',
    noAuth: true,
  },
  {
    path: '/verify',
    name: 'verifyLink',
    signal: 'account.verifyExternalLink',
    noAuth: true,
  },
  {
    path: '/confirmEmail',
    name: 'confirmEmail',
    signal: 'account.confirmEmailRendered',
  },
];

export default routes;
