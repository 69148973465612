import 'match-media';

function matchMedia(mediaQueryString) {
  return window.matchMedia(mediaQueryString);
}

export default function getMedia(options) {
  const queries = options.media;

  const media = Object.keys(queries)
    .map(name => ({ name, queryString: queries[name] }))
    .reduce((result, { name, queryString }) => {
      result[name] = matchMedia(queryString).matches;

      return result;
    }, {});

  return media;
}
