import { when, set, equals } from 'cerebral/factories';
import { sequence, state, props } from 'cerebral';
import { URL_ACTION } from 'resources/constants';
import * as actions from './actions';
import { authenticate } from './modules/auth/factories';

export function notifyError(message) {
  /*eslint-disable */
  return function notifyErrorAction({ state, props }) {
    console.log('Error:');
    console.log(message, props);
    state.set('appContainer.error', message);
  };
  /* eslint-enable */
}

export function hasUrlAction(name) {
  return function hasUrlActionAction({ path, resolve }) {
    const resolvedName = resolve.value(name);

    if (location.search.indexOf(resolvedName) >= 0) {
      return path.true();
    }

    return path.false();
  };
}

export function setUrlAction(name: string): () => void {
  return sequence([
    when(state`app.isMobile`),
    {
      true: [
        actions.shouldSaveScrollTop,
        {
          true: [actions.saveScrollTop],
          false: [],
        },
        actions.resetScroll,
      ],
      false: [],
    },
    function setUrlActionAction({ resolve, urlAction, router }) {
      const resolvedName = resolve.value(name);
      const newUrl = urlAction.set(resolvedName);

      if (newUrl) {
        router.goTo(newUrl);
      }
    },
  ]);
}

export function rendered(continueChain = []) {
  return sequence('appContainer.rendered', [
    set(state`app.path`, props`path`),
    when(props`base`),
    {
      true: [set(state`app.tool`, props`base`)],
      false: [],
    },
    when(props`page`),
    {
      true: [set(state`app.page`, props`page`)],
      false: [],
    },
    when(
      props`action`,
      state`appContainer.isServer`,
      (action, isServer) =>
        action &&
        (action === 'login' ||
          action.startsWith(URL_ACTION.activityModal) ||
          action.startsWith(URL_ACTION.tagModal) ||
          action.startsWith(URL_ACTION.indicatorModal) ||
          action === 'adminActivityModal' ||
          action === 'adminTagModal' ||
          action === 'infoModal') &&
        isServer
    ),
    {
      true: actions.clearUrl,
      false: [
        when(
          state`appContainer.isServer`, // Is it the first render? Authenticate
          state`appContainer.user.uid`, // Is the user not authenticated and it is required? Authenticate
          state`appContainer.profiles.${state`appContainer.user.uid`}`, // Has the user not accepted terms? Auth to show modal
          props`requireAuth`,
          (isServer, uid, profile, requireAuth) =>
            isServer ||
            (!uid && requireAuth) ||
            (profile && !profile.acceptedTerms)
        ),
        {
          true: [
            ...authenticate(continueChain),
            set(state`appContainer.isServer`, false),
          ],
          false: continueChain,
        },
      ],
    },
    when(state`appContainer.hasRendered`),
    {
      true: [],
      false: [actions.listenToAuthState],
    },
    set(state`appContainer.hasRendered`, true),
    when(state`appContainer.shouldResetScroll`),
    {
      true: actions.scrollToScrollTop,
      false: set(state`appContainer.shouldResetScroll`, true),
    },
    // Show terms needs to happen after hasRendered is set to true, so that the first render is without the modal
    // This is because the server does not render modals, and the re-hydration would fail when the content is not the same
    equals(props`action`),
    {
      terms: set(state`appContainer.showTermsModal`, true),
      otherwise: set(state`appContainer.showTermsModal`, false),
    },
  ]);
}

export function adminRendered(continueChain = []) {
  return sequence('appContainer.rendered', [
    when(
      props`action`,
      state`appContainer.isServer`,
      (action, isServer) => action && action === 'login' && isServer
    ),
    {
      true: actions.clearUrl,
      false: [
        when(
          state`appContainer.isServer`, // Is it the first render? Authenticate
          state`appContainer.user.uid`, // Is the user not authenticated and it is required? Authenticate
          state`appContainer.profiles.${state`appContainer.user.uid`}`, // Has the user not accepted terms? Auth to show modal
          props`requireAuth`,
          (isServer, uid, profile, requireAuth) =>
            isServer ||
            (!uid && requireAuth) ||
            (profile && !profile.acceptedTerms)
        ),
        {
          true: [
            ...authenticate(continueChain),
            set(state`appContainer.isServer`, false),
          ],
          false: continueChain,
        },
      ],
    },
    when(state`appContainer.hasRendered`),
    {
      true: [],
      false: [actions.listenToAuthState],
    },
    set(state`appContainer.hasRendered`, true),
    when(state`appContainer.shouldResetScroll`),
    {
      true: actions.scrollToScrollTop,
      false: set(state`appContainer.shouldResetScroll`, true),
    },
    // Show terms needs to happen after hasRendered is set to true, so that the first render is without the modal
    // This is because the server does not render modals, and the re-hydration would fail when the content is not the same
    equals(props`action`),
    {
      terms: set(state`appContainer.showTermsModal`, true),
      otherwise: set(state`appContainer.showTermsModal`, false),
    },
  ]);
}
