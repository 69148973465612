import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';

import { OwnState } from './types';

const state: OwnState = {
  total: {
    challenge: {},
    teams: {},
    sections: {},
    profiles: {},
  },
  daily: {
    user: {},
  },
};

const module: ModuleDefinition = {
  state,
  sequences: {
    dailyStatsChanged: sequences.updateDailyStats,
    dailyStatsAdded: sequences.addDailyStats,
    dailyStatsRemoved: sequences.removeDailyStats,
    totalStatsRemoved: sequences.removeTotalStats,
    totalChallengeStatsUpdated: sequences.updateTotalChallengeStats,
    totalProfileStatsUpdated: sequences.updateTotalProfileStats,
    totalSectionStatsUpdated: sequences.updateTotalSectionStats,
    totalTeamStatsUpdated: sequences.updateTotalTeamStats,
  },
};

export default module;
