import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { createUser } from './helpers';
import { FirebaseProviderAuthenticationError } from './errors';

export default function signInAnonymouslyFactory(auth) {
  return new Promise((resolve, reject) => {
    signInAnonymously(auth)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
          unsubscribe();
          resolve(createUser(user));
        });
      })
      .catch(error => {
        reject(new FirebaseProviderAuthenticationError(error));
      });
  });
}
