import * as cerebral from 'cerebral';
import * as AccountModule from 'account/module/types';
import * as ChallengeModule from 'challenge/module/types';
import * as AppModule from 'app/pwa/module/types';
import * as AppContainerModule from 'common/appContainer/module/types';

import * as providers from 'providers';

type State = {
  appContainer: AppContainerModule.State;
  app: AppModule.State;
  account: AccountModule.State;
  challenge: ChallengeModule.State;
  useragent: {
    network: {
      offline: boolean;
    };
    device: {
      vendor: string;
    };
  };
};

type Sequences = {
  challenge: ChallengeModule.Sequences;
};

export type Providers = {
  [key in keyof typeof providers]: (typeof providers)[key];
} & { firebase: any };

export type Context<Props = Record<string, never>> = cerebral.IContext<Props> &
  Providers;

export const { props } = cerebral;
export const state = cerebral.state as unknown as State;
export const { string } = cerebral;
export const { sequences } = cerebral;
// Temporarily used for typing sequences. The cerebral babel plugin requires certain names, so I'm high jacking this
export const moduleSequences = cerebral.sequences as unknown as Sequences;
export const { moduleState } = cerebral;
