import { state } from 'cerebral';
import { getDaysInMilliseconds, getLastDayTimestamp } from 'utils/common';
import { getGoalValue } from 'utils/challenge';

export function shouldSendDailyStreakSnackbar({ get, path }) {
  const datesLogged = Object.keys(get(state`challenge.stats.daily.user`));
  const challengeStartDatetime = get(
    state`challenge.campaign.challenge.startDatetime`
  );
  const todayDatetime = getLastDayTimestamp(challengeStartDatetime);
  const todayIndex = datesLogged.indexOf(String(todayDatetime));
  const dayLoggingForIndex = get(state`challenge.log.selectedDayIndex`);
  const dayLoggingFor =
    challengeStartDatetime + getDaysInMilliseconds(dayLoggingForIndex);

  let consecutiveDaysLogged = 0;

  let streakContinues = true;

  let dayIndex = 0;

  let isLoggingForToday = false;

  while (streakContinues) {
    const currentDayDatetime = todayDatetime - getDaysInMilliseconds(dayIndex);

    if (datesLogged[todayIndex - dayIndex] === String(currentDayDatetime)) {
      if (dayLoggingFor === todayDatetime) {
        isLoggingForToday = true;
      }

      consecutiveDaysLogged++;
      dayIndex++;
    } else {
      streakContinues = false;
    }
  }
  if (
    consecutiveDaysLogged > 2 &&
    consecutiveDaysLogged % 2 === 1 &&
    isLoggingForToday
  ) {
    return path.true({ consecutiveDaysLogged });
  }

  return path.false();
}

export function listenForTotalChallengeStats({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);

  firebase.onValue(
    `challenges.totalStats.challenges.${challengeKey}`,
    'challenge.stats.totalChallengeStatsUpdated'
  );
}

export function listenForTotalProfileStats({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const { sectionKey, teamKey } = get(state`challenge.userCampaign`);

  firebase.onChildChanged(
    `challenges.totalStats.profiles.${challengeKey}.${sectionKey}.${teamKey}`,
    'challenge.stats.totalProfileStatsUpdated'
  );

  firebase.onChildAdded(
    `challenges.totalStats.profiles.${challengeKey}.${sectionKey}.${teamKey}`,
    'challenge.stats.totalProfileStatsUpdated'
  );
}

export function listenForTotalSectionStats({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);

  firebase.onChildChanged(
    `challenges.totalStats.sections.${challengeKey}`,
    'challenge.stats.totalSectionStatsUpdated'
  );

  firebase.onChildAdded(
    `challenges.totalStats.sections.${challengeKey}`,
    'challenge.stats.totalSectionStatsUpdated'
  );
}

export function listenForTotalTeamsStats({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const sectionKey = get(state`challenge.userCampaign.sectionKey`);

  firebase.onChildChanged(
    `challenges.totalStats.teams.${challengeKey}.${sectionKey}`,
    'challenge.stats.totalTeamStatsUpdated',
    {
      payload: {
        sectionKey: sectionKey,
      },
    }
  );

  firebase.onChildAdded(
    `challenges.totalStats.teams.${challengeKey}.${sectionKey}`,
    'challenge.stats.totalTeamStatsUpdated',
    {
      payload: {
        sectionKey: sectionKey,
      },
    }
  );
}

export function hasAchievedGoal({ path, get }) {
  const uid = get(state`appContainer.user.uid`);
  const indicator = get(state`challenge.campaign.indicator`);
  const maxScore = get(state`challenge.maxScore`);
  const score = get(state`challenge.stats.total.profiles.${uid}.${indicator}`);
  const currentGoal = Object.entries(
    get(state`challenge.userChallenge.goals`) || {}
  )
    .map(([goalKey, goal]) => ({ goalKey, ...(goal as any) }))
    .find(goal => !goal.achieved);

  if (!currentGoal || maxScore === 0) return path.false();

  if (getGoalValue(maxScore, currentGoal.level) <= score) {
    return path.true({
      achievedGoalKey: currentGoal.goalKey,
    });
  }

  return path.false();
}

export function setAchievedGoal({ firebase, props, get }) {
  const { achievedGoalKey } = props;

  return firebase.task('complete_goal', {
    challengeKey: get(state`challenge.campaign.challenge.id`),
    goalKey: achievedGoalKey,
  });
}

export function setNewUserDailyStats({ props, store, get }) {
  const newData = props.value;
  const currentData = get(state`challenge.stats.daily.user.${props.key}`) || {};

  const newScores = Object.keys(newData).filter(
    key => key !== 'activitiesCount'
  );

  // Remove keys that were not in the new data
  Object.keys(currentData)
    .filter(key => key !== 'activitiesCount' && !newScores.includes(key))
    .forEach(keyToRemove => {
      store.unset(
        state`challenge.stats.daily.user.${props.key}.${keyToRemove}`
      );
    });

  // Change and add keys that were updated
  newScores
    .filter(key => newData[key] !== currentData[key])
    .forEach(keyToSet => {
      store.merge(state`challenge.stats.daily.user.${props.key}`, {
        [keyToSet]: newData[keyToSet],
      });
    });

  const newActivities = Object.keys(newData.activitiesCount || {});

  // Remove activities that were not in the new data
  Object.keys(currentData.activitiesCount || {})
    .filter(key => !newActivities.includes(key))
    .forEach(keyToRemove => {
      store.unset(
        state`challenge.stats.daily.user.${props.key}.activitiesCount.${keyToRemove}`
      );
    });

  // Change and add activities that were updated
  newActivities
    .filter(
      key => newData.activitiesCount[key] !== currentData.activitiesCount?.[key]
    )
    .forEach(keyToSet => {
      store.merge(
        state`challenge.stats.daily.user.${props.key}.activitiesCount`,
        {
          [keyToSet]: newData.activitiesCount[keyToSet],
        }
      );
    });
}
