/* eslint-disable */
import { CerebralError } from 'cerebral';
let _warnAboutDeprecatedIsAborted = false;

export default class HttpProviderError extends CerebralError {
  constructor(
    type,
    status,
    headers,
    result,
    message = null,
    isAborted = false
  ) {
    super(message);
    this.name = 'HttpProviderError';
    this.message = message;
    this.type = type || 'http';
    this.response = {
      status,
      headers,
      result
    };

    Object.defineProperty(this.response, 'isAborted', {
      get() {
        if (!_warnAboutDeprecatedIsAborted) {
          console.warn(
            'DEPRECATED - Please use error.type === "abort" instead'
          );
          _warnAboutDeprecatedIsAborted = true;
        }

        return isAborted;
      }
    });
  }
  toJSON() {
    return {
      type: this.type,
      name: this.name,
      message: this.message,
      response: this.response,
      stack: this.stack
    };
  }
}
