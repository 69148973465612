/* eslint-disable max-classes-per-file*/
import { CerebralError } from 'cerebral';

export class FirebaseProviderError extends CerebralError {
  constructor(error) {
    super(error.message);
    this.name = 'FirebaseProviderError';
    this.message = error.message;
    this.code = error.code;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      stack: this.stack,
      code: this.code,
    };
  }
}

export class FirebaseProviderAuthenticationError extends CerebralError {
  constructor(error) {
    super(error);
    this.name = 'FirebaseProviderAuthenticationError';
    this.message = error.message;
    this.code = error.code;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      stack: this.stack,
    };
  }
}
