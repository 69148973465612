import { Provider } from 'cerebral';

function isSupported() {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    localStorage.setItem(key, key);
    localStorage.removeItem(key);

    return true;
  } catch (e) {
    return false;
  }
}

function StorageProvider() {
  return Provider({
    set(key, value) {
      if (isSupported()) {
        return localStorage.setItem(key, JSON.stringify(value));
      }

      return null;
    },
    clear() {
      if (isSupported()) {
        return localStorage.clear();
      }

      return null;
    },
    remove(key) {
      if (isSupported()) {
        return localStorage.removeItem(key);
      }

      return null;
    },
    get(key) {
      if (isSupported()) {
        try {
          return JSON.parse(localStorage.getItem(key));
        } catch (e) {
          return localStorage.getItem(key);
        }
      }

      return null;
    },
    getKeys() {
      if (isSupported()) {
        return Object.keys(localStorage);
      }

      return null;
    },
  });
}

export default StorageProvider;
