import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';

export default Compute(get => {
  const tag = get(state.challenge.tagModal.key);
  const activities = get(state.appContainer.activities);
  const todaysLoggedActivities = get(
    state.challenge.stats.daily.user[
      state.challenge.campaign.challenge.startOfDays[
        state.challenge.log.selectedDayIndex
      ]
    ].activitiesCount
  );

  return Object.keys(todaysLoggedActivities || {}).some(activityKey =>
    activities[activityKey].tags.categories.includes(tag)
  );
});
