import { state } from 'cerebral';

export function getPushNotificationToken({ firebase }) {
  return firebase.messagingIsSupported().then(isSupported => {
    if (!isSupported) return { notificationToken: null };

    return Notification.requestPermission()
      .then(() =>
        firebase
          .messagingGetToken()
          .then(currentToken => ({ notificationToken: currentToken || null }))
      )
      .catch(err => {
        console.log('Failed to configure messaging', err); // eslint-disable-line no-console

        return { notificationToken: null };
      });
  });
}

export function setPushNotificationToken({ firebase, props }) {
  return firebase.task('update_notification_token', {
    token: props.notificationToken,
  });
}

export function setTeamHasLoggedPushNotification({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const { sectionKey, teamKey } = get(state`challenge.userCampaign`);
  const language = get(state`appContainer.language.locale`);

  return firebase.task('add_team_has_logged_notification', {
    challengeKey: challengeKey,
    sectionKey: sectionKey,
    teamKey: teamKey,
    language: language,
  });
}
