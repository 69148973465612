import { createUserWithEmailAndPassword } from 'firebase/auth';
import { createUser } from './helpers';
import { FirebaseProviderAuthenticationError } from './errors';

export default function createUserWithEmailAndPasswordFactory(
  auth,
  email,
  password
) {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(userCredentials => createUser(userCredentials.user))
    .catch(error => {
      throw new FirebaseProviderAuthenticationError(error);
    });
}
