import { when, set, equals, debounce } from 'cerebral/factories';
import { props, state } from 'cerebral';

import * as actions from './actions';

export const render = [
  when(props`link`),
  {
    true: [
      set(state`account.joinCampaign.campaignKey`, props`link`),
      set(state`account.joinCampaign.selections.campaign`, props`link`),
      actions.getCampaign,
      when(props`campaign`),
      {
        false: [
          set(state`account.joinCampaign.subPage`, 'error'),
          set(state`account.joinCampaign.joiningError`, 'invalid_link'),
        ],
        true: [
          set(state`account.joinCampaign.campaign`, props`campaign`),
          actions.isMember,
          {
            true: [actions.redirect],
            false: [
              equals(state`account.joinCampaign.campaign.access`),
              {
                link: [
                  set(state`account.joinCampaign.subPage`, 'sectionAndTeam'),
                ],
                code: [set(state`account.joinCampaign.subPage`, 'code')],
                email: [
                  actions.isValidDomain,
                  {
                    true: [
                      set(
                        state`account.joinCampaign.subPage`,
                        'sectionAndTeam'
                      ),
                    ],
                    false: [
                      set(state`account.joinCampaign.subPage`, 'error'),
                      set(
                        state`account.joinCampaign.joiningError`,
                        'invalid_domain'
                      ),
                    ],
                  },
                ],
                email_and_code: [
                  actions.isValidDomain,
                  {
                    true: [set(state`account.joinCampaign.subPage`, 'code')],
                    false: [
                      set(state`account.joinCampaign.subPage`, 'error'),
                      set(
                        state`account.joinCampaign.joiningError`,
                        'invalid_domain'
                      ),
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    false: [set(state`account.joinCampaign.subPage`, 'code')],
  },
  set(state`account.joinCampaign.loaded`, true),
];

export const hideOtherDropdown = [
  when(
    props`dropdown`,
    state`account.joinCampaign.dropdown`,
    (a, b) => a === b
  ),
  {
    true: [],
    false: [set(state`account.joinCampaign.dropdown`, null)],
  },
];

export const toggleShowDropdown = [
  when(
    props`dropdown`,
    state`account.joinCampaign.dropdown`,
    (a, b) => a === b
  ),
  {
    true: [set(state`account.joinCampaign.dropdown`, null)],
    false: [set(state`account.joinCampaign.dropdown`, props`dropdown`)],
  },
];

export const showDropdown = [
  set(state`account.joinCampaign.dropdown`, props`dropdown`),
];

const reset = [
  equals(props`field`),
  {
    campaign: [
      set(state`account.joinCampaign.selections.section`, null),
      set(state`account.joinCampaign.fields.section`, ''),
      set(state`account.joinCampaign.selections.team`, null),
      set(state`account.joinCampaign.fields.team`, ''),
    ],
    section: [
      set(state`account.joinCampaign.selections.team`, null),
      set(state`account.joinCampaign.fields.team`, ''),
    ],
    team: [],
  },
];

export const optionSelected = [
  set(state`account.joinCampaign.selections.${props`field`}`, props`key`),
  set(state`account.joinCampaign.fields.${props`field`}`, props`text`),
  set(state`account.joinCampaign.dropdown`, null),
  reset,
];

export const optionChanged = [
  set(state`account.joinCampaign.selections.${props`field`}`, null),
  set(state`account.joinCampaign.fields.${props`field`}`, props`value`),
  reset,
];

const join = [
  set(state`account.joinCampaign.code`, ''),
  set(state`account.joinCampaign.codeNames`, {}),
  actions.getCampaignForRedirect,
  actions.redirect,
];

export const joinCampaignCode = [
  set(state`account.joinCampaign.isJoining`, true),
  actions.joinCampaignCode,
  {
    success: join,
    error: [
      when(props`error`, error => error === 'E303'),
      {
        true: join,
        false: [
          set(state`account.joinCampaign.subPage`, 'error'),
          set(state`account.joinCampaign.joiningError`, props`error`),
          set(state`account.joinCampaign.codeNames`, {}),
          set(state`account.joinCampaign.selections.campaign`, null),
          set(state`account.joinCampaign.selections.section`, null),
          set(state`account.joinCampaign.selections.team`, null),
        ],
      },
    ],
  },
  set(state`account.joinCampaign.isJoining`, false),
];

export const joinCampaignSectionAndTeam = [
  set(state`account.joinCampaign.isJoining`, true),
  actions.joinCampaignSectionAndTeam,
  {
    success: [
      set(props`campaign`, state`account.joinCampaign.campaign`),
      actions.redirect,
    ],
    error: [
      set(state`account.joinCampaign.subPage`, 'error'),
      set(state`account.joinCampaign.joiningError`, 'joining_error'),
    ],
  },
];

export const codeChanged = [
  set(state`account.joinCampaign.code`, props`value`),
  set(state`account.joinCampaign.joiningError`, null),
  when(props`value`, value => value.length !== 6),
  {
    true: [
      set(state`account.joinCampaign.codeNames`, {}),
      set(state`account.joinCampaign.codeError`, null),
    ],
    false: [
      debounce(1000),
      {
        continue: [
          set(state`account.joinCampaign.isValidatingCode`, true),
          actions.checkCode,
          {
            success: [
              set(state`account.joinCampaign.selections`, props`keys`),
              set(state`account.joinCampaign.codeNames`, props`names`),
              set(state`account.joinCampaign.codeError`, null),
            ],
            error: [
              set(state`account.joinCampaign.codeNames`, {}),
              set(state`account.joinCampaign.codeError`, props`error`),
            ],
          },
          set(state`account.joinCampaign.isValidatingCode`, false),
        ],
        discard: [],
      },
    ],
  },
];
