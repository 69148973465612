import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getLastDayTimestamp, getFullDaysDiff } from 'utils/common';

export default Compute(get => {
  const isOffline = get(state.useragent.network.offline);
  const selectedDayIndex = get(state.challenge.log.selectedDayIndex);
  const challenge = get(state.challenge.campaign.challenge);

  if (!challenge) return false;

  const currentDayKey = getLastDayTimestamp(challenge.startDatetime);
  const currentDayIndex = getFullDaysDiff(
    challenge.startDatetime,
    currentDayKey
  );

  return (
    !isOffline &&
    Date.now() >= challenge.startDatetime &&
    Date.now() <= challenge.endDatetime &&
    currentDayIndex - challenge.backlogDays <= selectedDayIndex
  );
});
