/* eslint-disable max-lines */
import { getIndicatorConfig, getIndicator } from './common';
import { ICON } from 'resources/constants';
import { Activity, Sponsor } from 'common/types';
import {
  FormattedLeaderboard,
  Leaderboard,
} from 'challenge/module/modules/visualisations/types';

export function getTagIconColor(key: string): string {
  const colors = {
    tutorial: '#007565',
    food: '#F6BA75',
    food_waste: '#F6BA75',
    sustainable_food: '#F6BA75',
    transport: '#673E88',
    energy: '#00A1C9',
    consumption: '#EF5F8A',
    public: '#3999E3',
    plastic: '#1DB3D9',
    microplastics: '#1DB3D9',
    overuse: '#1DB3D9',
    plastic_on_the_go: '#1DB3D9',
    handprint: '#689F38',
    1: '#EB1C2D',
    2: '#D3A029',
    3: '#279B48',
    4: '#C31F33',
    5: '#EF402B',
    6: '#00AED9',
    7: '#FDB713',
    8: '#8F1838',
    9: '#F36D25',
    10: '#E11484',
    11: '#F99D26',
    12: '#CF8D2A',
    13: '#528B58',
    14: '#007DBC',
    15: '#3EB049',
    16: '#02558B',
    17: '#183668',
  };

  return colors[key];
}

export function getTagInfoColors(key: string): {
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
  quinary: string;
} {
  const primaryColors = {
    tutorial: '#007565',
    food: '#ECA156',
    food_waste: '#ECA156',
    sustainable_food: '#ECA156',
    transport: '#7A4E94',
    energy: '#0487B0',
    consumption: '#9D2E58',
    public: '#3999E3',
    plastic: '#007079',
    microplastics: '#007079',
    overuse: '#007079',
    plastic_on_the_go: '#007079',
    handprint: '#689F38',
    1: '#EB1C2D',
    2: '#D3A029',
    3: '#279B48',
    4: '#C31F33',
    5: '#EF402B',
    6: '#00AED9',
    7: '#FDB713',
    8: '#8F1838',
    9: '#F36D25',
    10: '#E11484',
    11: '#F99D26',
    12: '#CF8D2A',
    13: '#528B58',
    14: '#007DBC',
    15: '#3EB049',
    16: '#02558B',
    17: '#183668',
  };

  const secondaryColors = {
    tutorial: '#007565',
    food: '#FAD297',
    food_waste: '#FAD297',
    sustainable_food: '#FAD297',
    transport: '#CB9BE0',
    energy: '#68D4EC',
    consumption: '#F27596',
    public: '#3999E3',
    plastic: '#35B8C4',
    microplastics: '#35B8C4',
    overuse: '#35B8C4',
    plastic_on_the_go: '#35B8C4',
    handprint: '#AED581',
    1: 'rgba(235, 28, 45, 0.5)',
    2: 'rgba(211, 160, 41, 0.5)',
    3: 'rgba(39, 155, 72, 0.5)',
    4: 'rgba(195, 31, 51, 0.5)',
    5: 'rgba(239, 64, 43, 0.5)',
    6: 'rgba(0, 174, 217, 0.5)',
    7: 'rgba(253, 183, 19, 0.5)',
    8: 'rgba(143, 24, 56, 0.5)',
    9: 'rgba(243, 109, 37, 0.5)',
    10: 'rgba(225, 20, 132, 0.5)',
    11: 'rgba(249, 157, 38, 0.5)',
    12: 'rgba(207, 141, 42, 0.5)',
    13: 'rgba(82, 139, 88, 0.5)',
    14: 'rgba(0, 125, 188, 0.5)',
    15: 'rgba(62, 176, 73, 0.5)',
    16: 'rgba(2, 85, 139, 0.5)',
    17: 'rgba(24, 54, 104, 0.5)',
  };

  const tertiaryColors = {
    tutorial: '#007565',
    food: '#623E2F',
    food_waste: '#623E2F',
    sustainable_food: '#623E2F',
    transport: '#523375',
    energy: '#005E86',
    consumption: '#7C2A4B',
    public: '#3999E3',
    plastic: '#035D67',
    microplastics: '#035D67',
    overuse: '#035D67',
    plastic_on_the_go: '#035D67',
    handprint: '#689F38',
    1: '#EB1C2D',
    2: '#D3A029',
    3: '#279B48',
    4: '#C31F33',
    5: '#EF402B',
    6: '#00AED9',
    7: '#FDB713',
    8: '#8F1838',
    9: '#F36D25',
    10: '#E11484',
    11: '#F99D26',
    12: '#CF8D2A',
    13: '#528B58',
    14: '#007DBC',
    15: '#3EB049',
    16: '#02558B',
    17: '#183668',
  };

  const quaternaryColors = {
    tutorial: '#007565',
    food: '#F6BA75',
    food_waste: '#F6BA75',
    sustainable_food: '#F6BA75',
    transport: '#AA75BA',
    energy: '#1DB3D9',
    consumption: '#E0487B',
    public: '#3999E3',
    plastic: '#0099A9',
    microplastics: '#0099A9',
    overuse: '#0099A9',
    plastic_on_the_go: '#0099A9',
    handprint: '#689F38',
    1: '#EB1C2D',
    2: '#D3A029',
    3: '#279B48',
    4: '#C31F33',
    5: '#EF402B',
    6: '#00AED9',
    7: '#FDB713',
    8: '#8F1838',
    9: '#F36D25',
    10: '#E11484',
    11: '#F99D26',
    12: '#CF8D2A',
    13: '#528B58',
    14: '#007DBC',
    15: '#3EB049',
    16: '#02558B',
    17: '#183668',
  };

  const quinaryColors = {
    tutorial: '#007565',
    food: '#FCEAC6',
    food_waste: '#FCEAC6',
    sustainable_food: '#FCEAC6',
    transport: '#CB9BE0',
    energy: '#C6EEF7',
    consumption: '#FCD7DF',
    public: '#3999E3',
    plastic: '#CDF2F7',
    microplastics: '#CDF2F7',
    overuse: '#CDF2F7',
    plastic_on_the_go: '#CDF2F7',
    handprint: '#689F38',
    1: '#EB1C2D',
    2: '#D3A029',
    3: '#279B48',
    4: '#C31F33',
    5: '#EF402B',
    6: '#00AED9',
    7: '#FDB713',
    8: '#8F1838',
    9: '#F36D25',
    10: '#E11484',
    11: '#F99D26',
    12: '#CF8D2A',
    13: '#528B58',
    14: '#007DBC',
    15: '#3EB049',
    16: '#02558B',
    17: '#183668',
  };

  return {
    primary: primaryColors[key],
    secondary: secondaryColors[key],
    tertiary: tertiaryColors[key],
    quaternary: quaternaryColors[key],
    quinary: quinaryColors[key],
  };
}

export function getTagIcon(key: string): ICON {
  const iconRefs = {
    tutorial: ICON.help,
    food: ICON.restaurantMenu,
    food_waste: ICON.foodVariant,
    sustainable_food: ICON.restaurantMenu,
    transport: ICON.directionsCar,
    energy: ICON.power,
    consumption: ICON.shoppingCart,
    public: ICON.public,
    plastic: ICON.foodForkDrink,
    microplastics: ICON.microplastics,
    overuse: ICON.overuse,
    plastic_on_the_go: ICON.foodForkDrink,
    1: ICON.trackChanges,
    2: ICON.trackChanges,
    3: ICON.trackChanges,
    4: ICON.trackChanges,
    5: ICON.trackChanges,
    6: ICON.trackChanges,
    7: ICON.trackChanges,
    8: ICON.trackChanges,
    9: ICON.trackChanges,
    10: ICON.trackChanges,
    11: ICON.trackChanges,
    12: ICON.trackChanges,
    13: ICON.trackChanges,
    14: ICON.trackChanges,
    15: ICON.trackChanges,
    16: ICON.trackChanges,
    17: ICON.trackChanges,
  };

  return iconRefs[key] || ICON.trackChanges;
}

export function getTagImage(
  staticUrl: string,
  key: string
): { small: string; large: string; hasBottomPadding?: boolean } {
  const iconRefs = {
    food: {
      small: `${staticUrl}/images/tags/cheesemilkSmall.png`,
      large: `${staticUrl}/images/tags/cheesemilkLarge.png`,
      hasBottomPadding: true,
    },
    sustainable_food: {
      small: `${staticUrl}/images/tags/sustainable_foodSmall.png`,
      large: `${staticUrl}/images/tags/sustainable_foodLarge.png`,
      hasBottomPadding: true,
    },
    food_waste: {
      small: `${staticUrl}/images/tags/food_wasteSmall.png`,
      large: `${staticUrl}/images/tags/food_wasteLarge.png`,
      hasBottomPadding: true,
    },
    transport: {
      small: `${staticUrl}/images/tags/bikeSmall.png`,
      large: `${staticUrl}/images/tags/bikeLarge.png`,
      hasBottomPadding: true,
    },
    energy: {
      small: `${staticUrl}/images/tags/bulbSmall.png`,
      large: `${staticUrl}/images/tags/bulbLarge.png`,
      hasBottomPadding: true,
    },
    consumption: {
      small: `${staticUrl}/images/tags/consumptionSmall.png`,
      large: `${staticUrl}/images/tags/consumptionLarge.png`,
      hasBottomPadding: true,
    },
    microplastics: {
      small: `${staticUrl}/images/tags/micro_plasticSmall.png`,
      large: `${staticUrl}/images/tags/micro_plasticLarge.png`,
    },
    plastic: {
      small: `${staticUrl}/images/tags/plastic_on_the_goSmall.png`,
      large: `${staticUrl}/images/tags/plastic_on_the_goLarge.png`,
      hasBottomPadding: true,
    },
    plastic_on_the_go: {
      small: `${staticUrl}/images/tags/plastic_on_the_goSmall.png`,
      large: `${staticUrl}/images/tags/plastic_on_the_goLarge.png`,
      hasBottomPadding: true,
    },
    overuse: {
      small: `${staticUrl}/images/tags/plastic_on_the_goSmall.png`,
      large: `${staticUrl}/images/tags/plastic_on_the_goLarge.png`,
      hasBottomPadding: true,
    },
    handprint: {
      small: `${staticUrl}/images/tags/handprintSmall.png`,
      large: `${staticUrl}/images/tags/handprintLarge.png`,
      hasBottomPadding: true,
    },
    1: {
      small: `${staticUrl}/images/tags/SDG_1Small.png`,
      large: `${staticUrl}/images/tags/SDG_1Large.png`,
    },
    2: {
      small: `${staticUrl}/images/tags/SDG_2Small.png`,
      large: `${staticUrl}/images/tags/SDG_2Large.png`,
    },
    3: {
      small: `${staticUrl}/images/tags/SDG_3Small.png`,
      large: `${staticUrl}/images/tags/SDG_3Large.png`,
    },
    4: {
      small: `${staticUrl}/images/tags/SDG_4Small.png`,
      large: `${staticUrl}/images/tags/SDG_4Large.png`,
    },
    5: {
      small: `${staticUrl}/images/tags/SDG_5Small.png`,
      large: `${staticUrl}/images/tags/SDG_5Large.png`,
    },
    6: {
      small: `${staticUrl}/images/tags/SDG_6Small.png`,
      large: `${staticUrl}/images/tags/SDG_6Large.png`,
    },
    7: {
      small: `${staticUrl}/images/tags/SDG_7Small.png`,
      large: `${staticUrl}/images/tags/SDG_7Large.png`,
    },
    8: {
      small: `${staticUrl}/images/tags/SDG_8Small.png`,
      large: `${staticUrl}/images/tags/SDG_8Large.png`,
    },
    9: {
      small: `${staticUrl}/images/tags/SDG_9Small.png`,
      large: `${staticUrl}/images/tags/SDG_9Large.png`,
    },
    10: {
      small: `${staticUrl}/images/tags/SDG_10Small.png`,
      large: `${staticUrl}/images/tags/SDG_10Large.png`,
    },
    11: {
      small: `${staticUrl}/images/tags/SDG_11Small.png`,
      large: `${staticUrl}/images/tags/SDG_11Large.png`,
    },
    12: {
      small: `${staticUrl}/images/tags/SDG_12Small.png`,
      large: `${staticUrl}/images/tags/SDG_12Large.png`,
    },
    13: {
      small: `${staticUrl}/images/tags/SDG_13Small.png`,
      large: `${staticUrl}/images/tags/SDG_13Large.png`,
    },
    14: {
      small: `${staticUrl}/images/tags/SDG_14Small.png`,
      large: `${staticUrl}/images/tags/SDG_14Large.png`,
    },
    15: {
      small: `${staticUrl}/images/tags/SDG_15Small.png`,
      large: `${staticUrl}/images/tags/SDG_15Large.png`,
    },
    16: {
      small: `${staticUrl}/images/tags/SDG_16Small.png`,
      large: `${staticUrl}/images/tags/SDG_16Large.png`,
    },
    17: {
      small: `${staticUrl}/images/tags/SDG_17Small.png`,
      large: `${staticUrl}/images/tags/SDG_17Large.png`,
    },
  };

  return iconRefs[key];
}

type IndicatorItem = {
  key: string;
  isCompetingIndicator: boolean;
  value: number;
  icon: string;
  color: string;
};

/**
 * Create a copy of the array and sort indicators by descending value
 * The sorting order is:
 * 1. values > 0 order from large to small
 * 2. values === null sorted by key, ascending
 * 3. values === 0 sorted by key, ascending
 */
export function sortIndicatorsDesc(
  indicators: IndicatorItem[]
): IndicatorItem[] {
  const splitIndicatorsToCategories = indicators.reduce<{
    sortedPositivesNumbers: IndicatorItem[];
    sortedNulls: IndicatorItem[];
    sortedZeros: IndicatorItem[];
  }>(
    ({ sortedPositivesNumbers, sortedNulls, sortedZeros }, indicator) => {
      if (typeof indicator.value === 'number' && indicator.value > 0)
        return {
          sortedPositivesNumbers: [...sortedPositivesNumbers, indicator],
          sortedNulls,
          sortedZeros,
        };
      else if (typeof indicator.value === 'number')
        return {
          sortedPositivesNumbers,
          sortedNulls,
          sortedZeros: [...sortedZeros, indicator],
        };

      // else indicator.value === null
      return {
        sortedPositivesNumbers,
        sortedNulls: [...sortedNulls, indicator],
        sortedZeros,
      };
    },
    {
      sortedPositivesNumbers: [],
      sortedNulls: [],
      sortedZeros: [],
    }
  );
  const { sortedPositivesNumbers, sortedNulls, sortedZeros } =
    splitIndicatorsToCategories;

  sortedPositivesNumbers.sort((a, b) => b.value - a.value); // sort values descending
  sortedNulls.sort((a, b) => a.key.localeCompare(b.key)); // string keys ascending
  sortedZeros.sort((a, b) => a.key.localeCompare(b.key)); // string keys ascending

  return [...sortedPositivesNumbers, ...sortedNulls, ...sortedZeros];
}

/**
 * Sort indicators by descending value but put the campaignIndicator first
 */
export function sortIndicatorsForChallenge(
  activity: Activity,
  campaignIndicator: string
): IndicatorItem[] {
  const indicators = [];
  let competingIndicator = null;

  Object.keys(activity?.indicators || {}).forEach(indicator => {
    const indicatorConfig = getIndicatorConfig(indicator);
    const indicatorItem = {
      key: indicator,
      isCompetingIndicator: indicator === campaignIndicator,
      value: getIndicator(activity.indicators, indicator),
      icon: indicatorConfig.icon,
      color: indicatorConfig.color,
    };
    // Insert the campaignIndicator at the beginning of the array
    if (indicatorItem.isCompetingIndicator) {
      competingIndicator = indicatorItem;
    } else {
      indicators.push(indicatorItem);
    }
  });

  const sortedIndicators = sortIndicatorsDesc(indicators);

  if (competingIndicator) sortedIndicators.splice(0, 0, competingIndicator);

  return sortedIndicators;
}

export function getActivityIconUrl(
  icon: {
    png: {
      [key: string]: string;
    };
    svg: {
      [key: string]: string;
    };
    webp?: {
      [key: string]: string;
    };
  },
  filetype: 'svg' | 'png' | 'webp' = 'webp'
): string {
  const filetypes = ['svg', 'png', 'webp'];
  const preferredSize = '196x196';
  const placeholder =
    filetype === 'svg'
      ? `https://static.ducky.eco/SVGIcons/activities/act_placeholder.${filetype}`
      : `https://static.ducky.eco/SVGIcons/activities/act_placeholder.png`;

  if (!(filetypes.includes(filetype) && filetypes.includes('png')))
    throw new Error(
      `Invalid filetype "${filetype}" supplied to "getActivityIconUrl"`
    );

  // Older activities only have svg and png, and only contain 1 key/value pair
  // which is not 128x128
  const pngFallback = Object.values(icon?.['png'] ?? {})[0];
  const iconUrl =
    icon?.[filetype]?.[preferredSize] ??
    Object.values(icon?.[filetype] ?? {})[0] ??
    pngFallback;

  return iconUrl ?? placeholder;
}

const goalLevels = [0.14, 0.39, 0.74];

export function getGoalValue(maxScore: number, index: number): number {
  return maxScore * goalLevels[index - 1];
}

export function getGoalValues(maxScore: number): number[] {
  return goalLevels.map((level, index) => getGoalValue(maxScore, index + 1));
}

export function orderAndFormatLeaderboard(
  leaderboard: Omit<Leaderboard, 'position'>[],
  page: number,
  pageSize: number,
  expanded: boolean
): FormattedLeaderboard {
  const wholeLeaderboard = leaderboard
    .sort((l1, l2) => l2.value - l1.value)
    .map((lb, index) => ({
      ...lb,
      position: index + 1,
    }));

  const yourIndex = wholeLeaderboard.findIndex(entry => entry.isEmphasised);
  const startIndex = Math.min(
    Math.max(yourIndex - 1, 0),
    Math.max(wholeLeaderboard.length - 3, 0)
  );

  return {
    short: wholeLeaderboard.slice(startIndex, startIndex + 3),
    long: wholeLeaderboard.slice(
      expanded ? page * pageSize : 0,
      expanded ? (page + 1) * pageSize : 5
    ),
    pageCount: Math.ceil(wholeLeaderboard.length / pageSize),
    currentPage: page,
  };
}

export function getQuizSummaryContent(
  staticUrl: string,
  score: number,
  maxScore: number
): { textPath: string; image: string } {
  if (score === maxScore)
    return {
      textPath: 'challenge.quiz.end_page.congrats',
      image: `${staticUrl}/images/challenge/quiz/duckCheering.png`,
    };

  if (score > 1)
    return {
      textPath: 'challenge.quiz.end_page.congrats',
      image: `${staticUrl}/images/challenge/quiz/duckWinking.png`,
    };

  return {
    textPath: 'challenge.quiz.end_page.tough',
    image: `${staticUrl}/images/challenge/quiz/duckTrying.png`,
  };
}

export function getOrderedSponsors(sponsors: Sponsor[]) {
  const orderedSponsors =
    sponsors?.length > 0 &&
    sponsors.slice().sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }

      return 0;
    });

  return orderedSponsors || [];
}
