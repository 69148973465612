function send(Sentry, data) {
  if (data instanceof Error) {
    Sentry.captureException(data);
  } else {
    Sentry.captureMessage(data);
  }
}

function sendLevel(Sentry, data, level) {
  Sentry.withScope(scope => {
    scope.setLevel(level);

    send(Sentry, data);
  });
}

export default function provider(Sentry) {
  return {
    error(message) {
      send(Sentry, message);
    },
    debug(message) {
      sendLevel(Sentry, message, 'debug');
    },
    info(message) {
      sendLevel(Sentry, message, 'info');
    },
    warning(message) {
      sendLevel(Sentry, message, 'warning');
    },
    fatal(message) {
      sendLevel(Sentry, message, 'fatal');
    },
    setUser({ uid, email }) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: uid, email: email });
      });
    },
    setExtra(extra = {}) {
      Sentry.configureScope(scope => {
        Object.keys(extra).forEach(key => {
          scope.setExtra(key, extra[key]);
        });
      });
    },
    setTags(tags = {}) {
      Sentry.configureScope(scope => {
        Object.keys(tags).forEach(key => {
          scope.setTag(key, tags[key]);
        });
      });
    },
  };
}
