import { primaryLanguage } from 'languages/config';
import { isValidCampaignCode } from 'utils/common';

export function getCampaign({ props, database }) {
  return database.getCampaign(props.link).then(campaign => ({ campaign }));
}

export function getCampaignForRedirect({ state, database }) {
  const campaignKey = state.get('account.joinCampaign.selections.campaign');

  return database.getCampaign(campaignKey).then(campaign => ({ campaign }));
}

export function joinCampaignSectionAndTeam({ state, firebase, path }) {
  const { campaign, section, team } = state.get(
    `account.joinCampaign.selections`
  );

  return firebase
    .task('join_campaign', {
      campaignKey: campaign,
      sectionKey: section,
      teamKey: team,
    })
    .then(path.success)
    .catch(path.error);
}

export function joinCampaignCode({ state, firebase, path }) {
  const code = state.get('account.joinCampaign.code');
  const campaignKey = state.get(`account.joinCampaign.selections.campaign`);

  return firebase
    .task('join_campaign', { code, campaignKey })
    .then(path.success)
    .catch(error => path.error({ error: error.message }));
}

export function checkCode({ firebase, path, props }) {
  if (props.value.length === 0) return path.no_code();

  if (!isValidCampaignCode(props.value)) {
    return path.error({ error: 'must_be_numeric' });
  }

  return firebase
    .task('get_campaign_code_details', {
      code: props.value,
      campaignKey: props.campaignKey,
    })
    .then(codeDetails => {
      if (!codeDetails) return path.invalid();

      return path.success({
        keys: {
          team: codeDetails.team.id,
          section: codeDetails.section.id,
          campaign: codeDetails.campaign.id,
        },
        names: {
          team: codeDetails.team.name,
          section: codeDetails.section.name,
          campaign: codeDetails.campaign.name,
        },
      });
    })
    .catch(error => path.error({ error: error.message }));
}

export function redirect({ props, state, router }) {
  const lang = state.get('appContainer.language.locale');
  const { challenge } = props.campaign;

  const language = primaryLanguage === lang ? '/' : `/${lang}/`;

  let url = `${language}`;

  if (challenge) {
    url = `${language}challenge/${challenge.id}`;
  }

  router.goTo(url);
}

export function isValidDomain({ database, state, path }) {
  const campaignKey = state.get('account.joinCampaign.campaignKey');
  const email = state.get('appContainer.user.email');
  const noDots = email.slice(email.indexOf('@') + 1).replace(/\./gu, '_');

  return database
    .isAllowedEmailDomainForCampaign(noDots, campaignKey)
    .then(isAllowed => {
      if (isAllowed) return path.true();

      return path.false();
    });
}

export function isMember({ path, state, firebase }) {
  const uid = state.get(`appContainer.user.uid`);
  const campaignKey = state.get('account.joinCampaign.campaignKey');

  return firebase
    .value(`profiles.campaigns.${uid}.${campaignKey}`)
    .then(response => (response.value ? path.true() : path.false()));
}
