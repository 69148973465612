import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import { renderPage } from '../../factories';
import ComputedCampaigns from '../../../computed/timeline';

import { OwnState } from './types';

const state: OwnState = {
  loaded: false,
  organisationNames: {},
  subItemsShowing: '',
  explanationModalShowing: false,
  campaigns: {},
  computedCampaigns: ComputedCampaigns,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    routed: renderPage('timeline', sequences.render),
    toggleShowTimelineSubItems: sequences.toggleShowTimelineSubItems,
    redirectToTool: sequences.redirectToTool,
    redirectToCampaignPage: sequences.redirectToCampaignPage,
    showExplanationModal: sequences.showExplanationModal,
  },
};

export default module;
