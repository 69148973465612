import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import { INDICATOR } from 'resources/constants';

import { OwnState } from './types';

const state: OwnState = {
  tabIndex: 0,
  showActivityModal: false,
  activityKey: null,
  showMoreInfo: Object.assign(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...Object.values(INDICATOR).map((indicator: string) => ({
      [indicator]: false,
    }))
  ),
};

const module: ModuleDefinition = {
  state,
  sequences: {
    tabClicked: sequences.setTabIndex,
    closeActivityModalClicked: sequences.closeActivityModalClicked,
    toggleIndicatorExpanded: sequences.toggleIndicatorExpanded,
  },
};

export default module;
