import { sequence, parallel, unset, set, when } from 'cerebral/factories';
import { state, props, sequences, string } from 'cerebral';
import {
  value,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from 'providers/Firebase/src/operators';
import { getTranslationByValue, notify } from 'common/factories';
import * as actions from './actions';
import * as logSequences from 'challenge/module/modules/log/sequences';

const dailyStatsPath = string`challenges.dailyStats.profiles.${state`challenge.key`}.${state`appContainer.user.uid`}`;

export const getDailyUserStats = [
  sequence('getDailyUserStats', [
    value(dailyStatsPath),
    when(props`response.value`),
    {
      true: set(state`challenge.stats.daily.user`, props`response.value`),
      false: set(state`challenge.stats.daily.user`, {}),
    },
    set(state`challenge.isLoading.dailyUserStats`, false),
    onChildChanged(
      dailyStatsPath,
      sequences`challenge.stats.dailyStatsChanged`
    ),
    onChildAdded(dailyStatsPath, sequences`challenge.stats.dailyStatsAdded`),
    onChildRemoved(
      dailyStatsPath,
      sequences`challenge.stats.dailyStatsRemoved`
    ),
  ]),
];

export const getTotalChallengeStats = [
  sequence('getTotalChallengeStats', [
    value(string`challenges.totalStats.challenges.${state`challenge.key`}`),
    when(props`response.value`),
    {
      true: set(state`challenge.stats.total.challenge`, props`response.value`),
      false: set(state`challenge.stats.total.challenge`, {}),
    },
    set(state`challenge.isLoading.totalChallengeStats`, false),
  ]),
];

const totalStatsPath = string`challenges.totalStats.profiles.${state`challenge.key`}.${state`challenge.userCampaign.sectionKey`}.${state`challenge.userCampaign.teamKey`}`;

export const getTotalProfileStats = [
  sequence('getTotalProfilesStats', [
    value(totalStatsPath),
    when(props`response.value`),
    {
      true: set(state`challenge.stats.total.profiles`, props`response.value`),
      false: set(state`challenge.stats.total.profiles`, {}),
    },
    set(state`challenge.isLoading.totalProfilesStats`, false),
    onChildRemoved(
      totalStatsPath,
      sequences`challenge.stats.totalStatsRemoved`
    ),
  ]),
];

export const removeTotalStats = [
  unset(state`challenge.stats.total.profiles.${props`key`}`),
];

export const updateDailyStats = [actions.setNewUserDailyStats];

export const addDailyStats = [
  when(state`challenge.stats.daily.user.${props`key`}`), // Don't set the data again
  {
    true: [],
    false: [
      actions.setNewUserDailyStats,
      actions.shouldSendDailyStreakSnackbar,
      {
        true: [
          notify('dailyStreak', {
            consecutiveDaysLogged: props`consecutiveDaysLogged`,
          }),
        ],
        false: [],
      },
    ],
  },
];

export const removeDailyStats = [
  unset(state`challenge.stats.daily.user.${props`key`}`),
];

export const getTotalTeamsStats = [
  sequence('getTotalTeamsStats', [
    value(
      string`challenges.totalStats.teams.${state`challenge.key`}.${state`challenge.userCampaign.sectionKey`}`
    ),
    when(props`response.value`),
    {
      true: set(state`challenge.stats.total.teams`, props`response.value`),
      false: set(state`challenge.stats.total.teams`, {}),
    },
    set(state`challenge.isLoading.totalTeamsStats`, false),
  ]),
];

export const updateTotalChallengeStats = [
  set(state`challenge.stats.total.challenge`, props`value`),
];

export const updateTotalProfileStats = [
  set(state`challenge.stats.total.profiles.${props`key`}`, props`value`),
  actions.hasAchievedGoal,
  {
    true: [actions.setAchievedGoal, logSequences.getUserChallenge],
    false: [],
  },
];

export const updateTotalSectionStats = [
  set(state`challenge.stats.total.sections.${props`key`}`, props`value`),
];

export const updateTotalTeamStats = [
  set(state`challenge.stats.total.teams.${props`key`}`, props`value`),
];

export const getInsightsTranslations = [
  parallel('getInsightsTranslations', [
    sequence('getTotal', [
      when(
        state`challenge.stats.total.challenge.co2`,
        co2 => co2 && co2 > 10000000
      ),
      {
        true: [
          getTranslationByValue(
            state`challenge.stats.total.challenge.co2`,
            state`challenge.campaign.dataSource`
          ),
          set(
            state`challenge.visualisations.translates.insightsTotal`,
            props`result`
          ),
        ],
        false: [],
      },
    ]),
    sequence('getAverage', [
      when(
        state`challenge.stats.total.challenge.averageCo2`,
        averageCo2 => averageCo2 && averageCo2 >= 10000000
      ),
      {
        true: [
          getTranslationByValue(
            state`challenge.stats.total.challenge.averageCo2`,
            state`challenge.campaign.dataSource`
          ),
          set(
            state`challenge.visualisations.translates.insightsAverage`,
            props`result`
          ),
        ],
        false: [],
      },
    ]),
    sequence('getUser', [
      when(
        state`challenge.stats.total.profiles.${state`appContainer.user.uid`}.co2`,
        co2 => co2 && co2 >= 10000000
      ),
      {
        true: [
          getTranslationByValue(
            state`challenge.stats.total.profiles.${state`appContainer.user.uid`}.co2`,
            state`challenge.campaign.dataSource`
          ),
          set(
            state`challenge.visualisations.translates.insightsUser`,
            props`result`
          ),
        ],
        false: [],
      },
    ]),
    sequence('getSectionAverage', [
      when(
        state`challenge.stats.total.sections.${state`challenge.userCampaign.sectionKey`}.averageCo2`,
        averageCo2 => averageCo2 && averageCo2 >= 10000000
      ),
      {
        true: [
          getTranslationByValue(
            state`challenge.stats.total.sections.${state`challenge.userCampaign.sectionKey`}.averageCo2`,
            state`challenge.campaign.dataSource`
          ),
          set(
            state`challenge.visualisations.translates.insightsSectionAverage`,
            props`result`
          ),
        ],
        false: [],
      },
    ]),
  ]),
];

export const listenForStatUpdates = [
  parallel('listenForStatUpdates', [
    actions.listenForTotalChallengeStats,
    actions.listenForTotalProfileStats,
    actions.listenForTotalSectionStats,
    actions.listenForTotalTeamsStats,
  ]),
];
