import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';

function noTutorial(activityKey) {
  return !activityKey.startsWith('activity_tutorial');
}

export default Compute(get => {
  const activities = get(state.challenge.campaign.challenge.activities);
  const appActivities = get(state.appContainer.activities);
  const selectedTag = get(state.challenge.log.selectedTag);

  const apiFilteredActivities = Object.keys(activities || {}).filter(
    activityKey => appActivities[activityKey]
  );

  if (selectedTag === 'all') {
    return apiFilteredActivities.filter(noTutorial);
  }

  if (selectedTag === 'tutorial') {
    return apiFilteredActivities.filter(activityKey =>
      activityKey.startsWith('activity_tutorial')
    );
  }

  return apiFilteredActivities.filter(noTutorial).filter(activityKey => {
    const { sdgs = [], categories = [] } = appActivities[activityKey].tags;

    return sdgs.includes(selectedTag) || categories.includes(selectedTag);
  });
});
