import { primaryLanguage } from 'languages/config';

export function getUserCampaigns({ state, firebase }) {
  const uid = state.get('appContainer.user.uid');

  return firebase
    .value(`profiles.campaigns.${uid}`)
    .then(result => ({ userCampaigns: result.value || {} }));
}

export async function getCampaigns({ props, database }) {
  const { userCampaigns } = props;

  const campaigns = await database
    .getCampaigns(Object.keys(userCampaigns))
    .then(camps => camps || {});

  return { campaigns };
}

export function toggleShowTimelineSubItems({ state, props }) {
  const { campaignId } = props;
  const timelineShowing = state.get('account.timeline.subItemsShowing');

  if (timelineShowing === campaignId) {
    state.set('account.timeline.subItemsShowing', '');
  } else {
    state.set('account.timeline.subItemsShowing', campaignId);
  }
}

export function redirectToTool({ state, props, router, config }) {
  const { endpoint, key } = props;
  const locale = state.get(`appContainer.language.locale`);
  const path = `${config.get('appUrl')}${
    locale === primaryLanguage
      ? '/'
      : `/${state.get(`appContainer.language.locale`)}/`
  }${endpoint}/${key}`;

  router.goTo(path);
}

export function redirectToCampaignPage({ props }) {
  window.open(props.url, '_blank');
}

/**
 * For each campaign period (current, future, past), determine if there is a single campaign.
 * If this is the case, return the campaign key, otherwise return null.
 *
 * @returns {{current: *, future: *, past: *}} - a campaign key if there is a single campaign for the given period or NULL.
 */
function getSingleCampaignKeysPerPeriod(campaigns) {
  let currentSingleCampaign = null;
  let futureSingleCampaign = null;
  let pastSingleCampaign = null;

  Object.keys(campaigns).forEach(campaignKey => {
    const endDatetime =
      campaigns[campaignKey].config?.endDatetime ??
      campaigns[campaignKey].endDatetime;
    const startDatetime =
      campaigns[campaignKey].config?.startDatetime ??
      campaigns[campaignKey].startDatetime;
    if (endDatetime < Date.now()) {
      pastSingleCampaign = pastSingleCampaign === null ? campaignKey : false;
    } else if (startDatetime > Date.now()) {
      futureSingleCampaign =
        futureSingleCampaign === null ? campaignKey : false;
    } else {
      currentSingleCampaign =
        currentSingleCampaign === null ? campaignKey : false;
    }
  });

  return {
    current: currentSingleCampaign || null,
    future: futureSingleCampaign || null,
    past: pastSingleCampaign || null,
  };
}

/**
 * If there is a single campaign in one of the campaign period, we expand this campaign by default.
 * And if there are multiple campaigns for a campaign period, we don't expand any by default.
 *
 * Since only a single campaign can be expanded, we look through the campaign periods in this order:
 * - current
 * - future
 * - past
 *
 * @set account.timeline.subItemsShowing - campaign to expand
 */
export function setDefaultItemToShow({ state, props }) {
  const { campaigns } = props;
  const { current, future, past } = getSingleCampaignKeysPerPeriod(campaigns);

  state.set(
    'account.timeline.subItemsShowing',
    current || future || past || ''
  );
}
