import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import quiz from 'toolData/quiz';
import { QuizQuestion } from 'common/types';

export default Compute(get => {
  const tag = get(state.challenge.tagModal.key);
  const userQuiz = get(state.challenge.userChallenge.quiz);

  const questions = quiz[tag] as QuizQuestion[];

  if (!questions) {
    return {
      value: 0,
      max: 0,
      isStarted: false,
      isCompleted: false,
      questions: [],
    };
  }

  const scoredQuestions = questions.map(q => ({
    ...q,
    isAnswered: Boolean(userQuiz?.[q.question]?.answer),
    isCorrect: q.correctOption === userQuiz?.[q.question]?.answer,
  }));

  return {
    value: scoredQuestions.filter(q => q.isCorrect).length,
    max: questions.length,
    isStarted: scoredQuestions.some(q => q.isAnswered),
    isCompleted: scoredQuestions.every(q => q.isAnswered),
    questions: scoredQuestions,
  };
});
