import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';

import { OwnState } from './types';

const state: OwnState = {
  showIntroModal: false,
  showOutroModal: false,
  showExpandedIntro: false,
  length: 2,
  mobileLength: 3,
  show: 0,
  tutorialActivityLogged: false,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    beginTutorial: sequences.beginTutorial,
    nextPopUp: sequences.increasePopUpNumber,
    prevPopUp: sequences.decreasePopUpNumber,
    endTutorial: sequences.endTutorial,
    toggleLogTutorialActivity: sequences.toggleLogTutorialActivity,
    expandIntroModal: sequences.expandIntroModal,
    closeOutroModal: sequences.closeOutroModal,
  },
};

export default module;
