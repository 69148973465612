import { callDuckyAPI } from './helpers/callDuckyAPI';
import {
  CalculatorActivitiesResponse,
  activitiesProviderProps,
  activitiesProviderReturn,
} from './types';

export const activitiesProvider = ({
  firebaseUrl,
  duckyApiUrl,
}: activitiesProviderProps): activitiesProviderReturn => ({
  async getAllActivities({ dataSource, language, dataFromDate }) {
    const maybeDataFromDate = dataFromDate
      ? `&dataFromDate=${dataFromDate}`
      : '';
    const allActivities = await callDuckyAPI({
      endpoint: `/change?dataSource=${dataSource}${maybeDataFromDate}&language=${language}&returnLegacyTipId=true&ignoreZeroOrNegativeTips=false`,
      firebaseUrl,
      duckyApiUrl,
    });

    const formattedActivities: CalculatorActivitiesResponse =
      allActivities?.activities.reduce(
        (accumulatedActivities, currentActivity) => ({
          ...accumulatedActivities,
          [currentActivity.legacyTipId]: {
            title: currentActivity.title,
            description: currentActivity.description,
            category: currentActivity.module,
            icon: currentActivity.imageUrls,
            indicators: Object.entries(currentActivity.indicators).reduce(
              (
                accumulatedIndicators,
                [currentIndicatorKey, currentIndicatorValues]
              ) => ({
                ...accumulatedIndicators,
                [currentIndicatorKey]: currentIndicatorValues.value,
              }),
              {}
            ),
            subCategory: currentActivity.subCategory,
            group: currentActivity.group,
            groupOverrides: currentActivity.groupOverrides,
            tags: Object.entries(currentActivity.tags).reduce(
              (accumulatedTags, [currentTagKey, currentTagValues]) => ({
                ...accumulatedTags,
                [currentTagKey]: currentTagValues.reduce(
                  (accumulatedTagValues, currentTagValue) => {
                    const tag = currentTagValue.id.includes('SDG')
                      ? currentTagValue.id.substring(3)
                      : currentTagValue.id;

                    return [...accumulatedTagValues, tag];
                  },
                  []
                ),
              }),
              {}
            ),
          },
        }),
        {}
      );

    return formattedActivities ?? {};
  },
  async getActivity({ dataSource, activityKey, language, dataFromDate }) {
    const allActivities: CalculatorActivitiesResponse =
      await this.getAllActivities({
        dataSource,
        language,
        dataFromDate,
      });

    return allActivities[activityKey];
  },
});
