import { Provider } from 'cerebral';
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  onAuthStateChanged,
  isSignInWithEmailLink,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { stopListening } from './src/helpers';
import signInAnonymouslyService from './src/signInAnonymously';
import getUserIDToken from './src/getUserIDToken';
import onChildAdded from './src/onChildAdded';
import onChildRemoved from './src/onChildRemoved';
import onChildChanged from './src/onChildChanged';
import onValue from './src/onValue';
import createTask from './src/createTask';
import createHeavyTask from './src/createHeavyTask';
import value from './src/value';
import getDownloadURL from './src/getDownloadURL';
import updatePassword from './src/updatePassword';
import createUserWithEmailAndPassword from './src/createUserWithEmailAndPassword';
import signInWithEmailAndPassword from './src/signInWithEmailAndPassword';
import signInWithEmailLink from './src/signInWithEmailLink';
import signOutService from './src/signOut';
import signInWithFacebook from './src/signInWithFacebook';
import signInWithGoogle from './src/signInWithGoogle';
import signInWithCustomToken from './src/signInWithCustomToken';
import deleteUser from './src/deleteUser';
import getCallableFunction from './src/getCallableFunction';
import uploadAvatarImage from './src/uploadAvatarImage';
import uploadAdminImage from './src/uploadAdminImage';

export { FirebaseProviderError } from './src/errors';
export { FirebaseProviderAuthenticationError } from './src/errors';

export default function FirebaseProviderFactory({ app }) {
  const auth = getAuth(app);
  const functions = getFunctions(app, process.env.CONFIG.cloudFunctionRegion);
  const storage = getStorage(app);
  const database = getDatabase(app);

  return new Provider(
    {
      getCurrentUser: () => auth.currentUser,
      onAuthStateChanged: (onChange, onError, onListenerRemoved) =>
        onAuthStateChanged(auth, onChange, onError, onListenerRemoved),
      messagingGetToken: options =>
        import('firebase/messaging').then(({ getMessaging, getToken }) =>
          getToken(getMessaging(app), options).catch(() => {
            // Ignore error when messaging was rejected by the user
          })
        ),
      messagingIsSupported: () => {
        if (typeof navigator.serviceWorker === 'undefined') {
          return Promise.resolve(false);
        }

        return import('firebase/messaging').then(({ isSupported }) =>
          isSupported()
        );
      },
      applyActionCode: code => applyActionCode(auth, code),
      getCallableFunction: getCallableFunction(functions),
      verifyPasswordResetCode: code => verifyPasswordResetCode(auth, code),
      confirmPasswordReset: (code, password) =>
        confirmPasswordReset(auth, code, password),
      createUserWithEmailAndPassword: (email, password) =>
        createUserWithEmailAndPassword(auth, email, password),
      deleteUser: () => deleteUser(auth),
      getDownloadURL: getDownloadURL(storage),
      getUserIDToken: () => getUserIDToken(auth),
      off: stopListening,
      onChildAdded: onChildAdded(database),
      onChildChanged: onChildChanged(database),
      onChildRemoved: onChildRemoved(database),
      onValue: onValue(database),
      isSignInWithEmailLink: link => isSignInWithEmailLink(auth, link),
      signInWithEmailLink: (email, link) =>
        signInWithEmailLink(auth, email, link),
      signInAnonymously: () => signInAnonymouslyService(auth),
      signInWithCustomToken: token => signInWithCustomToken(auth, token),
      signInWithEmailAndPassword: (email, password) =>
        signInWithEmailAndPassword(auth, email, password),
      signInWithFacebook: options => signInWithFacebook(auth, options),
      signInWithGoogle: options => signInWithGoogle(auth, options),
      signOut: () => signOutService(auth),
      task: createTask(functions),
      taskHeavy: createHeavyTask(functions),
      value: value(database),
      updatePassword: (currentPassword, newPassword) =>
        updatePassword(auth, currentPassword, newPassword),
      uploadAvatarImage: uploadAvatarImage(auth),
      uploadAdminImage: uploadAdminImage(auth),
    },
    { cache: true }
  );
}
