declare global {
  interface Window {
    _paq?: {
      push(data: (number | string)[]): void;
    };
  }
}

type Event = {
  category: string;
  action: string;
  name?: string;
  value?: string | number;
};

export const trackingProvider = {
  trackEvent(event: Event): void {
    const data = ['trackEvent', event.category, event.action];

    if (event.name !== undefined && event.value !== undefined) {
      return window._paq?.push([...data, event.name, event.value]);
    }

    if (event.name !== undefined) {
      return window._paq?.push([...data, event.name]);
    }

    return window._paq?.push(data);
  },
};
