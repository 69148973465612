import { Route } from 'common/types';

const routes: Route[] = [
  {
    path: '/:challengeKey',
    name: 'root',
    signal: 'challenge.rendered',
  },
];

export default routes;
