import { set, when } from 'cerebral/factories';
import { signOut as firebaseSignOut } from 'providers/Firebase/src/operators';
import { setField } from 'forms/operators';
import { state, props } from 'cerebral';
import * as actions from './actions';
import * as authActions from './modules/auth/actions';
import * as factories from './factories';

export const closeModal = [
  when(state`app.isMobile`),
  {
    true: [],
    false: [set(state`appContainer.shouldResetScroll`, false)],
  },
  set(state`appContainer.auth.authenticationError`, null),
  actions.clearUrl,
];

export const selectLanguage = [
  when(
    state`appContainer.user.uid`,
    state`appContainer.profiles.${state`appContainer.user.uid`}.language`,
    props`language`,
    (uid, language, newLanguage) => uid && language !== newLanguage
  ),
  {
    true: [actions.setProfileLanguage],
    false: [],
  },
  actions.redirectToLanguage,
];

export const goTo = [actions.goTo];

export const goToProfile = [actions.setReturnUrl, actions.goTo];

export const unload = [set(state`appContainer.isUnloading`, true)];

export const changeField = setField(state`${props`field`}`, props`value`);

export const openMobileMenu = [
  when(
    state`app.page`,
    page =>
      page !== 'profileInfo' &&
      page !== 'profileDelete' &&
      page !== 'profileLanguage' &&
      page !== 'profilePreferences' &&
      page !== 'profilePassword' &&
      page !== 'profileTerms' &&
      page !== 'profileSupport'
  ),
  {
    true: [actions.setReturnUrl],
    false: [],
  },
  actions.goTo,
];

export const closeMobileMenu = [
  actions.getReturnUrl,
  authActions.redirectToUrl,
];

export const openTermsModal = [factories.setUrlAction('terms')];

export const closePopupsAndModals = [
  set(state`appContainer.showNavProfileMenu`, null),
  set(state`appContainer.showNavLanguageMenu`, null),
];

export const { toggleNavItemMenu } = actions;

export const declineTerms = [
  set(state`appContainer.termsScroll`, 0),
  when(state`appContainer.isAcceptingTerms`),
  {
    true: [
      set(state`appContainer.showTermsModal`, false),
      set(state`appContainer.auth.registerForm.termsAccepted.value`, false),
      set(
        state`appContainer.auth.authenticationError.message`,
        'authentication.errors.declined_terms'
      ),
      actions.deleteProfile,
      firebaseSignOut(),
      authActions.redirectToRegister,
      set(state`appContainer.user`, null),
      set(state`appContainer.profiles`, {}),
    ],
    false: [
      set(state`appContainer.auth.authenticationError`, null),
      set(state`appContainer.auth.registerForm.termsAccepted.value`, false),
      actions.clearUrl,
    ],
  },
];

export const acceptTerms = [
  set(state`appContainer.termsScroll`, 0),
  when(state`appContainer.isAcceptingTerms`),
  {
    true: [
      set(state`appContainer.auth.registerForm.termsAccepted.value`, true),
      set(state`appContainer.showTermsModal`, false),
      actions.updateTermsAgreement,
      actions.clearUrl,
    ],
    false: [
      set(state`appContainer.auth.authenticationError`, null),
      set(state`appContainer.auth.registerForm.termsAccepted.value`, true),
      actions.clearUrl,
    ],
  },
];

export const closeAllModals = [
  set(state`appContainer.auth.authenticationError`, null),
  when(state`challenge`),
  {
    true: [
      set(state`challenge.activityModal.showActivityModal`, false),
      set(state`challenge.log.tagModal.show`, false),
      set(state`challenge.info.showIndicatorModal`, false),
      set(state`challenge.info.showStreakModal`, false),
      set(state`challenge.info.showGoalModal`, false),
    ],
    false: [],
  },
  when(state`account`),
  {
    true: [set(state`account.showDeleteAccountModal`, false)],
    false: [],
  },
];

export const signOut = [
  actions.stopAllDatabaseListeners,
  firebaseSignOut(),
  actions.refreshPage,
];

export const startServiceWorker = [actions.startServiceWorker];

export const openTermsConfirmed = [
  set(state`appContainer.showConfirmDeletion`, true),
];

export const termsGoBackClicked = [
  set(state`appContainer.showConfirmDeletion`, false),
];
