import { Provider } from 'cerebral';

export default () =>
  Provider({
    get(key) {
      if (key) {
        return this.context.controller.getState(`appContainer.config.${key}`);
      }

      return this.context.controller.getState(`appContainer.config`);
    },
  });
