import account from 'account/routes';
import challenge from 'challenge/routes';

import { Route, AugmentedRoute } from 'common/types';

const addBase = (base: string, noPath?: boolean) => (route: Route) => ({
  ...route,
  ...{ path: noPath ? route.path : `/${base}${route.path}` },
  base,
});

const mergedRoutes: AugmentedRoute[] = [
  ...account.map(addBase('account', true)),
  ...challenge.map(addBase('challenge')),
];

export default mergedRoutes;
