import { state } from 'cerebral';

export function increasePopUpNumber({ get, store, props }) {
  const currentPopUp = get(state`challenge.tutorial.showTutorial`);
  const mobile = get(state`app.isMobile`);
  const { popUpNumber } = props;

  let tutorialLength = get(state`challenge.tutorial.tutorialLength`);

  if (mobile) {
    tutorialLength = get(state`challenge.tutorial.mobileLength`);
  }
  if (currentPopUp !== tutorialLength) {
    store.set(
      state`challenge.tutorial.show`,
      popUpNumber ? popUpNumber : get(state`challenge.tutorial.show`) + 1
    );
  }
}

export function decreasePopUpNumber({ get, store }) {
  const currentPopUp = get(state`challenge.tutorial.show`);

  if (currentPopUp !== 1) {
    store.set(
      state`challenge.tutorial.show`,
      get(state`challenge.tutorial.show`) - 1
    );
  }
}

export function checkPopUpNumber({ get, store }) {
  const showTutorial = get(state`challenge.tutorial.show`);
  const mobile = get(state`app.isMobile`);

  switch (showTutorial) {
    case 1:
      break;
    case 2:
      store.set(state`challenge.tutorial.tutorialActivityLogged`, !mobile);
      break;
    case 3:
      store.set(state`challenge.tutorial.tutorialActivityLogged`, true);
      break;
    case 0:
    default:
      store.set(state`challenge.currentTab`, 0);
      break;
  }
}

export function scrollTo({ props }) {
  for (
    let scroll = window.pageYOffset;
    scroll < props.offset - 9;
    scroll += 10
  ) {
    setTimeout(() => window.scrollTo(0, scroll), Number(scroll));
  }
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
