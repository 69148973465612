import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { sum, getIndicator } from 'utils/common';
import { getAvatarUrl, getDefaultAvatarColor } from 'utils/avatars';
import { INDICATOR } from 'resources/constants';
import { orderAndFormatLeaderboard } from 'utils/challenge';

export default Compute(get => {
  const staticUrl = get(state.appContainer.config.staticUrl);
  const userCampaign = get(state.challenge.userCampaign);
  const leaderboardPage = get(state.challenge.visualisations.leaderboardPage);
  const leaderboardPageSize = get(
    state.challenge.visualisations.leaderboardPageSize
  );
  const leaderboardIsExpanded = get(
    state.challenge.visualisations.toplistExpanded
  );

  if (!userCampaign)
    return orderAndFormatLeaderboard(
      [],
      leaderboardPage,
      leaderboardPageSize,
      leaderboardIsExpanded
    );

  const teams =
    get(
      state.challenge.campaign.structure.sections[userCampaign.sectionKey].teams
    ) || {};
  const teamScores = get(state.challenge.stats.total.teams) || {};
  const indicator = get(state.challenge.campaign.indicator) || INDICATOR.co2;

  const leaderboard = Object.keys(teams).map(teamKey => ({
    key: teamKey,
    isEmphasised: teamKey === userCampaign.teamKey,
    name: teams[teamKey].name,
    avatar: getAvatarUrl(staticUrl, { name: teams[teamKey].name }).small,
    avatarLarge: getAvatarUrl(staticUrl, { name: teams[teamKey].name }).large,
    avatarColor: getDefaultAvatarColor(teams[teamKey].name),
    activityCount: sum(teamScores?.[teamKey]?.activitiesCount || {}),
    value: getIndicator(teamScores?.[teamKey], indicator, 'average'),
  }));

  return orderAndFormatLeaderboard(
    leaderboard,
    leaderboardPage,
    leaderboardPageSize,
    leaderboardIsExpanded
  );
});
