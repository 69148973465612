import { ModuleDefinition } from 'cerebral';
import * as actions from './actions';
import * as sequences from './sequences';
import computedTags from 'challenge/computed/tags';
import computedActivityKeys from 'challenge/computed/activityKeys';
import computedMostPopularActivities from 'challenge/computed/mostPopularActivities';
import computedLoggedHabitActivityKeys from 'challenge/computed/loggedHabitActivityKeys';
import computedCanLog from 'challenge/computed/canLog';

import { OwnState } from './types';

const state: OwnState = {
  showAllPopularActivities: false,
  showHabitActivities: false,
  showEnergyActivities: false,
  showTransportActivities: false,
  showFoodActivities: false,
  showConsumptionActivities: false,
  showPopularActivities: false,
  selectedTag: 'all',
  selectedDayIndex: -1,
  previousActivityModalKey: null,
  activityKeysLogging: {},
  activitySubCategoryLogging: {},
  computedTags,
  computedActivityKeys,
  computedMostPopularActivities,
  computedLoggedHabitActivityKeys,
  computedCanLog,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    toggleShowActivities: sequences.toggleShowActivities,
    setShowAllPopularActivities: sequences.setShowAllPopularActivities,
    nextDayArrowClicked: actions.increaseSelectedDayIndex,
    previousDayArrowClicked: sequences.decreaseSelectedDayIndex,
    tagClicked: sequences.tagSelected,
    removeSelectedTag: sequences.removeSelectedTag,
    activityInfoClicked: sequences.setActivityModal,
    toggleLogActivityClicked: sequences.toggleActivity,
    toggleLogAllHabitsClicked: sequences.toggleLogAllHabits,
    toggleHabitClicked: sequences.toggleHabit,
    toggleShowHabitActivities: sequences.toggleShowHabitActivities,
    toggleShowPopularActivities: sequences.toggleShowPopularActivities,
  },
};

export default module;
