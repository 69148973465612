import { Context, state } from 'app/pwa/app.cerebral';
import { TRACKING_CATEGORY } from 'resources/constants';
import quiz from 'toolData/quiz';

type SaveQuizAnswerProps = {
  category: string;
  question: string;
  answer: string;
};

export function saveQuizAnswer({
  props,
  get,
  firebase,
}: Context<SaveQuizAnswerProps>): Promise<never> {
  const { category, question, answer } = props;
  const challengeKey = get(state.challenge.key);

  return firebase.task('submit_quiz_answer', {
    challengeKey,
    category,
    question,
    answer,
  });
}

export function setQuizState({ store, get }: Context): void {
  const tag = get(state.challenge.tagModal.key);
  const answers = get(state.challenge.userChallenge.quiz);

  const questionKeys = quiz[tag]?.map(q => q.question);
  const uncompletedIndex = questionKeys.findIndex(q => !answers?.[q]);

  if (uncompletedIndex === -1) {
    store.set(state.challenge.tagModal.quizPageIndex, 0);
  } else {
    store.set(state.challenge.tagModal.quizPageIndex, uncompletedIndex + 1);
  }

  store.set(state.challenge.tagModal.quizQuestionKeys, questionKeys);
}

export function incrementQuizIndex({ store, get }: Context): void {
  const tag = get(state.challenge.tagModal.key);
  const quizPageIndex = get(state.challenge.tagModal.quizPageIndex);
  const questionCount = quiz[tag]?.length;

  if (quizPageIndex === questionCount) {
    store.set(state.challenge.tagModal.quizPageIndex, 0);
  } else {
    store.set(state.challenge.tagModal.quizPageIndex, quizPageIndex + 1);
  }
}

export function getQuizStats({
  firebase,
  get,
}: Context): Promise<{ quizStats: { [key: string]: number } }> {
  const challengeKey = get(state.challenge.key);
  const tag = get(state.challenge.tagModal.key);

  return firebase
    .value(`challenges.quiz.${challengeKey}.scores.${tag}`)
    .then(response => ({
      // Firebase sometimes returns an array, if the keys are 0,1,2... This converts it to an object
      // https://firebase.googleblog.com/2014/04/best-practices-arrays-in-firebase.html
      quizStats: Object.fromEntries(Object.entries(response.value || {})),
    }));
}

export function trackSecondaryTagModalOpened({
  props,
  tracking,
}: Context<{ contentTag: string }>): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Open Secondary Tag Modal',
    name: props.contentTag,
  });
}

export function trackTagModalOpened({
  props,
  tracking,
}: Context<{ tag: string }>): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Open Tag Modal',
    name: props.tag,
  });
}

export function resetScroll(): void {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
