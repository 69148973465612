import { Provider } from 'function-tree';
import page from 'page';

function RouterProvider() {
  return new Provider({
    goTo(url) {
      return page(url);
    },
  });
}

export default RouterProvider;
