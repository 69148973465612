import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { CHALLENGE_EVENT_TYPE } from 'resources/constants';
import { EventWithKey } from 'challenge/module/modules/events/types';

export default Compute(get => {
  const events = get(state.challenge.events.events);
  const uid = get(state.appContainer.user.uid);
  const lastSeenDatetime =
    get(state.challenge.userChallenge.eventsLastSeen) || 0;

  const oldEvents: EventWithKey[] = [];
  const newEvents: EventWithKey[] = [];

  Object.entries(events || {})
    .filter(([, event]) => event)
    .filter(([, event]) => {
      if (event.type !== CHALLENGE_EVENT_TYPE.DAILY_SUMMARY_UPDATES) {
        return true;
      }

      // Remove activity events if they contain no events
      return Object.keys(event.activitiesCount || {}).length > 0;
    })
    .filter(([, event]) => {
      if (event.type !== CHALLENGE_EVENT_TYPE.QUIZ_COMPLETED) {
        return true;
      }

      // Remove your own quiz completed events when you scored 0 or 1 on the quiz
      return !(event.profileKey === uid && event.score < 2);
    })
    .filter(([, event]) => {
      if (event.type !== CHALLENGE_EVENT_TYPE.GOALS_SET) {
        return true;
      }

      // Remove your own goal set event
      return !(event.profileKey === uid);
    })
    .sort((eventA, eventB) => eventB[1].datetime - eventA[1].datetime)
    .forEach(([eventKey, event]) => {
      if (event.datetime > lastSeenDatetime) {
        newEvents.push({ key: eventKey, ...event });
      } else {
        oldEvents.push({ key: eventKey, ...event });
      }
    });

  return {
    newEvents,
    oldEvents,
  };
});
