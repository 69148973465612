import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

export default function getCallableFunctionFactory(functions) {
  return function getCallableFunctionFunction(name, options) {
    if (process.env.NODE_ENV !== 'production') {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }

    return httpsCallable(functions, name, options);
  };
}
