import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import { OwnState } from './types';
import auth from './modules/auth';
import { primaryLanguage } from 'languages/config';

const TERMS_UPDATED_DATETIME = new Date('2022-03-01').getTime();

const state: OwnState = {
  isUnloading: false,
  currentPage: null,
  isServer: true,
  error: null,
  language: {
    locale: '',
    fullLocale: '',
    format: {},
  },
  primaryLanguage: primaryLanguage,
  showHomePageOverlay: false,
  showTermsModal: false,
  termsUpdatedDatetime: TERMS_UPDATED_DATETIME,
  showConfirmDeletion: false,
  scrollTop: 0,
  profiles: {},
  activities: {},
  snackbar: {
    message: null,
    show: false,
  },
  config: {
    cloudFunctionUrl: '',
    cloudFunctionRegion: '',
    widgetUrl: '',
    appUrl: '',
    domain: '',
    staticUrl: '',
    translateImagePath: '',
    DUCKY_ENV: '',
    matomoTrackingUrl: '',
    matomoSiteId: -1,
  },
  showNavLanguageMenu: null,
  shouldResetScroll: false,
  hasRendered: false,
  online: true,
  user: null,
};

const module: ModuleDefinition = {
  state,
  modules: {
    auth,
  },
  sequences: {
    startServiceWorker: sequences.startServiceWorker,
    clicked: sequences.closePopupsAndModals,
    fieldChanged: sequences.changeField,
    termsClicked: sequences.openTermsModal,
    closeModalClicked: sequences.closeModal,
    logoutClicked: sequences.signOut,
    profileClicked: sequences.goToProfile,
    showNavItemMenuClicked: sequences.toggleNavItemMenu,
    goTo: sequences.goTo,
    termsDeclinedClicked: sequences.declineTerms,
    termsAcceptedClicked: sequences.acceptTerms,
    languageSelected: sequences.selectLanguage,
    mobileMenuClick: sequences.openMobileMenu,
    closeMobileMenuClick: sequences.closeMobileMenu,
    unloading: sequences.unload,
    openTermsConfirmed: sequences.openTermsConfirmed,
    termsGoBackClicked: sequences.termsGoBackClicked,
  },
};

export default module;
