export function deleteAccount({ firebase, props }) {
  return firebase.task('delete_account', {
    feedback: props.feedback,
  });
}

export function redirectToChallengeLandingPage({ router }) {
  router.goTo('/');
}

export function redirectToUrl({ props, router }) {
  const { redirectUrl } = props;

  router.goTo(redirectUrl);
}

export function changeChallengeEmailSetting({ props, firebase }) {
  return firebase.task('update_profile_emails', {
    emails: { challengeEmails: { consent: props.value, datetime: Date.now() } },
  });
}

export function saveProfile({ props, state, firebase }) {
  const firstName = state.get('account.profileForm.firstName.value');
  const lastName = state.get('account.profileForm.lastName.value');
  const avatarImage = state.get('account.profileForm.avatarImage.value');

  return firebase.task('update_profile', {
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    avatarImage: avatarImage || null,
    avatarImagePathsToRemove: props.avatarImagePathsToRemove || null,
  });
}

export function uploadAvatar({ props, path, firebase }) {
  return firebase
    .uploadAvatarImage(props.file)
    .then(path.success)
    .catch(path.error);
}

export function storeQueryParams({ props, state }) {
  props.oobCode
    ? state.set('account.oobCode', props.oobCode)
    : state.set('account.oobCode', 'null');
  props.mode
    ? state.set('account.mode', props.mode)
    : state.set('account.mode', 'null');
}

export function verifyCode({ state, path, firebase }) {
  const oobCode = state.get('account.oobCode');

  return firebase
    .verifyPasswordResetCode(oobCode)
    .then(email => path.valid({ email }))
    .catch(error => path.invalid({ error }));
}

export function resetPassword({ state, firebase, path }) {
  const oobCode = state.get('account.oobCode');
  const password = state.get('account.resetPasswordForm.password.value');

  return firebase
    .confirmPasswordReset(oobCode, password)
    .then(() => path.success())
    .catch(error => path.error({ error }));
}

export function createNewPassword({ state, firebase, path }) {
  const newPasswordForm = state.get('account.newPasswordForm');
  const { oldPassword, newPassword } = newPasswordForm;

  return firebase
    .updatePassword(oldPassword.value, newPassword.value)
    .then(() => path.success())
    .catch(error => path.error({ error }));
}

export function promptError({ state, props }) {
  const { error } = props;

  state.set('account.oobError', error.code);
}

export function getSnackbarMessage({ storage }) {
  const snackbarMessage = storage.get('snackbarMessage');

  return { snackbarMessage };
}

export function setAccountDeletedSnackbarMessage({ storage }) {
  storage.set('snackbarMessage', 'accountDeleted');
}

export function deleteAccountDeletedSnackbarMessage({ storage }) {
  storage.remove('snackbarMessage');
}

export function setQueryParams({ state }) {
  const path = state.get('app.path');

  const paramsString = path.split('?')[1];
  if (!paramsString) return state.set('app.params', {});

  const paramsArray = paramsString.split('&');
  const paramsObject = Object.fromEntries(
    paramsArray.map(param => param.split('='))
  );

  return state.set('app.params', paramsObject);
}

export function fillForm({ state }) {
  const email = state.get('appContainer.auth.logInForm.email.value');

  state.set('appContainer.auth.forgotPasswordForm.email.value', email);
}

export function verifyEmail({ state, firebase, path }) {
  const oobCode = state.get('account.oobCode');

  return firebase
    .applyActionCode(oobCode)
    .then(() => path.success())
    .catch(error => path.error({ error }));
}

export function setRedirectUrl({ props, storage }) {
  storage.set('redirectUrl', props.redirectUrl);
}

export function hasConfirmedEmail({ state, path }) {
  const hasConfirmed = state.get('appContainer.user.emailVerified');

  return hasConfirmed ? path.true() : path.false();
}

export function waitForConfirmation({ firebase, app }) {
  const interval = setInterval(() => {
    const currentUser = firebase.getCurrentUser();

    if (!currentUser) {
      // If not logged in, redirect to wherever and then to login
      clearInterval(interval);
      app.getSequence('account.continueFromConfirmEmail')();

      return;
    }

    currentUser.reload().then(() => {
      const currentUserAfterReload = firebase.getCurrentUser();
      if (
        !currentUserAfterReload || // If not logged in, redirect to wherever and then to login
        currentUserAfterReload.emailVerified // Once verified, redirect
      ) {
        clearInterval(interval);
        app.getSequence('account.continueFromConfirmEmail')();
      }
    });
  }, 5000);
}

export function setReturnUrl({ storage, state }) {
  const returnUrl = storage.get('returnUrl');

  state.set('account.returnUrl', returnUrl);
}

export function stopAllDatabaseListeners({ firebase }) {
  try {
    return firebase.off('*');
  } catch {
    // If no listeners and it throws, it's all gravy
    return {};
  }
}

export function reloadUser({ firebase }) {
  return firebase.getCurrentUser()?.reload();
}
