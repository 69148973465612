export const enum CATEGORY {
  consumption = 'consumption',
  energy = 'energy',
  food = 'food',
  foodWaste = 'foodWaste',
  household = 'household',
  plastic = 'plastic',
  public = 'public',
  sustainableFood = 'sustainableFood',
  transport = 'transport',
  tutorial = 'tutorial',
  workTransport = 'workTransport',
  nature = 'nature',
  treeGroup = 'treeGroup',
}
