import merge from 'lodash.merge';
import parseUserAgent from './parseUserAgent';
import getMedia from './getMedia';
import getWindowSpec from './getWindowSpec';

import createOfflineChanged from './signalFactories/offlineChanged';
import createWindowChanged from './signalFactories/windowChanged';

const defaultOptions = {
  feature: true,
  parse: {
    browser: true,
    device: true,
  },
  media: {},
  window: true,
};

export default (userOptions = {}) => {
  const options = merge({}, defaultOptions, userOptions);
  const state = parseUserAgent(options);

  state.media = getMedia(options);
  state.window = getWindowSpec();
  state.network = { offline: !navigator.onLine };

  return ({ app, path }) => {
    app.on('initialized', () => {
      const windowChanged = app.getSequence(`${path}.windowChanged`);
      const offlineChanged = app.getSequence(`${path}.offlineChanged`);

      if (options.window !== false) {
        let updatingSpecs = false;

        window.addEventListener('resize', () => {
          if (updatingSpecs) return;
          updatingSpecs = true;
          requestAnimationFrame(() => {
            windowChanged({
              windowSpec: getWindowSpec(),
              media: getMedia(options),
            });
            updatingSpecs = false;
          });
        });
      }

      if (options.offline !== false) {
        window.addEventListener('online', () => {
          app.getState(`${path}.network.offline`) &&
            offlineChanged({ offline: false });
        });
        window.addEventListener('offline', () => {
          !app.getState(`${path}.network.offline`) &&
            offlineChanged({ offline: true });
        });
      }
    });

    return {
      state,
      sequences: {
        offlineChanged: createOfflineChanged(path),
        windowChanged: createWindowChanged(path),
      },
    };
  };
};
