import { signInWithEmailLink } from 'firebase/auth';
import { FirebaseProviderAuthenticationError } from './errors';

export default function signInWithEmailLinkFactory(auth, email, url) {
  return signInWithEmailLink(auth, email, url)
    .then(res => {
      window.localStorage.removeItem('emailForSignIn');

      return res;
    })
    .catch(error => {
      throw new FirebaseProviderAuthenticationError(error);
    });
}
