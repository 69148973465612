/* eslint-disable max-lines, no-shadow */
import { state, props } from 'cerebral';
import { set, toggle, when } from 'cerebral/factories';
import { setUrlAction } from 'common/appContainer/module/factories';
import { clearUrl } from 'common/appContainer/module/actions.js';
import * as logSequences from 'challenge/module/modules/log/sequences';
import { GOAL_STATUS } from 'resources/constants';

import * as actions from './actions';

export const toggleMoreInfo = toggle(state`challenge.info.moreInfoExpanded`);

export const setIndicatorModalUrl = [
  when(state`challenge.info.showIndicatorModal`),
  {
    true: [clearUrl],
    false: [
      set(state`appContainer.shouldResetScroll`, false),
      setUrlAction(`indicatorModal`),
    ],
  },
];

export const setStreakModalUrl = [
  when(state`challenge.info.showStreakModal`),
  {
    true: [clearUrl],
    false: [
      set(state`appContainer.shouldResetScroll`, false),
      setUrlAction(`streakModal`),
    ],
  },
];

export const setGoalModalUrl = [
  when(state`challenge.info.showGoalModal`),
  {
    true: [clearUrl],
    false: [
      set(state`appContainer.shouldResetScroll`, false),
      setUrlAction(`goalModal`),
    ],
  },
];

export const setLeaderboardModalUrl = [
  when(state`challenge.info.showLeaderboardModal`),
  {
    true: [clearUrl],
    false: [
      set(state`appContainer.shouldResetScroll`, false),
      setUrlAction(`leaderboardModal`),
    ],
  },
];

export const toggleTeamProfileList = toggle(
  state`challenge.info.isTeamProfilesListExpanded`
);

export const hideAddToHomeScreen = [
  set(state`challenge.info.showAddToHomeScreen`, false),
  actions.hideAddToHomeScreenLocalStorage,
];

export const saveGoal = [
  when(props`status`, status => status === GOAL_STATUS.CURRENT),
  {
    false: [actions.saveGoal, logSequences.getUserChallenge, clearUrl],
    true: [actions.updateGoal, logSequences.getUserChallenge, clearUrl],
  },
];

export const closeGoalRecognition = [
  actions.setAchievedRecognitionClosed,
  set(
    state`challenge.userChallenge.goals.${props`goalKey`}.hasClosedAchievedRecognition`,
    true
  ),
];

export const setNpsScore = [
  set(state`challenge.info.isSendingFeedback`, true),
  actions.setNpsScore,
  set(state`challenge.info.feedbackSent`, true),
  logSequences.getUserChallenge,
  set(state`challenge.info.isSendingFeedback`, false),
];

export const npsScoreChanged = [
  set(state`challenge.info.npsForm.score`, props`score`),
  set(state`challenge.info.showFeedbackTextArea`, true),
];

export const npsCommentChanged = [
  set(state`challenge.info.npsForm.comment`, props`comment`),
];

export const hideFeedbackTextArea = [
  set(state`challenge.info.showFeedbackTextArea`, false),
];

export const dismissNps = [
  when(state`challenge.info.feedbackSent`),
  {
    true: [set(state`challenge.info.feedbackSent`, false)],
    false: [actions.dismissNps, logSequences.getUserChallenge],
  },
  set(state`challenge.info.npsForm.score`, null),
  set(state`challenge.info.showFeedbackTextArea`, false),
  set(state`challenge.info.showNpsModal`, false),
];

export const setSeenApiInfo = [
  set(state`challenge.info.seenApiInfo`, true),
  actions.setSeenApiInfo,
  logSequences.getUserChallenge,
];
