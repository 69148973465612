import { TAG_CATEGORY, QUIZ_TAG_CATEGORY } from '../constants';
import { QuizQuestion } from 'common/types';

type QuizData = {
  [key in QUIZ_TAG_CATEGORY]: QuizQuestion[];
};

const quizData: QuizData = {
  [TAG_CATEGORY.consumption]: [
    {
      question: 'buying_something',
      correctOption: 'true',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
    {
      question: 'buying_clothing',
      correctOption: '100',
      options: [
        { option: '25', text: 'buying_clothing.options.25' },
        { option: '50', text: 'buying_clothing.options.50' },
        { option: '100', text: 'buying_clothing.options.100' },
      ],
    },
    {
      question: 'e_waste',
      correctOption: 'false',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
  ],
  [TAG_CATEGORY.energy]: [
    {
      question: 'energy_greenhouse',
      correctOption: 'decrease',
      options: [
        { option: 'decrease', text: 'energy_greenhouse.options.decrease' },
        { option: 'increase', text: 'energy_greenhouse.options.increase' },
      ],
    },
    {
      question: 'heating_cooling',
      correctOption: 'true',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
    {
      question: 'fossil_fuel_percentage',
      correctOption: '80',
      options: [
        { option: '30', text: 'fossil_fuel_percentage.options.30' },
        { option: '50', text: 'fossil_fuel_percentage.options.50' },
        { option: '80', text: 'fossil_fuel_percentage.options.80' },
      ],
    },
  ],
  [TAG_CATEGORY.transport]: [
    {
      question: 'convenient_public',
      correctOption: '50',
      options: [
        { option: '30', text: 'convenient_public.options.30' },
        { option: '50', text: 'convenient_public.options.50' },
        { option: '70', text: 'convenient_public.options.70' },
      ],
    },
    {
      question: 'long_distance_train',
      correctOption: 'true',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
    {
      question: 'bike_infrastructure',
      correctOption: 'false',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
  ],
  [TAG_CATEGORY.food]: [
    {
      question: 'food_waste_percentage',
      correctOption: '17',
      options: [
        { option: '4', text: 'food_waste_percentage.options.4' },
        { option: '17', text: 'food_waste_percentage.options.17' },
        { option: '36', text: 'food_waste_percentage.options.36' },
      ],
    },
    {
      question: 'half_agriculture',
      correctOption: 'true',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
    {
      question: 'sea_food_transport',
      correctOption: 'true',
      options: [
        { option: 'true', text: 'common.true' },
        { option: 'false', text: 'common.false' },
      ],
    },
  ],
  [TAG_CATEGORY.microplastics]: [
    {
      question: 'plastic_recycling_percentage',
      correctOption: '9',
      options: [
        { option: '9', text: 'plastic_recycling_percentage.options.9' },
        { option: '29', text: 'plastic_recycling_percentage.options.29' },
        { option: '49', text: 'plastic_recycling_percentage.options.49' },
      ],
    },
    {
      question: 'million_plastic_bottles',
      correctOption: 'minute',
      options: [
        { option: 'minute', text: 'million_plastic_bottles.options.minute' },
        { option: 'hour', text: 'million_plastic_bottles.options.hour' },
        { option: 'day', text: 'million_plastic_bottles.options.day' },
      ],
    },
    {
      question: 'plastic_lasts',
      correctOption: 'forever',
      options: [
        { option: '1to2years', text: 'plastic_lasts.options.1to2years' },
        { option: '50to100years', text: 'plastic_lasts.options.50to100years' },
        { option: 'forever', text: 'plastic_lasts.options.forever' },
      ],
    },
  ],
};

export default quizData;
