import { Form } from '../form';

function isValidFormFactory(formPath) {
  function isValidForm({ path, resolve, get }) {
    const form = new Form(resolve.value(formPath), get);

    return form._validate(get) ? path.true() : path.false();
  }

  return isValidForm;
}

export default isValidFormFactory;
