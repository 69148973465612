import { noop as noReturnValue } from './helpers';
import {
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
} from 'firebase/auth';
import { FirebaseProviderAuthenticationError } from './errors';

export default function updatePasswordFactory(
  auth,
  currentPassword,
  newPassword
) {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, currentPassword);

  return reauthenticateWithCredential(user, credential)
    .then(() => updatePassword(user, newPassword).then(noReturnValue))
    .catch(error => {
      throw new FirebaseProviderAuthenticationError(error);
    });
}
