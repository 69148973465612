import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import computedLeaderboardChampionship from 'challenge/computed/leaderboardChampionship';
import computedLeaderboardTeam from 'challenge/computed/leaderboardTeam';
import computedLeaderboardSection from 'challenge/computed/leaderboardSection';
import computedLeaderboardChallenge from 'challenge/computed/leaderboardChallenge';

import computedPersonalInsights from 'challenge/computed/personalInsights';

import { OwnState } from './types';
import { LEADERBOARD_TAB } from 'resources/constants';

const state: OwnState = {
  translates: {
    insightsAverage: null,
    insightsTotal: null,
    insightsUser: null,
  },
  toplistExpanded: false,
  showMobileParticipationList: false,
  showMobileActivityItems: false,
  showMobileSectionToplist: false,
  selectedLeaderboardTab: LEADERBOARD_TAB.team,
  championshipLeaderboard: [],
  championshipLeaderboardLastUpdated: Date.now(),
  leaderboardPage: 0,
  leaderboardPageSize: 20,
  computedLeaderboardTeam,
  computedLeaderboardSection,
  computedLeaderboardChallenge,
  computedLeaderboardChampionship,
  computedPersonalInsights,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    toggleShowMobileParticipationList: sequences.toggleParticipationListMobile,
    toggleShowMobileActivityItems: sequences.toggleActivityItemsMobile,
    toggleShowMobileSectionToplist: sequences.toggleToplistMobile,
    expandToplistClicked: sequences.expandToplistClicked,
    leaderboardTabClicked: sequences.setLeaderboardTab,
    changeLeaderboardPageClicked: sequences.setLeaderboardPage,
    updateChampionshipLeaderboardClicked: sequences.getChampionshipLeaderboard,
  },
};

export default module;
