import { getIdToken } from 'firebase/auth';
import { FirebaseProviderError } from './errors';

export default function getUserIDToken(auth) {
  const user = auth.currentUser;

  if (!user) {
    return Promise.reject(new FirebaseProviderError('No user'));
  }

  return getIdToken(user).catch(error => {
    throw new FirebaseProviderError(error);
  });
}
