import { ModuleDefinition } from 'cerebral';
import * as moduleSequences from './sequences';
import computedUserQuizScore from 'challenge/computed/userQuizScore';
import computedTeamQuizStats from 'challenge/computed/teamQuizStats';
import computedUserQuizScoreAll from 'challenge/computed/userQuizScoreAll';
import computedHasLoggedCurrentTag from 'challenge/computed/hasLoggedCurrentTag';

import { OwnState } from './types';

const state: OwnState = {
  key: null,
  show: false,
  showSecondaryInfo: false,
  showQuiz: false,
  shouldOpenQuiz: false,
  quizPageIndex: 0,
  quizQuestionKeys: [],
  quizStats: {},
  answerIsSaving: false,
  infoType: '',
  contentTag: '',
  computedUserQuizScore,
  computedTeamQuizStats,
  computedUserQuizScoreAll,
  computedHasLoggedCurrentTag,
};

export const sequences = {
  showTagModalClicked: moduleSequences.showTagModal,
  closeTagModalClicked: moduleSequences.closeTagModal,
  goToActivitiesClicked: moduleSequences.goToActivities,
  toggleShowSecondaryInfo: moduleSequences.toggleShowSecondaryInfo,
  quizAnswerSelected: moduleSequences.quizAnswerSelected,
  quizContinueClicked: moduleSequences.quizContinueClicked,
  toggleQuiz: moduleSequences.toggleQuiz,
  reviewQuiz: moduleSequences.reviewQuiz,
};

const module: ModuleDefinition = {
  state,
  sequences,
};

export default module;
