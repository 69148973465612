import { primaryLanguage } from 'languages/config';

export function refreshPage() {
  location.reload();
}

export function clearUrl({ router, urlAction }) {
  const newUrl = urlAction.clear();

  if (newUrl) {
    router.goTo(newUrl);
  }
}

export function redirectToEndpoint({ props, state, router }) {
  const language = state.get('appContainer.language.locale');

  const url = `${language === primaryLanguage ? '/' : `/${language}/`}${
    props.endpoint
  }`;

  router.goTo(url);
}

export function setReturnUrl({ props, storage, state }) {
  const { returnUrl } = props;
  const isMobile = state.get('app.isMobile');

  const returnString = isMobile ? returnUrl : returnUrl.substring(1);

  storage.set('returnUrl', returnString);
}

export function getReturnUrl({ storage }) {
  const redirectUrl = storage.get('returnUrl') || '/';

  return { redirectUrl };
}

export function goTo({ props, state, router }) {
  const language = state.get('appContainer.language.locale');

  const url = `${language === primaryLanguage ? '/' : `/${language}/`}${
    props.url
  }`;

  router.goTo(url);
}

export function redirectToLanguage({ props, state }) {
  const currentLanguage = state.get('appContainer.language.locale');
  const { language } = props;

  const path = location.pathname
    .split('/')
    .slice(primaryLanguage === currentLanguage ? 1 : 2)
    .join('/');

  const query = location.href.split('?');

  query.shift();

  const urlQuery = query.join('?');

  const urlLanguage = language === primaryLanguage ? '/' : `/${language}/`;

  location.href = `${location.origin}${urlLanguage}${path}${
    urlQuery ? `?${urlQuery}` : ''
  }`;
}

export function setProfileLanguage({ firebase, props }) {
  return firebase.task('set_profile_language', {
    language: props.language,
  });
}

export function shouldSaveScrollTop({ state, path }) {
  const tagModalKey = state.get('challenge.log.tagModal.key');

  if (tagModalKey) {
    return path.false();
  }

  return path.true();
}

export function saveScrollTop({ state }) {
  state.set(
    'appContainer.scrollTop',
    document?.body?.scrollTop || document?.documentElement?.scrollTop
  );
}

export function resetScroll() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export function scrollToScrollTop({ state }) {
  const scrollTop = state.get('appContainer.scrollTop') || 0;
  setTimeout(() => {
    document.body.scrollTop = scrollTop;
    document.documentElement.scrollTop = scrollTop;
  }, 0);
}

export function toggleNavItemMenu({ state, props }) {
  const openItem = state.get(`appContainer.showNav${props.item}Menu`);

  if (props.navBar === openItem) {
    state.set(`appContainer.showNav${props.item}Menu`, null);
  } else {
    state.set(`appContainer.showNav${props.item}Menu`, props.navBar);
  }
}

export function updateTermsAgreement({ firebase, state }) {
  const termsAccepted = state.get(
    'appContainer.auth.registerForm.termsAccepted.value'
  );

  return firebase.task('update_profile_terms_accepted', {
    termsAccepted,
  });
}

export function deleteProfile({ firebase }) {
  return firebase.task('delete_account', {
    feedback: {
      radio: 'declinedTerms',
    },
  });
}

export function listenToAuthState({ firebase, state }) {
  firebase.onAuthStateChanged(user => {
    // If the user was logged in, but is no longer, refresh the page to update auth state, profile etc.
    if (!user && state.get(`appContainer.user.uid`)) {
      window.location.reload();
    }
  });
}

export function startServiceWorker({ serviceWorker }) {
  serviceWorker.install(() => {
    serviceWorker.updateAll();
    window.location.reload();
  });
}

export function stopAllDatabaseListeners({ firebase }) {
  try {
    return firebase.off('*');
  } catch {
    // If no listeners and it throws, it's all gravy
    return {};
  }
}
