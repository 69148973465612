import { listenTo, createRef } from './helpers';

export default function OnValueFactory(database) {
  return function OnValueFunction(path, signal, options = {}) {
    let hasEmittedInitialValue = false;

    listenTo(
      createRef(database, path, options),
      path,
      'value',
      signal,
      data => {
        if (!hasEmittedInitialValue) {
          hasEmittedInitialValue = true;

          return;
        }

        this.context.app.getSequence(signal)({
          value: data.val(),
          ...options.payload,
        });
      }
    );
  };
}
