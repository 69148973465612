export const enum STREAKS {
  trackDays = 'track_days',
  getAStreak = 'get_a_streak',
  loggedEvery = 'logged_every',
  niceWork = 'nice_work',
  loggedX = 'logged_x',
  blank = 'blank',
  xDayStreak = 'x_day_streak',
  keepItGoing = 'keep_it_going',
  missedADay = 'missed_a_day',
  lostStreak = 'lost_streak',
}
