import { get } from 'firebase/database';
import { createRef, snapshotToArray } from './helpers';
import { FirebaseProviderError } from './errors';

export default function valueFactory(database) {
  return function valueFunction(path, options = {}) {
    const ref = createRef(database, path, options);

    return get(ref)
      .then(snapshot => {
        if (options.asArray && 'forEach' in snapshot) {
          return {
            key: snapshot.key,
            value: snapshotToArray(snapshot),
          };
        }

        return {
          key: snapshot.key,
          value: snapshot.val(),
        };
      })
      .catch(error => {
        throw new FirebaseProviderError(error);
      });
  };
}
