import page from 'page';

import { initTracking } from './tracking';

import { getLanguage, checkIsMobile } from 'utils/common';
import createApp from './controller';

initTracking();

const { language, isPrimary } = getLanguage();
const isMobile = checkIsMobile();

if (!isPrimary) page.base(`/${language}`);

import(`../../../resources/languages/${language}/index.ts`).then(module => {
  const app = createApp(module.default, isMobile);

  if (isMobile) {
    import('./index_mobile').then(({ mountApp }) => mountApp(app));
  } else {
    import('./index_desktop').then(({ mountApp }) => mountApp(app));
  }
});
