export function initTracking(): void {
  window['_paq'] = window['_paq'] || [];

  const paq = window['_paq'];

  paq.push([
    'setTrackerUrl',
    'https://folketsfotavtrykk.matomo.cloud/matomo.php',
  ]);
  paq.push([
    'setSiteId',
    (process.env.CONFIG as any).matomoSiteId as unknown as number,
  ]);
  paq.push(['trackPageView']);
  paq.push(['enableLinkTracking']);

  // Needed unless we ask for consent
  paq.push(['requireCookieConsent']);

  const matomoScript = document.createElement('script');

  matomoScript.setAttribute(
    'src',
    'https://folketsfotavtrykk.matomo.cloud/matomo.js'
  );

  document.head.appendChild(matomoScript);
}
