import { state, Context } from 'app/pwa/app.cerebral';
import { sum, getIndicator } from 'utils/common';
import { Campaign, TotalStats } from 'common/types';
import { TRACKING_CATEGORY } from 'resources/constants';
import { LeaderboardData } from 'challenge/module/modules/visualisations/types';

type Props = {
  campaignsWithData: {
    campaign: Campaign;
    totalStats: TotalStats;
  }[];
};

export function getChampionshipLeaderboardData({
  firebase,
  get,
}: Context): Promise<Props> {
  const campaigns = get(state.challenge.campaigns) || {};

  return Promise.all(
    Object.values(campaigns).map(campaign =>
      firebase
        .value(`challenges.totalStats.challenges.${campaign.challenge.id}`)
        .then(response => ({ campaign, totalStats: response.value || {} }))
    )
  ).then(campaignsWithData => ({ campaignsWithData }));
}

export function formatChampionshipLeaderboardData({
  props,
  get,
}: Context<Props>): { leaderboard: LeaderboardData[] } {
  const indicator = get(state.challenge.campaign.indicator);

  return {
    leaderboard: props.campaignsWithData.map(({ campaign, totalStats }) => ({
      key: campaign.id,
      name: campaign.organisation.name,
      value: getIndicator(totalStats, indicator, 'average') || 0,
      activityCount: sum(totalStats.activitiesCount || {}),
    })),
  };
}

export function setChampionshipLeaderboardUpdateDate({ store }: Context): void {
  store.set(
    state.challenge.visualisations.championshipLeaderboardLastUpdated,
    Date.now()
  );
}

export function trackLeaderboardTabClicked({
  props,
  tracking,
}: Context<{ tab: string }>): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Change Leaderboard Tab',
    name: props.tab,
  });
}

export function trackLeaderboardPageClicked({
  props,
  tracking,
}: Context<{ page: string }>): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Change Leaderboard Page',
    name: props.page,
  });
}

export function trackLeaderboardExpanded({ tracking }: Context): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Expand Leaderboard',
  });
}

export function trackLeaderboardCollapsed({ tracking }: Context): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Collapse Leaderboard',
  });
}
