import { ModuleDefinition } from 'cerebral';

import stats from './modules/stats';
import events from './modules/events';
import activityModal from './modules/activityModal';
import notifications from './modules/notifications';
import tutorial from './modules/tutorial';
import info from './modules/info';
import visualisations from './modules/visualisations';
import log from './modules/log';
import tagModal from './modules/tagModal';

import { OwnState } from './types';

import * as sequences from './sequences';

const state: OwnState = {
  notFound: false,
  hasRendered: false,
  key: null,
  renderedChallengeKey: null,
  campaign: null,
  campaigns: {},
  userCampaign: null,
  userChallenge: {},
  teamMembers: {},
  currentTab: 0,
  maxScore: null,
  isLoading: {
    challengeData: true,
    sectionsData: true,
    teamsData: true,
    totalChallengeStats: true,
    totalTeamsStats: true,
    dailyUserStats: true,
    totalProfilesStats: true,
    events: true,
  },
};

const module: ModuleDefinition = {
  state,
  sequences: {
    rendered: sequences.renderPage,
    tabMenuClicked: sequences.updateTabMenu,
    feedbackSubmitted: sequences.submitFeedback,
  },
  modules: {
    stats,
    visualisations,
    events,
    activityModal,
    tutorial,
    info,
    log,
    notifications,
    tagModal,
  },
};

export default module;
