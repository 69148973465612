import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getAvatarUrl, getDefaultAvatarColor } from 'utils/avatars';
import { orderAndFormatLeaderboard } from 'utils/challenge';

export default Compute(get => {
  const staticUrl = get(state.appContainer.config.staticUrl);
  const campaignKey = get(state.challenge.campaign.id);

  const championshipLeaderboard = get(
    state.challenge.visualisations.championshipLeaderboard
  );
  const leaderboardPage = get(state.challenge.visualisations.leaderboardPage);
  const leaderboardPageSize = get(
    state.challenge.visualisations.leaderboardPageSize
  );
  const leaderboardIsExpanded = get(
    state.challenge.visualisations.toplistExpanded
  );

  const leaderboard = championshipLeaderboard.map(element => ({
    ...element,
    avatar: getAvatarUrl(staticUrl, { name: element.name }).small,
    avatarLarge: getAvatarUrl(staticUrl, { name: element.name }).large,
    value: element.value,
    isEmphasised: campaignKey === element.key,
    avatarColor: getDefaultAvatarColor(element.name),
  }));

  return orderAndFormatLeaderboard(
    leaderboard,
    leaderboardPage,
    leaderboardPageSize,
    leaderboardIsExpanded
  );
});
