export const INDICATOR = {
  co2: 'co2',
  biodiversity: 'biodiversity',
  handprint: 'handprint',
  health: 'health',
  landuse: 'landuse',
  money: 'money',
  points: 'points',
  time: 'time',
  social: 'social',
} as const;

export type Indicator = (typeof INDICATOR)[keyof typeof INDICATOR];
