export const enum URL_ACTION {
  activityModal = 'activityModal',
  tagModal = 'tagModal',
  indicatorModal = 'indicatorModal',
  streakModal = 'streakModal',
  goalModal = 'goalModal',
  introModal = 'introModal',
  outroModal = 'outroModal',
  numberInputModal = 'numberInputModal',
  leaderboardModal = 'leaderboardModal',
  tutorial1 = 'tutorial1',
  tutorial2 = 'tutorial2',
  tutorial3 = 'tutorial3',
  tutorial4 = 'tutorial4',
  tutorial5 = 'tutorial5',
  tutorial6 = 'tutorial6',
  tutorial7 = 'tutorial7',
}
