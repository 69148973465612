import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';

import { OwnState } from './types';

const state: OwnState = {
  hasSetPushNotificationToken: false,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    handlePushNotifications: sequences.handlePushNotifications,
  },
};

export default module;
