import { sequence, parallel, set, equals, when } from 'cerebral/factories';
import { state, props, string } from 'cerebral';
import { value } from 'providers/Firebase/src/operators';
import { TAG_CATEGORY, URL_ACTION } from 'resources/constants';

import {
  setUrlAction,
  hasUrlAction,
  rendered,
} from 'common/appContainer/module/factories';
import { getProfilesByKeys } from './factories';

import * as actions from './actions';
import * as appContainerActions from 'common/appContainer/module/actions';
import { increasePopUpNumber } from 'challenge/module/modules/tutorial/sequences';
import * as logSequences from 'challenge/module/modules/log/sequences';
import * as tagModalSequences from 'challenge/module/modules/tagModal/sequences';
import * as statsSequences from 'challenge/module/modules/stats/sequences';
import * as eventsSequences from 'challenge/module/modules/events/sequences';
import * as visualisationSequences from 'challenge/module/modules/visualisations/sequences';

const getSectionsData = [
  sequence('getSectionsData', [
    sequence('getSectionsTotalStats', [
      value(string`challenges.totalStats.sections.${state`challenge.key`}`),
      set(state`challenge.stats.total.sections`, props`response.value`),
    ]),
    set(state`challenge.isLoading.sectionsData`, false),
  ]),
];

const getUserCampaign = [
  sequence('getUserCampaign', [
    value(
      string`profiles.campaigns.${state`appContainer.user.uid`}.${state`challenge.campaign.id`}`
    ),
    set(state`challenge.userCampaign`, props`response.value`),
  ]),
];

const getChallengeParticipantCounts = [
  sequence('getChallengeParticipantCounts', [
    value(string`challenges.participantCounts.${state`challenge.key`}`),
    actions.setChallengeParticipantCounts,
  ]),
];

const getTeamsData = [
  sequence('getTeamsData', [
    sequence('getUserTeamMembers', [
      actions.getTeamMembers,
      getProfilesByKeys(props`teamMembers`),
      set(state`challenge.teamMembers`, props`teamMembers`),
    ]),
    set(state`challenge.isLoading.teamsData`, false),
  ]),
];

const handleTagModal = sequence([
  actions.setTagModal,
  when(
    state`challenge.tagModal.show`,
    state`challenge.tagModal.shouldOpenQuiz`,
    (showTagModal, shouldOpenQuiz) => showTagModal && shouldOpenQuiz
  ),
  {
    false: [],
    true: [tagModalSequences.toggleQuiz],
  },
  set(state`challenge.tagModal.shouldOpenQuiz`, false),
]);

const urlActions = [
  sequence('challengeUrlActions', [
    when(state`challenge.tutorial.show`, showTutorial => showTutorial !== 0),
    {
      true: [set(state`challenge.log.selectedTag`, 'all')],
      false: [],
    },
    set(state`challenge.tutorial.show`, 0),
    actions.parseAction,
    actions.setActivityModal,
    handleTagModal,
    actions.setIndicatorModal,
    actions.setStreakModal,
    actions.setGoalModal,
    actions.setIntroModal,
    actions.setOutroModal,
    actions.setLeaderboardModal,
    when(state`app.isMobile`),
    {
      true: [
        equals(props`actionType`),
        {
          [URL_ACTION.tutorial1]: [
            set(state`challenge.tutorial.show`, 1),
            set(state`challenge.currentTab`, 0),
          ],
          [URL_ACTION.tutorial2]: [
            set(state`challenge.tutorial.tutorialActivityLogged`, false),
            set(state`challenge.log.selectedTag`, TAG_CATEGORY.tutorial),
            set(state`challenge.tutorial.show`, 2),
            set(state`challenge.currentTab`, 2),
          ],
          [URL_ACTION.tutorial3]: [
            set(state`challenge.tutorial.tutorialActivityLogged`, true),
            set(state`challenge.log.selectedTag`, TAG_CATEGORY.tutorial),
            set(state`challenge.tutorial.show`, 3),
          ],
          otherwise: [],
        },
      ],
      false: [
        equals(props`actionType`),
        {
          [URL_ACTION.tutorial1]: [
            set(state`challenge.tutorial.tutorialActivityLogged`, false),
            set(state`challenge.log.selectedTag`, TAG_CATEGORY.tutorial),
            set(state`challenge.tutorial.show`, 1),
          ],
          [URL_ACTION.tutorial2]: [
            set(state`challenge.tutorial.tutorialActivityLogged`, true),
            set(state`challenge.log.selectedTag`, TAG_CATEGORY.tutorial),
            set(state`challenge.tutorial.show`, 2),
          ],
          otherwise: [],
        },
      ],
    },
  ]),
];

const challengeRenderedSequence = [
  set(state`challenge.hasRendered`, true),
  set(state`challenge.renderedChallengeKey`, props`challengeKey`),
  actions.triggerHandlePushNotifications,
];

const getCampaignsFromChampionship = [
  when(state`challenge.campaign.championshipKey`),
  {
    true: [
      actions.getChampionshipCampaigns,
      actions.getCampaigns,
      set(state`challenge.campaigns`, props`campaigns`),
    ],
    false: [],
  },
];

const loadChallengeSequence = [
  actions.hasUserJoinedChallenge,
  {
    true: [],
    false: [
      when(state`account.joinCampaign.isJoiningChallenge`),
      {
        true: [],
        false: [
          set(state`account.joinCampaign.isJoiningChallenge`, true),
          actions.joinChallenge,
          set(state`account.joinCampaign.isJoiningChallenge`, false),
          hasUrlAction(`introModal`),
          {
            true: [],
            false: setUrlAction('introModal'),
          },
        ],
      },
    ],
  },
  parallel('firstDataLoad', [
    getSectionsData,
    getChallengeParticipantCounts,
    statsSequences.getTotalChallengeStats,
    logSequences.getUserChallenge,
  ]),
  actions.computeMaxScore,
  set(state`challenge.maxScore`, props`maxScore`),
  set(state`challenge.isLoading.challengeData`, false),
  parallel('secondDataLoad', [
    actions.setSelectedDayIndex,
    getTeamsData,
    statsSequences.getTotalTeamsStats,
    statsSequences.getDailyUserStats,
    statsSequences.getTotalProfileStats,
    eventsSequences.getEvents,
    actions.notifyLatestEvents,
    getCampaignsFromChampionship,
  ]),
  visualisationSequences.getChampionshipLeaderboard,
  statsSequences.getInsightsTranslations,
  statsSequences.listenForStatUpdates,
  eventsSequences.listenForEvents,
  challengeRenderedSequence,
  when(state`challenge.userChallenge.tips.hasSeenBacklogTip`),
  {
    true: [],
    false: [
      actions.hasNotLoggedWithinBacklogPeriod,
      {
        true: [set(state`challenge.info.showBacklogTip`, true)],
        false: [],
      },
    ],
  },
];

export const renderPage = [
  rendered([
    set(state`challenge.notFound`, false),
    set(state`challenge.key`, props`challengeKey`),
    when(
      props`challengeKey`,
      state`challenge.renderedChallengeKey`,
      (newChallengeKey, currentChallengeKey) =>
        newChallengeKey === currentChallengeKey
    ),
    {
      true: [],
      false: [set(state`challenge.currentTab`, 0), actions.setAllLoadingFlags],
    },
    set(state`appContainer.currentPage`, 'challengePWA'),
    urlActions,
    when(
      state`challenge.hasRendered`,
      props`challengeKey`,
      state`challenge.renderedChallengeKey`,
      (hasRendered, newChallengeKey, currentChallengeKey) =>
        hasRendered && newChallengeKey === currentChallengeKey
    ),
    {
      true: [challengeRenderedSequence],
      false: [
        actions.getCampaign,
        when(props`campaign`),
        {
          true: [
            set(state`challenge.campaign`, props`campaign`),
            actions.hasActivityData,
            {
              true: [
                set(
                  state`appContainer.activities`,
                  props`campaign.activityData`
                ),
              ],
              false: [actions.setCalculatedActivities],
            },
            getUserCampaign,
            actions.hasUserJoinedCampaign,
            {
              true: loadChallengeSequence,
              false: actions.redirectToJoinCampaign,
            },
          ],
          false: [set(state`challenge.notFound`, true)],
        },
      ],
    },
  ]),
];

export const updateTabMenu = [
  set(state`challenge.visualisations.toplistExpanded`, false),
  set(state`challenge.currentTab`, props`tab`),
  appContainerActions.resetScroll,
  actions.shouldShowNps,
  {
    true: [set(state`challenge.info.showNpsModal`, true)],
    false: [],
  },
  actions.checkIfApplicableTab,
  {
    true: increasePopUpNumber,
    false: [],
  },
  when(
    props`tab`,
    props`currentTab`,
    (tab, currentTab) => tab !== 3 && currentTab === 3
  ),
  {
    true: [actions.updateEventsLastSeen, logSequences.getUserChallenge],
    false: [],
  },
];

export const submitFeedback = [
  actions.submitFeedback,
  logSequences.getUserChallenge,
];
