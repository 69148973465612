import { state } from 'app/pwa/app.cerebral';
import {
  getFullDaysDiff,
  getLastDayTimestamp,
  getDaysInMilliseconds,
} from 'utils/common';
import { URL_ACTION } from 'resources/constants';
import { CalculatorActivitiesResponse } from 'providers/Activities/types';

export function hasUserJoinedCampaign({ get, path }) {
  const userCampaign = get(state.challenge.userCampaign);

  if (userCampaign) {
    return path.true();
  }

  return path.false();
}

export function hasUserJoinedChallenge({ firebase, get, path }) {
  const campaignId = get(state.challenge.campaign.id);
  const { teamKey, sectionKey } = get(state.challenge.userCampaign);
  const profileKey = get(state.appContainer.user.uid);

  return firebase
    .value(
      `campaigns.members.${campaignId}.${sectionKey}.${teamKey}.${profileKey}.joinedDatetimes.challenge`
    )
    .then(result => {
      if (result.value) {
        return path.true();
      }

      return path.false();
    });
}

export function joinChallenge({ get, firebase }) {
  const challengeKey = get(state.challenge.key);

  return firebase.task('join_challenge', {
    challengeKey,
  });
}

export async function setCalculatedActivities({ get, store, activities }) {
  const dataSource = get(state.challenge.campaign.dataSource);
  const createdAt = get(state.challenge.campaign.createdAt);
  const language = get(state.appContainer.language.locale);

  const allActivities: CalculatorActivitiesResponse =
    await activities.getAllActivities({
      dataSource,
      dataFromDate: createdAt
        ? new Date(createdAt).toISOString().substring(0, 10)
        : undefined,
      language,
    });

  store.set(state.appContainer.activities, allActivities);
}

export function computeMaxScore({ get }) {
  const indicator = get(state.challenge.campaign.indicator);
  const startOfDays = get(state.challenge.campaign.challenge.startOfDays);

  const activities = Object.keys(
    get(state.challenge.campaign.challenge.activities) || {}
  )
    .filter(activityKey => !activityKey.includes('tutorial'))
    .filter(activityKey => get(state.appContainer.activities[activityKey]))
    .map(activityKey => get(state.appContainer.activities[activityKey]));

  const scoreForActivitiesWithoutSubCategories = activities
    .filter(({ subCategory }) => subCategory === null)
    .map(({ indicators }) => indicators[indicator] || 0)
    .reduce((a, v) => a + v, 0);

  const maxScorePerSubCategory = activities
    .filter(({ subCategory }) => subCategory)
    .reduce((result, { subCategory, indicators }) => {
      result[subCategory] = Math.max(
        result[subCategory] || 0,
        indicators[indicator] || 0
      );

      return result;
    }, {});

  const scoreForActivitiesWithSubCategories = Object.values(
    maxScorePerSubCategory
  ).reduce((a: number, v: number) => a + v, 0);

  return {
    maxScore:
      (scoreForActivitiesWithoutSubCategories +
        scoreForActivitiesWithSubCategories) *
      startOfDays.length,
  };
}

export function setSelectedDayIndex({ get, store }) {
  const { startDatetime, endDatetime } = get(
    state.challenge.campaign.challenge
  );

  store.set(
    state.challenge.log.selectedDayIndex,
    getFullDaysDiff(
      startDatetime,
      Date.now() > endDatetime ? endDatetime : Date.now()
    )
  );
}

export function getTeamMembers({ firebase, get }) {
  const campaignKey = get(state.challenge.campaign.id);
  const { teamKey, sectionKey } = get(state.challenge.userCampaign);

  return firebase
    .value(`campaigns.members.${campaignKey}.${sectionKey}.${teamKey}`)
    .then(result => {
      if (result.value) {
        const teamMembers = Object.assign(
          {},
          ...Object.keys(result.value)
            .filter(
              profileKey => result.value[profileKey].joinedDatetimes.challenge
            )
            .map(profileKey => ({
              [profileKey]: {
                joinedDatetime:
                  result.value[profileKey].joinedDatetimes.challenge,
              },
            }))
        );

        return { teamMembers };
      }

      return result.value;
    });
}

export function checkIfApplicableTab({ get, props, path }) {
  const showTutorial = get(state.challenge.tutorial.show);
  const { tab } = props;

  if (showTutorial === 1 && tab === 2) {
    return path.true();
  }

  return path.false();
}

export function redirectToJoinCampaign({ get, router }) {
  const campaignId = get(state.challenge.campaign.id);

  router.goTo(`/joinCampaign?link=${campaignId}`);
}

export function setAllLoadingFlags({ store }) {
  store.set(state.challenge.isLoading, {
    challengeData: true,
    sectionsData: true,
    teamsData: true,
    totalTeamsStats: true,
    dailyUserStats: true,
    totalProfilesStats: true,
    events: true,
  });
}

export function triggerHandlePushNotifications({ app }) {
  app.getSequence('challenge.notifications.handlePushNotifications')();
}

export function parseAction({ props }) {
  const [actionType = null, actionKey = null] = props?.action?.split(':') ?? [];

  return { actionType, actionKey };
}

export function setActivityModal({ store, props }) {
  const isActModal = props.actionType === URL_ACTION.activityModal;

  store.set(state.challenge.activityModal.showActivityModal, isActModal);
  store.set(
    state.challenge.activityModal.activityKey,
    isActModal ? props.actionKey : null
  );
}

export function setTagModal({ store, props }) {
  const isTagModal = props.actionType === URL_ACTION.tagModal;

  store.set(state.challenge.tagModal.show, isTagModal);
  store.set(state.challenge.tagModal.key, isTagModal ? props.actionKey : null);
}

export function setIndicatorModal({ store, props }) {
  const isIndicatorModal = props.actionType === URL_ACTION.indicatorModal;

  store.set(state.challenge.info.showIndicatorModal, isIndicatorModal);
}

export function setStreakModal({ store, props }) {
  const isStreakModal = props.actionType === URL_ACTION.streakModal;

  store.set(state.challenge.info.showStreakModal, isStreakModal);
}

export function setGoalModal({ store, props }) {
  const isGoalModal = props.actionType === URL_ACTION.goalModal;

  store.set(state.challenge.info.showGoalModal, isGoalModal);
}

export function setIntroModal({ store, props }) {
  const isIntroModal = props.actionType === URL_ACTION.introModal;

  store.set(state.challenge.tutorial.showIntroModal, isIntroModal);
}

export function setOutroModal({ store, props }) {
  const isOutroModal = props.actionType === URL_ACTION.outroModal;

  store.set(state.challenge.tutorial.showOutroModal, isOutroModal);
}

export function setLeaderboardModal({ store, props }) {
  const isLeaderboardModal = props.actionType === URL_ACTION.leaderboardModal;

  store.set(state.challenge.info.showLeaderboardModal, isLeaderboardModal);
}

export function hasLoggedBeforeToday({ get, path }) {
  const userStats = get(state.challenge.stats.daily.user);
  const challengeStartDatetime = get(
    state.challenge.campaign.challenge.startDatetime
  );
  const todaysDayTimeStamp = getLastDayTimestamp(challengeStartDatetime);
  if (Object.keys(userStats).some(datetime => datetime < todaysDayTimeStamp)) {
    return path.true();
  }

  return path.false();
}

export function hasNotLoggedWithinBacklogPeriod({ get, path }) {
  const userStats = get(state.challenge.stats.daily.user);
  const challenge = get(state.challenge.campaign.challenge);
  const { startDatetime, backlogDays, startOfDays = [] } = challenge;
  const todaysDatetime = getLastDayTimestamp(startDatetime);
  const furthestBacklogDatetime =
    todaysDatetime - getDaysInMilliseconds(backlogDays);
  const datetimesLogged = Object.keys(userStats);

  const hasLoggedAllAvailableTimestamps = startOfDays
    .filter(
      datetime =>
        datetime < todaysDatetime && datetime >= furthestBacklogDatetime
    )
    .every(datetime => datetimesLogged.includes(String(datetime)));

  if (hasLoggedAllAvailableTimestamps) {
    return path.false();
  }

  return path.true();
}

export function setBacklogTipSeen({ get, firebase }) {
  const challengeKey = get(state.challenge.key);

  return firebase.task('update_user_challenge_backlog_tip', {
    challengeKey,
  });
}

export function notifyLatestEvents({ app }) {
  app.getSequence('challenge.events.notifyLatestEvents')();
}

export function getCampaign({ database, props }) {
  return database
    .getCampaignByChallengeId(props.challengeKey)
    .then(campaign => ({ campaign }));
}

export function setChallengeParticipantCounts({ props, get, store }) {
  const structure = get(state.challenge.campaign.structure);

  const counts = props.response.value;

  store.set(
    state.challenge.campaign.structure.participantCount,
    counts.total || 0
  );

  Object.keys(structure.sections).forEach(sectionKey => {
    store.set(
      state.challenge.campaign.structure.sections[sectionKey].participantCount,
      counts.sections[sectionKey] || 0
    );

    Object.keys(structure.sections[sectionKey].teams).forEach(teamKey => {
      store.set(
        state.challenge.campaign.structure.sections[sectionKey].teams[teamKey]
          .participantCount,
        counts.teams[teamKey] || 0
      );
    });
  });
}

export function shouldShowNps({ get, props, path }) {
  const { tab } = props;
  const startOfDays = get(state.challenge.campaign.challenge.startOfDays);
  const challengeEndDatetime = get(
    state.challenge.campaign.challenge.endDatetime
  );
  const nps = get(state.challenge.userChallenge.nps);
  const isMobile = get(state.app.isMobile);

  const logTab = isMobile ? 2 : 0;
  const day7 = startOfDays?.[6];

  if (
    tab !== logTab &&
    day7 &&
    day7 < Date.now() &&
    Date.now() < challengeEndDatetime &&
    (!nps || (!nps.submitted && !nps.dismissed))
  ) {
    return path.true();
  }

  return path.false();
}

export function updateEventsLastSeen({ get, firebase }) {
  const challengeKey = get(state.challenge.key);

  return firebase.task('set_events_last_seen', {
    challengeKey,
  });
}

export function getChampionshipCampaigns({ database, get }) {
  return database.getChampionshipCampaigns(
    get(state.challenge.campaign.championshipKey)
  );
}

export async function getCampaigns({ database, props }) {
  const campaigns = await database
    .getCampaigns(props.campaignKeys)
    .then(camps => camps || {});

  return { campaigns };
}

export function submitFeedback({ props, get, firebase }) {
  const challengeKey = get(state.challenge.key);

  return firebase.task('update_user_challenge_submit_feedback', {
    feedback: {
      value: props.value,
      comment: '',
    },
    challengeKey,
    type: props.type,
    category: props.category,
  });
}

export function hasActivityData({ props, path }) {
  const { activityData } = props.campaign;
  const challengeId = props.challengeKey;
  const oldChallenge = challengeId.charAt(0) === '-';

  if (oldChallenge || !activityData) {
    return path.false();
  }

  return path.true();
}
