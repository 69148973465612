import { state, props, string } from 'cerebral';
import { set, when, toggle } from 'cerebral/factories';

import { value } from 'providers/Firebase/src/operators';

import { setUrlAction } from 'common/appContainer/module/factories';

import * as actions from './actions';
import * as mainActions from '../../actions';

export const toggleShowActivities = [
  toggle(state`challenge.log.show${props`category`}Activities`),
];

export const setShowAllPopularActivities = [
  set(state`challenge.log.showAllPopularActivities`, props`show`),
];

export const tagSelected = [set(state`challenge.log.selectedTag`, props`tag`)];

export const removeSelectedTag = set(state`challenge.log.selectedTag`, '');

export const getUserChallenge = [
  value(
    string`challenges.userChallenges.${state`challenge.key`}.${state`appContainer.user.uid`}`
  ),
  when(props`response.value`),
  {
    true: [set(state`challenge.userChallenge`, props`response.value`)],
    false: [],
  },
];

export const setActivityModal = [
  set(state`appContainer.shouldResetScroll`, false),
  set(
    state`challenge.activityModal.showMoreInfo.${state`challenge.campaign.indicator`}`,
    true
  ),
  set(state`challenge.log.previousActivityModalKey`, null),
  setUrlAction(string`activityModal:${props`activityKey`}`),
];

export const toggleActivity = [
  actions.setActivityKeysFromLogging,
  actions.toggleLogActivity,
  actions.removeActivityKeysFromLogging,
];

export const toggleLogAllHabits = [
  actions.filterHabitsToLog,
  actions.setActivityKeysFromLogging,
  actions.toggleLogAllHabits,
  actions.removeActivityKeysFromLogging,
];

export const toggleHabit = [
  actions.untoggleHabitActivitiesWithSameSubCategory,
  actions.toggleHabitActivity,
  actions.toggleHabitActivityTask,
];

export const toggleShowHabitActivities = [
  toggle(state`challenge.log.showHabitActivities`),
];

export const toggleShowPopularActivities = [
  toggle(state`challenge.log.showPopularActivities`),
];

export const decreaseSelectedDayIndex = [
  actions.decreaseSelectedDayIndex,
  when(state`challenge.info.showBacklogTip`),
  {
    true: [
      set(state`challenge.info.showBacklogTip`, false),
      mainActions.setBacklogTipSeen,
    ],
    false: [],
  },
];
