import { state } from 'cerebral';
import { getLastDayTimestamp, getDaysInMilliseconds } from 'utils/common';
import { Context } from 'app/pwa/app.cerebral';
import { TRACKING_CATEGORY } from 'resources/constants';
import quiz from 'toolData/quiz';
import moment from 'moment-timezone';

function filterOutActivitiesWithSameSubCategory(
  activities,
  toggledActivities,
  subCategoryToFilterOut
) {
  return Object.keys(toggledActivities)
    .filter(activityKey => {
      const activity = activities[activityKey];

      return activity?.subCategory !== subCategoryToFilterOut;
    })
    .reduce((allActivities, activityKey) => {
      allActivities[activityKey] = true;

      return allActivities;
    }, {});
}

export function increaseSelectedDayIndex({ get, store }) {
  const selectedDayIndex = get(state`challenge.log.selectedDayIndex`);
  const { startDatetime, endDatetime } = get(
    state`challenge.campaign.challenge`
  );
  const selectedDayKey =
    startDatetime + getDaysInMilliseconds(selectedDayIndex);
  const currentDayKey = getLastDayTimestamp(startDatetime);
  const lastDayKey = endDatetime - getDaysInMilliseconds(1);
  const newDayIndex =
    currentDayKey === selectedDayKey || selectedDayKey === lastDayKey
      ? selectedDayIndex
      : selectedDayIndex + 1;

  store.set(state`challenge.log.selectedDayIndex`, newDayIndex);
}

export function decreaseSelectedDayIndex({ get, store }) {
  const selectedDayIndex = get(state`challenge.log.selectedDayIndex`);
  const startDateIndex = 0;
  const newDayIndex =
    selectedDayIndex === startDateIndex
      ? selectedDayIndex
      : selectedDayIndex - 1;

  store.set(state`challenge.log.selectedDayIndex`, newDayIndex);
}

export function getMillisecondsDiff(
  fromDatetime: number,
  selectedDayIndex: number,
  timezone: string
) {
  if (
    typeof fromDatetime !== 'number' ||
    typeof selectedDayIndex !== 'number'
  ) {
    throw new Error('getMillisecondsDiff requires two EPOCH values');
  }

  const currentDay = Number(
    moment.tz(fromDatetime, timezone).add(selectedDayIndex, 'day')
  );

  return currentDay - fromDatetime;
}

export function toggleLogActivity({ firebase, props, get }) {
  const { activityKey } = props;
  const allActivities = get(state`appContainer.activities`);
  const challengeKey = get(state`challenge.key`);
  const challengeStartDatetime = get(
    state`challenge.campaign.challenge.startDatetime`
  );
  const timezone = get(state`challenge.campaign.timezone`);
  const selectedDayIndex = get(state`challenge.log.selectedDayIndex`);
  const datetime =
    challengeStartDatetime +
    getMillisecondsDiff(challengeStartDatetime, selectedDayIndex, timezone);

  return firebase
    .task('toggle_logged_activity', {
      activities: {
        [activityKey]: true,
      },
      challengeKey,
      datetime,
      allActivities,
    })
    .then(result => result)
    .catch(() => {});
}

export function filterHabitsToLog({ get }) {
  const habitActivities = get(state`challenge.userChallenge.habits`);
  const appActivities = get(state`appContainer.activities`);
  const loggedHabitActivityKeys = get(
    state`challenge.log.computedLoggedHabitActivityKeys`
  );

  const habitActivityKeys = Object.keys(habitActivities || {}).filter(
    activityKey => appActivities[activityKey]
  );

  if (
    habitActivityKeys.length === loggedHabitActivityKeys.length ||
    !loggedHabitActivityKeys.length
  ) {
    return { activityKeys: habitActivityKeys };
  }
  const filteredHabitActivityKeys = habitActivityKeys.filter(
    activityKey => loggedHabitActivityKeys.indexOf(activityKey) === -1
  );

  return {
    activityKeys: filteredHabitActivityKeys,
  };
}

export function toggleLogAllHabits({ firebase, get, props }) {
  const challengeKey = get(state`challenge.key`);
  const allActivities = get(state`appContainer.activities`);
  const challengeStartDatetime = get(
    state`challenge.campaign.challenge.startDatetime`
  );
  const timezone = get(state`challenge.campaign.timezone`);
  const selectedDayIndex = get(state`challenge.log.selectedDayIndex`);
  const datetime =
    challengeStartDatetime +
    getMillisecondsDiff(challengeStartDatetime, selectedDayIndex, timezone);

  const activities = props.activityKeys.reduce((acts, activityKey) => {
    acts[activityKey] = true;

    return acts;
  }, {});

  return firebase.task('toggle_logged_activity', {
    activities,
    challengeKey,
    datetime,
    allActivities,
  });
}

export function untoggleHabitActivitiesWithSameSubCategory({
  get,
  store,
  props,
}) {
  const activities = get(state`appContainer.activities`);
  const activityKeyToToggle = props.activityKey;
  const activityToToggle = activities[activityKeyToToggle];
  const toggledActivities = get(state`challenge.userChallenge.habits`) || {};

  if (
    !activityToToggle.subCategory ||
    activityKeyToToggle in toggledActivities
  ) {
    return;
  }
  const activitiesToKeepToggled = filterOutActivitiesWithSameSubCategory(
    activities,
    toggledActivities,
    activityToToggle.subCategory
  );

  store.set(state`challenge.userChallenge.habits`, activitiesToKeepToggled);
}

export function toggleHabitActivity({ props, get, store }) {
  let habitActivities = get(state`challenge.userChallenge.habits`);

  if (!habitActivities) {
    store.set(state`challenge.userChallenge.habits`, {});
  }
  habitActivities = get(state`challenge.userChallenge.habits`);
  if (props.activityKey in habitActivities) {
    store.unset(state`challenge.userChallenge.habits.${props.activityKey}`);
  } else {
    store.set(state`challenge.userChallenge.habits.${props.activityKey}`, true);
  }
}

export function toggleHabitActivityTask({ get, firebase }) {
  const challengeKey = get(state`challenge.key`);
  const habits = get(state`challenge.userChallenge.habits`) || {};

  return firebase.task('update_user_challenge_habits', {
    habits,
    challengeKey,
  });
}

export function setActivityKeysFromLogging({ props, get, store }) {
  (props.activityKeys || [props.activityKey]).forEach(activityKey => {
    store.set(state`challenge.log.activityKeysLogging.${activityKey}`, true);

    const subCategory = get(
      state`appContainer.activities.${activityKey}.subCategory`
    );

    if (subCategory) {
      store.set(
        state`challenge.log.activitySubCategoryLogging.${subCategory}`,
        true
      );
    }
  });
}

export function removeActivityKeysFromLogging({ props, get, store }) {
  (props.activityKeys || [props.activityKey]).forEach(activityKey => {
    store.set(state`challenge.log.activityKeysLogging.${activityKey}`, false);

    const subCategory = get(
      state`appContainer.activities.${activityKey}.subCategory`
    );

    if (subCategory) {
      store.set(
        state`challenge.log.activitySubCategoryLogging.${subCategory}`,
        false
      );
    }
  });
}

export function saveQuizAnswer({ props, get, firebase }) {
  const { category, question, answer } = props;
  const challengeKey = get(state`challenge.key`);

  return firebase.task('submit_quiz_answer', {
    challengeKey,
    category,
    question,
    answer,
  });
}

export function setQuizState({ store, get }) {
  const tag = get(state`challenge.log.tagModal.key`);
  const answers = get(state`challenge.userChallenge.quiz`);

  const questionKeys = quiz[tag]?.map(q => q.question);
  const uncompletedIndex = questionKeys.findIndex(q => !answers?.[q]);

  if (uncompletedIndex === -1) {
    store.set(state`challenge.log.quizPageIndex`, 0);
  } else {
    store.set(state`challenge.log.quizPageIndex`, uncompletedIndex + 1);
  }

  store.set(state`challenge.log.quizQuestionKeys`, questionKeys);
}

export function incrementQuizIndex({ store, get }) {
  const tag = get(state`challenge.log.tagModal.key`);
  const quizPageIndex = get(state`challenge.log.quizPageIndex`);
  const questionCount = quiz[tag]?.length;

  if (quizPageIndex === questionCount) {
    store.set(state`challenge.log.quizPageIndex`, 0);
  } else {
    store.set(state`challenge.log.quizPageIndex`, quizPageIndex + 1);
  }
}

export function getQuizStats({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const tag = get(state`challenge.log.tagModal.key`);

  return firebase
    .value(`challenges.quiz.${challengeKey}.scores.${tag}`)
    .then(response => ({
      // Firebase sometimes returns an array, if the keys are 0,1,2... This converts it to an object
      // https://firebase.googleblog.com/2014/04/best-practices-arrays-in-firebase.html
      quizStats: Object.fromEntries(Object.entries(response.value || {})),
    }));
}

export function trackSecondaryTagModalOpened({
  props,
  tracking,
}: Context<{ contentTag: string }>): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Open Secondary Tag Modal',
    name: props.contentTag,
  });
}

export function trackTagModalOpened({
  props,
  tracking,
}: Context<{ tag: string }>): void {
  tracking.trackEvent({
    category: TRACKING_CATEGORY.buttonClick,
    action: 'Open Tag Modal',
    name: props.tag,
  });
}
