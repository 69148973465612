import { sequence, props, state } from 'cerebral';
import { set, when, merge } from 'cerebral/factories';

import { setUrlAction } from 'common/appContainer/module/factories';
import { clearUrl } from 'common/appContainer/module/actions.js';

import * as actions from './actions';

const urlActions = [
  sequence('accountUrlActions', [
    set(state`account.timeline.explanationModalShowing`, false),
    when(props`action`, action => action && action.indexOf('challenge') !== -1),
    {
      true: [
        set(state`account.timeline.explanationModalShowing`, props`action`),
      ],
      false: [],
    },
  ]),
];

export const render = [
  urlActions,
  actions.getUserCampaigns,
  set(state`account.timeline.userCampaigns`, props`userCampaigns`),
  actions.getCampaigns,
  set(state`account.timeline.campaigns`, props`campaigns`),
  merge(state`app.campaigns`, props`campaigns`),
  set(state`account.timeline.loaded`, true),
  actions.setDefaultItemToShow,
];

export const toggleShowTimelineSubItems = [actions.toggleShowTimelineSubItems];

export const redirectToTool = [actions.redirectToTool];

export const redirectToCampaignPage = [actions.redirectToCampaignPage];

export const showExplanationModal = [
  when(state`account.timeline.explanationModalShowing`),
  {
    true: clearUrl,
    false: [setUrlAction(props`toShow`)],
  },
];
