import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { createUser } from './helpers';
import { FirebaseProviderAuthenticationError } from './errors';

export default function signInWithGoogle(auth, options) {
  const scopes = options.scopes || [];
  const redirect = options.redirect || false;
  const provider = new GoogleAuthProvider();

  scopes.forEach(scope => {
    provider.addScope(scope);
  });

  return new Promise((resolve, reject) => {
    if (redirect) {
      signInWithRedirect(auth, provider);
      resolve();
    } else {
      signInWithPopup(auth, provider)
        .then(result => {
          resolve({ user: createUser(result.user) });
        })
        .catch(error => {
          reject(new FirebaseProviderAuthenticationError(error));
        });
    }
  });
}
