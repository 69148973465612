import { Provider } from 'cerebral';

function initSW(updateCallback) {
  navigator.serviceWorker
    .register('/serviceWorker.js', {
      scope: '/',
    })
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (!installingWorker) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            // `navigator.serviceWorker.controller` should return an object if there
            // is a service worker with state 'activating' or 'activated', otherwise null
            if (navigator.serviceWorker.controller) {
              updateCallback &&
                updateCallback(Boolean(navigator.serviceWorker.controller));
            }
          }
        };
      };
    })
    .catch(err => {
      console.warn('ServiceWorker registration failed: ', err); // eslint-disable-line no-console
    });

  // check for updates immediately…
  function checkUpdates() {
    navigator.serviceWorker.getRegistrations().then(regs => {
      regs.forEach(reg => {
        if (reg.active) reg.update();
      });
    });
  }
  checkUpdates();

  // …and regularly since there won't be many page refreshes
  setInterval(
    () => {
      checkUpdates();
    },
    1000 * 60 * 60 * 2
  ); // 2 hours
}

function ServiceWorkerProvider() {
  return new Provider({
    /**
     * Initializes a serviceWorker and register the update callback
     * @param {function} updateCallback Function that should be called when the serviceWorker finds an updated asset
     */
    install(updateCallback) {
      if ('serviceWorker' in navigator) {
        // initialize serviceWorker after document is done loading
        if (document.readyState !== 'loading') {
          initSW(updateCallback);
        } else {
          document.addEventListener('DOMContentLoaded', () => {
            initSW(updateCallback);
          });
        }
      }
    },
    /**
     * Forces update serviceWorker on all tabs
     */
    updateAll() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(regs =>
          regs.forEach(reg => {
            if (reg.waiting) reg.waiting.postMessage({ type: 'SKIP_WAITING' });
          })
        );
      }
    },
  });
}

export default ServiceWorkerProvider;
