import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';

export default Compute(get => {
  const activitiesCount = get(
    state.challenge.stats.total.challenge.activitiesCount
  );

  return Object.keys(activitiesCount || {})
    .filter(key => activitiesCount[key] > 0)
    .sort((a, b) => (activitiesCount[b] || 0) - (activitiesCount[a] || 0))
    .slice(0, 5)
    .map(activityKey => ({
      activityKey,
      timesLogged: activitiesCount[activityKey],
    }));
});
