import { signOut } from 'providers/Firebase/src/operators';
import { sequence, state, props } from 'cerebral';
import { set, when, equals, toggle } from 'cerebral/factories';
import * as actions from './actions';

import { isValidForm, setField } from 'forms/operators';
import { rendered, setUrlAction } from 'common/appContainer/module/factories';
import * as authActions from 'common/appContainer/module/modules/auth/actions.js';

import { renderPage } from './factories';

export const changeChallengeEmailSetting = [
  set(state`account.isSavingProfile`, true),
  setField(state`account.settingsForm.sendChallengeEmail`, props`value`),
  actions.changeChallengeEmailSetting,
  set(state`account.isSavingProfile`, false),
];

export const saveProfile = [
  set(state`account.isSavingProfile`, true),
  actions.saveProfile,
  set(state`account.isSavingProfile`, false),
  set(state`account.profileFormIsPristine`, true),
];

export const populateForms = [
  when(state`appContainer.user.uid`),
  {
    true: [
      setField(
        state`account.profileForm.firstName`,
        state`appContainer.profiles.${state`appContainer.user.uid`}.firstName`
      ),
      setField(
        state`account.profileForm.lastName`,
        state`appContainer.profiles.${state`appContainer.user.uid`}.lastName`
      ),
      setField(
        state`account.profileForm.email`,
        state`appContainer.profiles.${state`appContainer.user.uid`}.email`
      ),
      when(
        state`appContainer.profiles.${state`appContainer.user.uid`}.avatarImage`
      ),
      {
        true: [
          setField(
            state`account.profileForm.avatarImage`,
            state`appContainer.profiles.${state`appContainer.user.uid`}.avatarImage`
          ),
        ],
        false: [],
      },
      when(
        state`appContainer.profiles.${state`appContainer.user.uid`}.emails`,
        emails => emails && 'challengeEmails' in emails
      ),
      {
        true: [
          setField(
            state`account.settingsForm.sendChallengeEmail`,
            state`appContainer.profiles.${state`appContainer.user.uid`}.emails.challengeEmails.consent`
          ),
        ],
        false: [],
      },
    ],
    false: [],
  },
];

export const urlActions = [
  sequence('accountUrlActions', [
    set(state`account.showDeleteAccountModal`, false),
    equals(props`action`),
    {
      deleteAccount: set(state`account.showDeleteAccountModal`, true),
      otherwise: [],
    },
  ]),
];

export const renderProfileInfo = [
  rendered([
    actions.setReturnUrl,
    set(state`account.active`, 'profile'),
    urlActions,
    populateForms,
  ]),
];

export const setFormField = setField(state`${props`path`}`, props`value`);

export const profileFieldChanged = [
  setField(state`${props`path`}`, props`value`),
  set(state`account.profileFormIsPristine`, false),
];

export const updateTab = set(state`account.active`, props`tab`);

export const uploadAvatar = [
  set(state`account.isUploading`, true),
  actions.uploadAvatar,
  {
    success: [
      set(
        state`appContainer.profiles.${state`appContainer.user.uid`}.avatarImage`,
        props`response`
      ),
      set(state`account.profileForm.avatarImage.value`, props`response`),
    ],
    error: [],
  },
  set(state`account.isUploading`, false),
];

export const removeAvatar = [
  set(state`account.profileForm.avatarImage.value`, null),
  set(state`account.isSavingProfile`, true),
  actions.saveProfile,
  set(state`account.isSavingProfile`, false),
];

export const showDeleteAccountModal = setUrlAction('deleteAccount');

export const verifiedDeleteAccount = [
  set(state`account.isDeletingAccount`, true),
  actions.deleteAccount,
  actions.stopAllDatabaseListeners,
  signOut(),
  actions.setAccountDeletedSnackbarMessage,
  set(state`appContainer.user`, null),
  set(props`noRedirect`, true),
  authActions.navigateToRegister,
  set(state`account.isDeletingAccount`, false),
];

export const deleteFieldChanged = [
  setField(state`account.verifyDeletionForm.verifyText`, props`value`),
  isValidForm(state`account.verifyDeletionForm`),
  {
    true: set(state`account.isValidDeletionForm`, true),
    false: set(state`account.isValidDeletionForm`, false),
  },
];

export const feedbackFieldChanged = [
  setField(state`account.verifyDeletionForm.feedbackText`, props`value`),
];

export const changeFeedbackValue = [
  when(
    state`account.verifyDeletionForm.feedbackChecked.value`,
    props`value`,
    (currentFeedback, updatedFeedback) => currentFeedback === updatedFeedback
  ),
  {
    true: setField(state`account.verifyDeletionForm.feedbackChecked`, ''),
    false: setField(
      state`account.verifyDeletionForm.feedbackChecked`,
      props`value`
    ),
  },
];

export const resetPassword = [
  actions.resetPassword,
  {
    error: [actions.promptError],
    success: [set(state`account.passwordSetSuccessfully`, true)],
  },
];

export const createNewPassword = [
  set(state`account.isSubmittingNewPassword`, true),
  actions.createNewPassword,
  {
    error: [
      equals(props`error.code`),
      {
        'auth/wrong-password': [set(state`account.newPasswordError`, 'E102')],
        'auth/too-many-requests': [
          set(state`account.newPasswordError`, 'E901'),
        ],
        otherwise: [set(state`account.newPasswordError`, 'E900')],
      },
    ],
    success: [set(state`account.newPasswordSetSuccessfully`, true)],
  },
  set(state`account.isSubmittingNewPassword`, false),
];

export const goToChallenge = actions.redirectToChallengeLandingPage;

export const verifyExternalLink = [
  set(state`account.passwordSetSuccessfully`, false),
  set(state`account.oobError`, null),
  actions.storeQueryParams,
  equals(props`mode`),
  {
    resetPassword: [
      actions.verifyCode,
      {
        valid: [],
        invalid: actions.promptError,
      },
      renderPage('resetPassword'),
    ],
    verifyEmail: [
      authActions.getContinueUrl,
      actions.setRedirectUrl,
      actions.verifyEmail,
      {
        success: [],
        error: [authActions.setAuthenticationError, actions.promptError],
      },
      actions.reloadUser,
      renderPage('verifyConfirmEmail'),
    ],
    otherwise: [renderPage('invalidLink')],
  },
];

export const redirectIfHasUser = [
  when(state`appContainer.user.uid`),
  {
    true: [authActions.getContinueUrl, authActions.redirectToUrl],
    false: [set(state`account.pageLoaded`, true)],
  },
];

export const renderForgotPassword = [
  set(state`account.hasSentPasswordEmail`, false),
  actions.setQueryParams,
  actions.fillForm,
];

export const renderConfirmEmail = [
  actions.hasConfirmedEmail,
  {
    true: [],
    false: [actions.waitForConfirmation],
  },
];

export const redirectToContinueUrl = [
  authActions.getContinueUrl,
  authActions.redirectToUrl,
];

export const toggleDeleteForm = [toggle(state`account.showDeleteForm`)];

export const newPasswordFieldChanged = [
  setField(state`${props`field`}`, props`value`),
  set(state`account.newPasswordError`, null),
];

export const changeOnboardingIndex = [
  set(state`account.currentOnboardingIndex`, props`newIndex`),
];

export const updateHasSeenOnboarding = [
  set(state`account.hasSeenOnboarding`, true),
];

export const toggleLanguageList = [toggle(state`account.showLanguageList`)];
