export const enum CHALLENGE_EVENT_TYPE {
  NOTIFICATION = 'notification',
  POSITION_CHANGE = 'positionChange',
  CHALLENGE_START = 'challengeStart',
  CHALLENGE_END = 'challengeEnd',
  PARTICIPANT_JOINED = 'participantJoined',
  DAILY_SUMMARY_UPDATES = 'dailySummaryUpdates',
  GOALS_REACHED = 'goalsReached',
  GOALS_SET = 'goalsSet',
  QUIZ_COMPLETED = 'quizCompleted',
}
