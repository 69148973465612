export const enum TAG_CATEGORY {
  consumption = 'consumption',
  energy = 'energy',
  food = 'food',
  foodWaste = 'food_waste',
  plastic = 'plastic',
  sustainableFood = 'sustainable_food',
  transport = 'transport',
  tutorial = 'tutorial',
  handprint = 'handprint',
  microplastics = 'microplastics',
  overuse = 'overuse',
  plasticOnTheGo = 'plastic_on_the_go',
  sdg3 = '3',
  sdg6 = '6',
  sdg8 = '8',
  sdg9 = '9',
  sdg10 = '10',
  sdg11 = '11',
  sdg12 = '12',
  sdg13 = '13',
  sdg14 = '14',
  sdg15 = '15',
}
