import { getAllResponseHeaders } from './utils';
import HttpProviderError from './HttpProviderError';

function parseResponse(xhr) {
  try {
    return {
      status: xhr.status,
      result: JSON.parse(xhr.responseText),
    };
  } catch (e) {
    throw new HttpProviderError(
      xhr.status,
      getAllResponseHeaders(xhr),
      xhr.responseText,
      e.message
    );
  }
}

export default function fileUpload(options) {
  if (!options.url) {
    console.warn('upload-controller: you must provide a url'); // eslint-disable-line no-console

    return;
  }
  this.isAborted = false;
  this.xhr = new XMLHttpRequest();
  this.abort = function abort() {
    this.isAborted = true;
    this.xhr && this.xhr.abort();
  };

  this.send = function send(files) {
    const fileUploadInstance = this; // eslint-disable-line consistent-this, @typescript-eslint/no-this-alias
    const { xhr } = this;

    fileUploadInstance.isAborted = false;

    return new Promise((resolve, reject) => {
      if (
        files &&
        (files instanceof FileList || files.length || files instanceof File)
      ) {
        const formData = new FormData();

        if (files instanceof FileList || files.length) {
          for (let i = 0; i < files.length; i++) {
            formData.append(options.name || 'files', files[i]);
          }
        } else {
          formData.append(options.name || 'files', files);
        }

        if (options.data) {
          Object.keys(options.data).forEach(paramKey => {
            formData.append(paramKey, options.data[paramKey]);
          });
        }

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
            resolve(parseResponse(xhr));
          } else if (xhr.readyState === 4) {
            const response = parseResponse(xhr);

            response.isAborted = fileUploadInstance.isAborted;
            reject(
              new HttpProviderError(
                xhr.status,
                getAllResponseHeaders(xhr),
                xhr.responseText,
                null
              )
            );
          }
        };

        xhr.open('POST', options.url, true);

        if (options.headers) {
          Object.keys(options.headers).forEach(key => {
            xhr.setRequestHeader(key, options.headers[key]);
          });
        }

        xhr.upload.onprogress = e => {
          if (options.onProgress) {
            const percentComplete = (e.loaded / e.total) * 100;

            options.onProgress({
              progress: Number(percentComplete.toFixed(0)),
            });
          }
        };

        xhr.send(formData);
      } else {
        reject(new Error('Not an instance of a File, File[] or FileList'));
      }
    });
  };
}
