import dayjs from 'dayjs';
import UAParser from 'ua-parser-js';

import { TotalStats, DailyStats } from 'common/types';

import indicators from 'toolData/indicators';
import { primaryLanguage, secondaryLanguages } from 'languages/config';

const MILLISECONDS_IN_A_DAY = 86400000; // 1000 * 60 * 60 * 24
const MILLION = 1000000;

// Returns number of days in milliseconds
export function getDaysInMilliseconds(days = 1) {
  return MILLISECONDS_IN_A_DAY * days;
}

// Number of full 24hour days between, ignoring clock changes
export function getFullDaysDiff(fromDatetime, toDatetime = Date.now()) {
  if (typeof fromDatetime !== 'number' || typeof toDatetime !== 'number') {
    throw new Error('getFullDaysDiff requires two EPOCH values');
  }

  let days = 0;

  let nextDay = fromDatetime + getDaysInMilliseconds(1);

  while (nextDay <= toDatetime) {
    days += 1;
    nextDay += getDaysInMilliseconds(1);
  }

  return days;
}

// Returns the timestamp of when final day starts, honoring
// 24-hour full days
export function getLastDayTimestamp(fromDatetime, toDatetime = Date.now()) {
  const daysDiffer = getFullDaysDiff(fromDatetime, toDatetime);

  return fromDatetime + getDaysInMilliseconds(daysDiffer);
}

export function getCurrentDatetime(startOfDays) {
  return Math.max(
    startOfDays[0] || 0,
    ...startOfDays.filter(datetime => datetime <= Date.now())
  );
}

export function addLeadingZero(number = 0) {
  return number >= 10 ? `${number}` : `0${number}`;
}

export function getDateAsInputFormat(datetime = Date.now()) {
  const dateObject = new Date(datetime);
  const year = dateObject.getFullYear();
  const month = addLeadingZero(dateObject.getMonth() + 1);
  const date = addLeadingZero(dateObject.getDate());

  return `${year}-${month}-${date}`;
}

interface SumObject {
  [key: string]: SumObject | number;
}

export function sum(obj: SumObject, prop?: string): number {
  function sumObj(objToSum: SumObject, currentSum: number) {
    return Object.keys(objToSum).reduce((totalSum, key) => {
      const newValue = objToSum[key];

      if (typeof newValue === 'number') {
        if (prop === undefined || key === prop) {
          return totalSum + (newValue || 0);
        }

        return totalSum;
      }

      return sumObj(newValue, totalSum);
    }, currentSum);
  }

  return sumObj(obj, 0);
}

export function toCo2(value = 0) {
  return Number(value.toFixed(2));
}

export function toNumber(value = 0) {
  return Number(value.toFixed(0));
}

export function toCo2Kg(co2Kg = 0, decimals = 2) {
  return Number((co2Kg / 1000000).toFixed(decimals));
}

export function titleize(str?: string): string {
  return (
    str?.toLowerCase().replace(/(?:^|\s|-)\S/gu, c => c.toUpperCase()) ?? ''
  );
}

function isObject(obj) {
  return obj === Object(obj);
}

/**
 * Format the given number (or string) according to the given locale and precision
 *
 * @param {number | any} data
 * @param {{[locale]: string, [precision]: number}} config
 * @returns {string}
 */
export function formatNumber(data, config) {
  if (typeof data === 'number') {
    if (window && window.Intl) {
      return new Intl.NumberFormat(config.locale || 'nb-NO', {
        maximumFractionDigits: config.precision || 0,
        minimumFractionDigits: config.precision || 0,
      }).format(data);
    }

    return Number(data).toFixed(config.precision || 0);
  }

  if (typeof data === 'string') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(el => formatNumber(el, config));
  }

  if (isObject(data)) {
    return Object.assign(
      {},
      ...Object.entries(data).map(([key, value]) => ({
        [key]: formatNumber(value, config),
      }))
    );
  }

  return data;
}

export function getIndicator(
  data: { [key: string]: number } | TotalStats | DailyStats,
  indicator: string,
  prefix?: string
): number {
  if (!data) {
    return 0;
  }
  const fullPath = prefix
    ? prefix + indicator.substring(0, 1).toUpperCase() + indicator.substring(1)
    : indicator;

  return Math.max(0, data[fullPath] || 0) / MILLION;
}

type IndicatorConfig = {
  color: string;
  icon: string;
  precision: number;
  unitPath?: string;
};

export function getIndicatorConfig(indicator: string): IndicatorConfig {
  if (indicator in indicators) {
    return indicators[indicator];
  }

  return indicators.default;
}

export function formatProfile(profileObject) {
  if (!profileObject) return null;

  return {
    ...profileObject,
    ...(profileObject.public || {}),
  };
}

/**
 * Get the app url for a given path with language sub-path
 *
 * Ex: '/profile' and 'no' yields 'https://app.ducky.eco/profile'
 *
 * Ex: '/profile' and 'en' yields 'https://app.ducky.eco/en/profile
 *
 * @param {string} appUrl The domain, ex: https://app.ducky.eco
 * @param {string} path The path, ex: '/profile'
 * @param {string} language The language, either no, en or ja
 * @returns {string} The full url
 */
export function createAppURL(appUrl, language, path) {
  const languagePath = language === primaryLanguage ? `` : `/${language}`;

  return `${appUrl}${languagePath}${path}`;
}

type FormData = {
  [key: string]: FormData | number;
  value?: number;
};

type FormDataResult = {
  [key: string]: FormData | number;
};

/**
 * Returns an object where itself of any sub-object with a 'value' property is replaced by its value
 *
 * Ex: '{car: {value: 4}}' gives '{car: 4}'
 */
export function formToJSON(data: FormData): FormDataResult | number {
  if ('value' in data) return data.value;

  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      typeof value === 'number' ? value : formToJSON(value),
    ])
  );
}

/**
 * Returns true if the date is 1 minute past midnight in the current timezone
 */
export function datetimeIsStartOfDay(datetime: number): boolean {
  const date = dayjs(datetime);

  return date.hour() === 0 && date.minute() === 1;
}

/**
 * Returns the current URL with a new language prefix
 */
export function getLanguageUrl(
  currentLanguage: string,
  language: string
): string {
  const path = location.pathname
    .split('/')
    .slice(primaryLanguage === currentLanguage ? 1 : 2)
    .join('/');

  const query = location.href.split('?');

  query.shift();

  const urlQuery = query.join('?');

  const urlLanguage = language === primaryLanguage ? '/' : `/${language}/`;

  return `${urlLanguage}${path}${urlQuery ? `?${urlQuery}` : ''}`;
}

/**
 * Returns true if the given code consists of exactly 6 numbers 0-9
 */
export function isValidCampaignCode(code: string): boolean {
  return /^\d{6}$/u.test(code);
}

/**
 * Returns the current language based on the URL
 */
export function getLanguage(): { language: string; isPrimary: boolean } {
  const pathName = window.location.pathname;

  const secondary = secondaryLanguages.find(
    lang => pathName.startsWith(`/${lang}/`) || pathName === `/${lang}`
  );

  if (secondary) return { language: secondary, isPrimary: false };

  return { language: primaryLanguage, isPrimary: true };
}

/**
 * Returns true if the current device is a mobile phone
 */
export function checkIsMobile(): boolean {
  const parser = new UAParser();

  const { type } = parser.getDevice();

  return type === 'mobile';
}

export const partition = <T>(
  array: T[] = [],
  filter: (element: T, index: number, array: T[]) => boolean
) => {
  const pass = [],
    fail = [];

  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));

  return [pass, fail];
};
