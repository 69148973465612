import { listenTo, createRef } from './helpers';

export default function onChildChangedFactory(database) {
  return function onChildChangedFunction(path, signal, options = {}) {
    listenTo(
      createRef(database, path, options),
      path,
      'child_changed',
      signal,
      data => {
        this.context.app.getSequence(signal)({
          key: data.key,
          value: data.val(),
          ...options.payload,
        });
      }
    );
  };
}
