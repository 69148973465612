import { parallel, set, when } from 'cerebral/factories';
import { state, props } from 'cerebral';

import * as actions from './actions';

export const handlePushNotifications = [
  when(state`challenge.notifications.hasSetPushNotificationToken`),
  {
    true: [],
    false: [
      actions.getPushNotificationToken,
      when(props`notificationToken`),
      {
        true: [
          actions.setPushNotificationToken,
          when(state`challenge.userCampaign`),
          {
            true: [parallel([actions.setTeamHasLoggedPushNotification])],
            false: [],
          },
        ],
        false: [],
      },
      set(state`challenge.hasSetPushNotificationToken`, true),
    ],
  },
];
