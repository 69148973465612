import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getGoalValues } from 'utils/challenge';

export default Compute(get => {
  const maxScore = get(state.challenge.maxScore) || 0;
  const goals = get(state.challenge.userChallenge.goals) || {};
  const currentScore =
    get(
      state.challenge.stats.total.profiles[state.appContainer.user.uid][
        state.challenge.campaign.indicator
      ]
    ) || 0;

  return getGoalValues(maxScore).map((score, index) => {
    const level = index + 1;

    return {
      level: level,
      score: score / 1_000_000,
      canBeSet:
        Object.values(goals)
          .filter(goal => goal.achieved)
          .every(goal => goal.level < level) && score > currentScore,
    };
  });
});
