import { signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import { createUser } from './helpers';
import { FirebaseProviderAuthenticationError } from './errors';

export default function signInWithCustomTokenFactory(auth, token) {
  return new Promise((resolve, reject) => {
    signInWithCustomToken(auth, token)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
          unsubscribe();
          resolve({
            user: createUser(user),
          });
        });
      })
      .catch(error => {
        reject(new FirebaseProviderAuthenticationError(error));
      });
  });
}
