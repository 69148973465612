export function getDefaultAvatar(staticUrl: string, name: string): string {
  const avatarArray = [
    `${staticUrl}/images/avatars/avatar_blue.png`,
    `${staticUrl}/images/avatars/avatar_blueLight.png`,
    `${staticUrl}/images/avatars/avatar_green.png`,
    `${staticUrl}/images/avatars/avatar_greenLight.png`,
    `${staticUrl}/images/avatars/avatar_orange.png`,
    `${staticUrl}/images/avatars/avatar_pink.png`,
    `${staticUrl}/images/avatars/avatar_purple.png`,
    `${staticUrl}/images/avatars/avatar_red.png`,
    `${staticUrl}/images/avatars/avatar_yellow.png`,
    `${staticUrl}/images/avatars/avatar_yellowLight.png`,
  ];

  if (!name) {
    return avatarArray[0];
  }

  const randomProfileNumber = name.charCodeAt(name.length - 1);

  return avatarArray[randomProfileNumber % avatarArray.length];
}

export function getDefaultAvatarColor(name: string): string {
  const colorArray = [
    '#3e51b5',
    '#01bcd4',
    '#4baf51',
    '#8cc34a',
    '#ff9800',
    '#e91e63',
    '#9b27b0',
    '#ff5721',
    '#ffc106',
    '#cddc39',
  ];

  if (!name) {
    return colorArray[0];
  }

  const randomProfileNumber = name.charCodeAt(name.length - 1);

  return colorArray[randomProfileNumber % colorArray.length];
}

export function isUrl(url: string): boolean {
  if (url && url.substr(0, 7) === 'avatars') {
    return url && url.substr(8, 4) === 'http';
  }

  return url && url.substr(0, 4) === 'http';
}

export const getAvatarUrl = (
  staticUrl: string,
  profile?: { name: string; avatarImage?: { large: string; small: string } }
): { large: string; small: string } => {
  if (!profile) {
    return {
      small: `${staticUrl}/images/avatars/avatar_gray.png`,
      large: `${staticUrl}/images/avatars/avatar_gray.png`,
    };
  }

  if (!profile.avatarImage) {
    return {
      small: getDefaultAvatar(staticUrl, profile.name),
      large: getDefaultAvatar(staticUrl, profile.name),
    };
  }

  return isUrl(profile.avatarImage.small)
    ? {
        small: profile.avatarImage.small,
        large: profile.avatarImage.large,
      }
    : {
        small: `avatars/${profile.avatarImage.small}`,
        large: `avatars/${profile.avatarImage.large}`,
      };
};
