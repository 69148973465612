import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import computedGoals from 'challenge/computed/goals';
import computedCurrentGoal from 'challenge/computed/currentGoal';

import { OwnState } from './types';

const state: OwnState = {
  moreInfoExpanded: false,
  showCo2ActivitiesInfoModal: false,
  showExpandedNumberInput: false,
  showIndicatorModal: false,
  showStreakModal: false,
  showNpsModal: false,
  showGoalModal: false,
  showLeaderboardModal: false,
  showBacklogTip: false,
  isTeamProfilesListExpanded: false,
  showAddToHomeScreen: false,
  showFeedbackTextArea: false,
  isSendingFeedback: false,
  feedbackSent: false,
  seenApiInfo: false,
  statusBar: {
    show: false,
    text: 'reminders',
    buttonText: 'read_more',
    icon: 'icon-notifications_none',
  },
  npsForm: {
    score: null,
    comment: '',
  },
  computedCurrentGoal,
  computedGoals,
};

const module: ModuleDefinition = {
  state,
  sequences: {
    toggleMoreInfoClicked: sequences.toggleMoreInfo,
    indicatorModalClicked: sequences.setIndicatorModalUrl,
    streakModalClicked: sequences.setStreakModalUrl,
    goalModalClicked: sequences.setGoalModalUrl,
    leaderboardModalClicked: sequences.setLeaderboardModalUrl,
    toggleExpandedTeamProfilesList: sequences.toggleTeamProfileList,
    closeAddToHomeScreenClicked: sequences.hideAddToHomeScreen,
    saveGoalClicked: sequences.saveGoal,
    closeGoalRecognitionClicked: sequences.closeGoalRecognition,
    setNpsScore: sequences.setNpsScore,
    npsScoreChanged: sequences.npsScoreChanged,
    npsCommentChanged: sequences.npsCommentChanged,
    hideFeedbackTextArea: sequences.hideFeedbackTextArea,
    dismissNps: sequences.dismissNps,
    setSeenApiInfo: sequences.setSeenApiInfo,
  },
};

export default module;
