import { state, props, string } from 'app/pwa/app.cerebral';
import { set, when, toggle, sequence } from 'cerebral/factories';

import { value } from 'providers/Firebase/src/operators';

import { setUrlAction } from 'common/appContainer/module/factories';
import { clearUrl } from 'common/appContainer/module/actions.js';

import * as actions from './actions';

type ShowTagModalProps = {
  tag: string;
  openQuiz?: boolean;
  activityModalKey?: string;
};

export const showTagModal = sequence<ShowTagModalProps>('Show Tag Modal', [
  set(state.appContainer.shouldResetScroll, false),
  set(state.challenge.tagModal.showQuiz, false),
  when(props`openQuiz`),
  {
    true: [set(state.challenge.tagModal.shouldOpenQuiz, true)],
    false: [],
  },
  set(state.challenge.log.previousActivityModalKey, props`activityModalKey`),
  when(props`activityModalKey`),
  {
    true: [set(state.challenge.tagModal.key, props`tag`)],
    false: [],
  },
  setUrlAction(string`tagModal:${props`tag`}`),
  actions.trackTagModalOpened,
]);

export const getUserChallenge = sequence('Get userChallenge', [
  value(
    string`challenges.userChallenges.${state.challenge.key}.${state.appContainer.user.uid}`
  ),
  when(props`response.value`),
  {
    true: [set(state.challenge.userChallenge, props`response.value`)],
    false: [],
  },
]);

export const closeTagModal = sequence('Close Tag Modal', [
  set(state.challenge.tagModal.show, false),
  set(state.challenge.tagModal.key, null),
  set(state.challenge.log.previousActivityModalKey, null),
  set(state.challenge.tagModal.infoType, null),
  set(state.challenge.tagModal.contentTag, null),
  set(state.challenge.tagModal.showSecondaryInfo, false),
  set(state.challenge.tagModal.showQuiz, false),
  getUserChallenge,
  when(state.app.isMobile),
  {
    true: [],
    false: [set(state.appContainer.shouldResetScroll, false)],
  },
  clearUrl,
]);

export const goToActivities = sequence('Go To Activities', [
  set(state.challenge.log.selectedTag, state.challenge.tagModal.key),
  closeTagModal,
]);

type ToggleShowSecondaryInfoProps = {
  type?: string;
  contentTag?: string;
};

export const toggleShowSecondaryInfo =
  sequence<ToggleShowSecondaryInfoProps | void>('Toggle Show Secondary Info', [
    set(state.challenge.tagModal.infoType, props`type`),
    set(state.challenge.tagModal.contentTag, props`contentTag`),
    toggle(state.challenge.tagModal.showSecondaryInfo),
    when(state.app.isMobile),
    {
      true: [actions.resetScroll],
      false: [],
    },
    when(state.challenge.tagModal.showSecondaryInfo),
    {
      true: [actions.trackSecondaryTagModalOpened],
      false: [],
    },
  ]);

type QuizAnswerSelectedProps = {
  category: string;
  question: string;
  answer: string;
};

export const quizAnswerSelected = sequence<QuizAnswerSelectedProps>(
  'Quiz Answer Selected',
  [
    set(state.challenge.tagModal.answerIsSaving, true),
    actions.saveQuizAnswer,
    getUserChallenge,
    set(state.challenge.tagModal.answerIsSaving, false),
  ]
);

const updateQuizStats = sequence('Update Quiz Stats', [
  when(state.challenge.tagModal.quizPageIndex, index => index === 0),
  {
    true: [
      actions.getQuizStats,
      set(state.challenge.tagModal.quizStats, props`quizStats`),
      when(state.app.isMobile),
      {
        true: [actions.resetScroll],
        false: [],
      },
    ],
    false: [],
  },
]);

export const quizContinueClicked = sequence('Quiz Continue Clicked', [
  actions.incrementQuizIndex,
  updateQuizStats,
]);

export const toggleQuiz = sequence('Toggle Quiz', [
  toggle(state.challenge.tagModal.showQuiz),
  when(state.challenge.tagModal.showQuiz),
  {
    true: [actions.setQuizState, updateQuizStats],
    false: [],
  },
]);

export const reviewQuiz = sequence('Review Quiz', [
  set(state.challenge.tagModal.quizPageIndex, 1),
]);
