import { Compute } from 'cerebral';
import { state } from 'app/pwa/app.cerebral';
import { getIndicator, sum } from 'utils/common';
import { getAvatarUrl } from 'utils/avatars';
import { INDICATOR } from 'resources/constants';
import { orderAndFormatLeaderboard } from 'utils/challenge';

export default Compute(get => {
  const uid = get(state.appContainer.user.uid);
  const profiles = get(state.appContainer.profiles);
  const teamMembers = get(state.challenge.teamMembers) || {};
  const profileScores = get(state.challenge.stats.total.profiles) || {};
  const indicator = get(state.challenge.campaign.indicator) || INDICATOR.co2;
  const staticUrl = get(state.appContainer.config.staticUrl);
  const leaderboardPage = get(state.challenge.visualisations.leaderboardPage);
  const leaderboardPageSize = get(
    state.challenge.visualisations.leaderboardPageSize
  );
  const leaderboardIsExpanded = get(
    state.challenge.visualisations.toplistExpanded
  );

  const leaderboard = Object.keys(teamMembers || {})
    .filter(
      profileKey =>
        profiles[profileKey] &&
        getIndicator(profileScores?.[profileKey], indicator) !== 0
    )
    .map(profileKey => ({
      key: profileKey,
      isEmphasised: profileKey === uid,
      name: profiles[profileKey].name,
      avatar: getAvatarUrl(staticUrl, profiles[profileKey]).small,
      avatarLarge: getAvatarUrl(staticUrl, profiles[profileKey]).large,
      activityCount: sum(profileScores[profileKey]?.activitiesCount || {}),
      value: getIndicator(profileScores?.[profileKey], indicator),
    }));

  return orderAndFormatLeaderboard(
    leaderboard,
    leaderboardPage,
    leaderboardPageSize,
    leaderboardIsExpanded
  );
});
