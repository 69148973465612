import { createTaskFactory } from './createTaskFactory';

/**
 * Run a heavy-resource task
 * @param functions
 * @returns {function(*, *=): Promise<T>}
 */
export default function createTask(functions) {
  return createTaskFactory(functions, 'handleHeavyTask');
}
