/* eslint-disable max-lines, no-shadow */
import { state, props, string } from 'cerebral';
import { set, toggle } from 'cerebral/factories';

import {
  setUrlAction,
  hasUrlAction,
} from 'common/appContainer/module/factories';
import { clearUrl } from 'common/appContainer/module/actions.js';

import * as actions from './actions';

export const beginTutorial = [
  set(state`appContainer.shouldResetScroll`, true),
  actions.scrollTo,
  set(state`challenge.currentTab`, 0),
  set(state`challenge.tutorial.show`, 1),
  hasUrlAction(`tutorial1`),
  {
    true: [],
    false: [setUrlAction('tutorial1')],
  },
];

export const increasePopUpNumber = [
  set(state`appContainer.shouldResetScroll`, false),
  actions.increasePopUpNumber,
  actions.checkPopUpNumber,
  hasUrlAction(string`tutorial${props`popUpNumber`}`),
  {
    true: [],
    false: [setUrlAction(string`tutorial${props`popUpNumber`}`)],
  },
  actions.scrollTo,
];

export const decreasePopUpNumber = [
  set(state`appContainer.shouldResetScroll`, false),
  actions.decreasePopUpNumber,
  actions.checkPopUpNumber,
  hasUrlAction(string`tutorial${props`popUpNumber`}`),
  {
    true: [],
    false: [setUrlAction(string`tutorial${props`popUpNumber`}`)],
  },
  actions.scrollTo,
];

export const endTutorial = [
  set(state`appContainer.shouldResetScroll`, false),
  set(state`challenge.tutorial.tutorialActivityLogged`, false),
  set(state`challenge.log.selectedTag`, 'all'),
  set(state`challenge.tutorial.showOutroModal`, true),
  hasUrlAction(`outroModal`),
  {
    true: [],
    false: [setUrlAction('outroModal')],
  },
];

export const toggleLogTutorialActivity = [
  toggle(state`challenge.tutorial.tutorialActivityLogged`),
  increasePopUpNumber,
];

export const expandIntroModal = [
  toggle(state`challenge.tutorial.showExpandedIntro`),
];

export const closeOutroModal = [set(state`challenge.currentTab`, 4), clearUrl];
