import { ModuleDefinition } from 'cerebral';
import * as sequences from './sequences';
import { renderPage } from './factories';
import { OwnState } from './types';

import joinCampaign from './modules/joinCampaign';
import timeline from './modules/timeline';
import { redirectToLogin } from 'common/appContainer/module/modules/auth/sequences';

const state: OwnState = {
  showDeleteForm: false,
  showLanguageList: false,
  pageLoaded: false,
  hasSeenOnboarding: false,
  currentOnboardingIndex: 0,
  isDeletingAccount: false,
  isUploading: false,
  isSavingProfile: false,
  isSavingSettings: false,
  isSendingConfirmEmail: false,
  isSendingPasswordEmail: false,
  registrationSuccessful: false,
  passwordSetSuccessfully: false,
  sentResetPassword: false,
  deactivated: {
    profile: false,
    account: false,
    settings: false,
  },
  profileForm: {
    firstName: {
      value: '',
      defaultValue: '',
      validationRules: ['isValue', 'minLength:1'],
    },
    lastName: {
      value: '',
      defaultValue: '',
    },
    email: {
      value: '',
      defaultValue: '',
      validationRules: ['isEmail', 'isValue'],
    },
    avatarImage: {
      value: null,
      defaultValue: null,
    },
  },
  settingsForm: {
    sendChallengeEmail: {
      value: false,
      defaultValue: false,
    },
    sendMarketingEmail: {
      value: false,
      defaultValue: false,
    },
    sendBlogEmail: {
      value: false,
      defaultValue: false,
    },
  },
  isSubmittingNewPassword: false,
  newPasswordError: null,
  newPasswordForm: {
    oldPassword: {
      value: '',
      defaultValue: '',
      validationRules: ['isValue'],
    },
    newPassword: {
      value: '',
      defaultValue: '',
      validationRules: ['minLength:8', 'isValue'],
    },
    repeatPassword: {
      value: '',
      defaultValue: '',
      validationRules: ['equalsField:account.newPasswordForm.newPassword'],
    },
  },
  showDeleteAccountModal: false,
  verifyDeletionForm: {
    feedbackChecked: {
      value: '',
      defaultValue: '',
    },
    feedbackText: {
      value: '',
      defaultValue: '',
    },
    verifyText: {
      value: '',
      defaultValue: '',
      validationRules: ['isValue', 'equalToWord:DELETE||SLETT'],
      isRequired: true,
    },
  },
  isValidDeletionForm: false,
  resetPasswordSuccess: false,
  oobCode: null,
  oobError: null,
  hasSentPasswordEmail: false,
  returnUrl: null,
  newPasswordSetSuccessfully: false,
  resetPasswordForm: {
    password: {
      value: '',
      defaultValue: '',
      validationRules: ['minLength:8', 'isValue'],
    },
  },
};

const module: ModuleDefinition = {
  state,
  modules: {
    joinCampaign,
    timeline,
  },
  sequences: {
    tabClicked: sequences.updateTab,
    fieldChanged: sequences.setFormField,
    saveProfileClicked: sequences.saveProfile,
    challengeEmailSettingChanged: sequences.changeChallengeEmailSetting,
    deleteAccountClicked: sequences.showDeleteAccountModal,
    verifiedDeleteAccountClicked: sequences.verifiedDeleteAccount,
    avatarFileSelected: sequences.uploadAvatar,
    avatarFileDeleted: sequences.removeAvatar,
    profileFieldChanged: sequences.profileFieldChanged,
    feedbackFieldChanged: sequences.feedbackFieldChanged,
    deleteFieldChanged: sequences.deleteFieldChanged,
    changeFeedbackValue: sequences.changeFeedbackValue,
    resetPassword: sequences.resetPassword,
    createNewPassword: sequences.createNewPassword,
    notFoundRendered: renderPage('notFound'),
    profileInfoRendered: renderPage('profileInfo', sequences.renderProfileInfo),
    profileDeleteRendered: renderPage('profileDelete'),
    profileLanguageRendered: renderPage('profileLanguage'),
    profilePreferencesRendered: renderPage('profilePreferences'),
    profilePasswordRendered: renderPage('profilePassword'),
    profileSupportRendered: renderPage('profileSupport'),
    profileTermsRendered: renderPage('profileTerms'),
    menuRendered: renderPage('menu'),
    goToChallenge: sequences.goToChallenge,
    forgotPasswordRendered: renderPage(
      'forgotPassword',
      sequences.renderForgotPassword
    ),
    confirmEmailRendered: renderPage(
      'confirmEmail',
      sequences.renderConfirmEmail
    ),
    passwordCreatedRendered: renderPage('passwordCreated'),
    registerRendered: renderPage('register', sequences.redirectIfHasUser),
    registerPasswordRendered: renderPage(
      'registerPassword',
      sequences.redirectIfHasUser
    ),
    loginRendered: renderPage('login', sequences.redirectIfHasUser),
    verifyExternalLink: sequences.verifyExternalLink,
    toggleDeleteForm: sequences.toggleDeleteForm,
    continueFromConfirmEmail: sequences.redirectToContinueUrl,
    continueFromSetPassword: redirectToLogin,
    newPasswordFieldChanged: sequences.newPasswordFieldChanged,
    changeOnboardingIndex: sequences.changeOnboardingIndex,
    getStartedClicked: sequences.updateHasSeenOnboarding,
    toggleLanguageList: sequences.toggleLanguageList,
  },
};

export default module;
