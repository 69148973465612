import axios from 'axios';
import { ApiActivitiesDataResponse } from '../types';
import * as Sentry from '@sentry/browser';

type callDuckyApiProps = {
  endpoint: string;
  firebaseUrl: string;
  duckyApiUrl: string;
};

export async function callDuckyAPI({
  endpoint,
  firebaseUrl,
  duckyApiUrl,
}: callDuckyApiProps): Promise<ApiActivitiesDataResponse> {
  const duckyApiAccessToken: string = await axios
    .get(`${firebaseUrl}/duckyApi/token.json`)
    .then(response => response.data)
    .catch(error =>
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(error);
      })
    );

  return axios
    .post(
      `${duckyApiUrl}${endpoint}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${duckyApiAccessToken}`,
        },
      }
    )
    .then(res => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error('Error while calling the Ducky API');
    })
    .catch(error =>
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(error);
      })
    );
}
