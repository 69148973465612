import { state } from 'cerebral';
import { getLastDayTimestamp } from 'utils/common';
import { CHALLENGE_EVENT_TYPE } from 'resources/constants';

function extractDailySummaryEvents(dailySummaryUpdates) {
  return Object.keys(dailySummaryUpdates).reduce(
    (dailySummaryUpdatesEvents, dayKey) => {
      const events = Object.keys(dailySummaryUpdates[dayKey]).map(
        profileKey => ({
          [`dailySummaryUpdates${profileKey}${dayKey}`]: {
            ...dailySummaryUpdates[dayKey][profileKey],
            profileKey,
            type: CHALLENGE_EVENT_TYPE.DAILY_SUMMARY_UPDATES,
          },
        })
      );

      return dailySummaryUpdatesEvents.concat(events);
    },
    []
  );
}

function extractPositionChangeEvents(positionChange) {
  return Object.keys(positionChange).reduce(
    (positionChangeEvents, dayKey) => ({
      [`positionChange${dayKey}`]: {
        ...positionChange[dayKey],
        type: CHALLENGE_EVENT_TYPE.POSITION_CHANGE,
      },
    }),
    []
  );
}

function extractGoalsReachedEvents(goalsReached) {
  return Object.keys(goalsReached).reduce((goalsReachedEvents, dayKey) => {
    const events = Object.keys(goalsReached[dayKey]).map(profileKey => ({
      [`goalsReached${profileKey}${dayKey}`]: {
        ...goalsReached[dayKey][profileKey],
        profileKey,
        type: CHALLENGE_EVENT_TYPE.GOALS_REACHED,
      },
    }));

    return goalsReachedEvents.concat(events);
  }, []);
}

function extractGoalsSetEvents(goalsSet) {
  return Object.keys(goalsSet).reduce((goalsSetEvents, dayKey) => {
    const events = Object.keys(goalsSet[dayKey]).map(profileKey => ({
      [`goalsSet${profileKey}${dayKey}`]: {
        ...goalsSet[dayKey][profileKey],
        profileKey,
        type: CHALLENGE_EVENT_TYPE.GOALS_SET,
      },
    }));

    return goalsSetEvents.concat(events);
  }, []);
}

export function getGlobalEvents({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);

  return firebase
    .value(`challenges.events.${challengeKey}.global`, {
      asArray: true,
    })
    .then(result => ({
      globalEvents: (result.value || []).map(item => ({
        [item.key]: item.value,
      })),
    }));
}

export function getTeamEvents({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const teamKey = get(state`challenge.userCampaign.teamKey`);

  return firebase
    .value(`challenges.events.${challengeKey}.teams.${teamKey}`, {
      asArray: true,
    })
    .then(result => ({
      teamEvents: (result.value || []).map(item => ({
        [item.key]: item.value,
      })),
    }));
}

export function getTeamStaticEvents({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const teamKey = get(state`challenge.userCampaign.teamKey`);
  const sectionKey = get(state`challenge.userCampaign.sectionKey`);

  return Promise.all([
    firebase.value(
      `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.dailySummaryUpdates`,
      {
        orderByKey: true,
      }
    ),
    firebase.value(
      `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.positionChange`,
      {
        orderByKey: true,
      }
    ),
    firebase.value(
      `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.goalsReached`,
      {
        orderByKey: true,
      }
    ),
    firebase.value(
      `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.goalsSet`,
      {
        orderByKey: true,
      }
    ),
  ]).then(results => {
    const [dailySummaryUpdates, positionChange, goalsReached, goalsSet] =
      results;
    const dailySummaryUpdatesEvents = extractDailySummaryEvents(
      (dailySummaryUpdates && dailySummaryUpdates.value) || {}
    );
    const positionChangeEvents = extractPositionChangeEvents(
      (positionChange && positionChange.value) || {}
    );
    const goalsReachedEvents = extractGoalsReachedEvents(
      (goalsReached && goalsReached.value) || {}
    );
    const goalsSetEvents = extractGoalsSetEvents(
      (goalsSet && goalsSet.value) || {}
    );

    return {
      teamStaticEvents: dailySummaryUpdatesEvents.concat(
        positionChangeEvents,
        goalsReachedEvents,
        goalsSetEvents
      ),
    };
  });
}

export function listenForEvents({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const { sectionKey, teamKey } = get(state`challenge.userCampaign`);
  const currentDayKey = getLastDayTimestamp(
    get(state`challenge.campaign.challenge.startDatetime`)
  );

  firebase.onChildAdded(
    `challenges.events.${challengeKey}.global`,
    'challenge.events.updated',
    {
      payload: {
        type: 'global',
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildAdded(
    `challenges.events.${challengeKey}.teams.${teamKey}`,
    'challenge.events.updated',
    {
      payload: {
        type: 'team',
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildChanged(
    `challenges.participantCounts.${challengeKey}`,
    'challenge.events.participantCountsUpdated'
  );

  firebase.onChildAdded(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.dailySummaryUpdates.${currentDayKey}`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.DAILY_SUMMARY_UPDATES,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildChanged(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.dailySummaryUpdates.${currentDayKey}`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.DAILY_SUMMARY_UPDATES,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildAdded(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.positionChange`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.POSITION_CHANGE,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildChanged(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.positionChange`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.POSITION_CHANGE,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildAdded(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.goalsReached.${currentDayKey}`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.GOALS_REACHED,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildChanged(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.goalsReached.${currentDayKey}`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.GOALS_REACHED,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildAdded(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.goalsSet.${currentDayKey}`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.GOALS_SET,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildAdded(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.quizCompleted`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.QUIZ_COMPLETED,
      },
      orderByChild: 'datetime',
    }
  );

  firebase.onChildChanged(
    `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.goalsSet.${currentDayKey}`,
    'challenge.events.staticEventsUpdated',
    {
      payload: {
        type: CHALLENGE_EVENT_TYPE.GOALS_SET,
      },
      orderByChild: 'datetime',
    }
  );
}

export function outputJoinedCount({ get }) {
  const messageType = get(state`appContainer.snackbar.message.type`);

  return {
    joinedCount:
      messageType === 'participantJoined'
        ? get(state`appContainer.snackbar.message.data.joinedCount`) + 1
        : 1,
  };
}

export function updateEvents({ store, props }) {
  store.set(state`challenge.events.events.${props.key}`, props.value);
}

export function updateStaticEvents({ get, store, props }) {
  const currentDayKey = getLastDayTimestamp(
    get(state`challenge.campaign.challenge.startDatetime`)
  );
  const updatedEvent = {
    profileKey: props.type !== 'positionChange' ? props.key : null,
    type: props.type,
    ...props.value,
  };

  const key = `${props.type}${
    props.type !== 'positionChange' ? `${props.key}` : ''
  }${currentDayKey}`;

  store.set(state`challenge.events.events.${key}`, updatedEvent);
}

export function getTodaysLatestDailySummaryEvent({ firebase, get }) {
  const challengeKey = get(state`challenge.key`);
  const { teamKey, sectionKey } = get(state`challenge.userCampaign`);
  const challengeStartDatetime = get(
    state`challenge.campaign.challenge.startDatetime`
  );
  const currentDayKey = getLastDayTimestamp(challengeStartDatetime);

  return firebase
    .value(
      `challenges.staticEvents.teams.${challengeKey}.${sectionKey}.${teamKey}.dailySummaryUpdates.${currentDayKey}`
    )
    .then(result => {
      if (!result.value) {
        return {
          latestEvent: null,
          latestEventProfileKey: [],
        };
      }

      const loggedProfileKeys = Object.keys(result.value).filter(
        profileKey => profileKey !== get(state`appContainer.user.uid`)
      );
      const randomProfileKey =
        loggedProfileKeys[Math.floor(Math.random() * loggedProfileKeys.length)];

      return {
        latestEvent: result.value[randomProfileKey],
        latestEventProfileKey: [randomProfileKey],
      };
    });
}

export function setChallengeParticipantCounts({ props, get, store }) {
  const structure = get(state`challenge.campaign.structure`);

  if (props.key === 'total') {
    store.set(
      state`challenge.campaign.structure.participantCount`,
      props.value || 0
    );
  }

  if (props.key === 'sections') {
    Object.keys(structure.sections).forEach(sectionKey => {
      store.set(
        state`challenge.campaign.structure.sections.${sectionKey}.participantCount`,
        props.value[sectionKey] || 0
      );
    });
  }

  if (props.key === 'teams') {
    Object.keys(structure.sections).forEach(sectionKey => {
      Object.keys(structure.sections[sectionKey].teams).forEach(teamKey => {
        store.set(
          state`challenge.campaign.structure.sections.${sectionKey}.teams.${teamKey}.participantCount`,
          props.value[teamKey] || 0
        );
      });
    });
  }
}

export function combineEvents({ props }) {
  const { globalEvents, teamEvents, teamStaticEvents } = props;

  const combined = Object.assign(
    {},
    ...globalEvents.concat(teamEvents, teamStaticEvents)
  );

  return { combined };
}
